import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { Clear } from "@mui/icons-material";

import messages from "../../../../assets/locale/messages";
import Input from "../../../../components/Input";
import Accordion from "../../../../components/Accordion";
import Select from "../../../../components/Select";
import { eventStatuses } from "../../../../utils/Constants";

const RequirementsFields = ({
  requirement,
  removeClickFunction,
  formik,
  index,
  requirementsCount,
  isEventUpcoming,
}) => {
  const { id } = useParams();

  const lang = useSelector((state) => state.locale.lang);
  const event = useSelector((state) => state.events.event);
  const activePublishMode = useSelector(
    (state) => state.routing.activePublishMode
  );
  const requirementsOptions = useSelector(
    (state) => state.lookups.general.requirements
  );

  const { creatEvent } = messages[lang].events;

  const [requirementName, setRequirementName] = useState(
    creatEvent.requirement
  );

  useEffect(() => {
    if (requirement?.requirement_id) {
      const selectedItemLabel = requirementsOptions?.find(
        (item) => item.id === requirement?.requirement_id
      )?.name;
      setRequirementName(selectedItemLabel);
    } else {
      setRequirementName(creatEvent.requirement);
    }
  }, [requirement?.requirement_id, lang, requirementsOptions]);

  return (
    <div>
      <Accordion
        accordionNumber={index}
        title={requirementName}
        isExpandedInitially={activePublishMode}
        extraFunctionBtn={
          (index === 0 && requirementsCount === 1) ||
          (id &&
            [
              eventStatuses.cancelled,
              eventStatuses.past,
            ].includes(event?.status)) ? (
            <></>
          ) : (
            <span
              className="cursor-pointer"
              onClick={() => removeClickFunction(true)}>
              <Clear />
            </span>
          )
        }
        content={
          <>
            <Select
              id={`event_requirements_attributes.${index}.requirement_id`}
              name={`event_requirements_attributes.${index}.requirement_id`}
              options={requirementsOptions}
              label={creatEvent.labels.nameOfRequirement}
              variant
              block
              labelClassName="text-gray-title  pb-2"
              placeholder={creatEvent.placeholders.nameOfRequirement}
              value={
                requirementsOptions?.find(
                  (option) => option.id == requirement.requirement_id
                ) || null
              }
              onChange={(value) => {
                formik.setFieldTouched(
                  `event_requirements_attributes.${index}.requirement_id`
                );
                formik.setFieldValue(
                  `event_requirements_attributes.${index}.requirement_id`,
                  value ? value.id : ""
                );
                if (!value && requirement.id) {
                  removeClickFunction(false);
                }
              }}
              hasError={
                !!(
                  formik.touched?.event_requirements_attributes?.[index]
                    ?.requirement_id &&
                  formik.errors?.event_requirements_attributes?.[index]
                    ?.requirement_id
                )
              }
              errMsg={
                formik.errors?.event_requirements_attributes?.[index]
                  ?.requirement_id
              }
              required={activePublishMode || isEventUpcoming}
              disabled={
                id &&
                [
                  eventStatuses.cancelled,
                  eventStatuses.past,
                ].includes(event?.status)
              }
            />

            <div className="row">
              <div className="col-md-6">
                <Input
                  id={`event_requirements_attributes.${index}.quantity`}
                  name={`event_requirements_attributes.${index}.quantity`}
                  label={creatEvent.labels.quantity}
                  type="text"
                  placeholder={creatEvent.placeholders.quantity}
                  value={requirement?.quantity || ""}
                  onChange={(value) => {
                    formik.setFieldTouched(
                      `event_requirements_attributes.${index}.quantity`
                    );
                    formik.setFieldValue(
                      `event_requirements_attributes.${index}.quantity`,
                      value || null
                    );
                  }}
                  inputClass="mb-1"
                  labelClassName="font-medium mb-2 text-primary field-label"
                  inputWrapperClass="create-event-input"
                  isInputHasErr={
                    !!(
                      formik.touched?.event_requirements_attributes?.[index]
                        ?.quantity &&
                      formik.errors?.event_requirements_attributes?.[index]
                        ?.quantity
                    )
                  }
                  errMsg={
                    formik.errors?.event_requirements_attributes?.[index]
                      ?.quantity
                  }
                  disabled={
                    id &&
                    [
                      eventStatuses.cancelled,
                      eventStatuses.past,
                    ].includes(event?.status)
                  }
                  required={activePublishMode || isEventUpcoming}
                />
              </div>
              <div className="col-md-6">
                <Input
                  id={`event_requirements_attributes.${index}.explanation`}
                  name={`event_requirements_attributes.${index}.explanation`}
                  label={
                    <div>
                      <span>{creatEvent.labels.explanation}</span>
                      <span className="mx-1">{creatEvent.optional}</span>
                    </div>
                  }
                  type="text"
                  placeholder={creatEvent.placeholders.explanation}
                  value={requirement?.explanation || ""}
                  onChange={(value) => {
                    formik.setFieldTouched(
                      `event_requirements_attributes.${index}.explanation`
                    );
                    formik.setFieldValue(
                      `event_requirements_attributes.${index}.explanation`,
                      value || null
                    );
                  }}
                  inputClass="mb-1"
                  labelClassName="font-medium mb-2 text-primary field-label"
                  inputWrapperClass="create-event-input"
                  isInputHasErr={
                    !!(
                      formik.touched?.event_requirements_attributes?.[index]
                        ?.explanation &&
                      formik.errors?.event_requirements_attributes?.[index]
                        ?.explanation
                    )
                  }
                  errMsg={
                    formik.errors?.event_requirements_attributes?.[index]
                      ?.explanation
                  }
                  disabled={
                    id &&
                    [
                      eventStatuses.cancelled,
                      eventStatuses.past,
                    ].includes(event?.status)
                  }
                />
              </div>
            </div>
          </>
        }
      />
    </div>
  );
};

export default RequirementsFields;

RequirementsFields.propTypes = {
  requirement: PropTypes.shape({
    id: PropTypes.string,
    requirement_id: PropTypes.string,
    quantity: PropTypes.string,
    explanation: PropTypes.string,
  }),
  removeClickFunction: PropTypes.func,
  formik: PropTypes.any,
  index: PropTypes.number,
  requirementsCount: PropTypes.number,
  isEventUpcoming: PropTypes.bool,
};
