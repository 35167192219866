import * as types from "./types";

export const getReportsRequest = (payload) => ({
  type: types.GET_REPORTS_REQUEST,
  payload,
});

export const getReportsResponse = (payload) => ({
  type: types.GET_REPORTS_RESPONSE,
  payload,
});

export const getReportDetailRequest = (payload) => ({
  type: types.GET_REPORT_DETAIL_REQUEST,
  payload,
});

export const getReportDetailResponse = (payload) => ({
  type: types.GET_REPORT_DETAIL_RESPONSE,
  payload,
});

export const exportReportSheetRequest = (payload) => ({
  type: types.EXPORT_REPORT_SHEET_REQUEST,
  payload,
});
