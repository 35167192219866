import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { TableCell, TableRow } from "@mui/material";
import { Add } from "@mui/icons-material";

import SearchInput from "../../components/Filter/SearchInput";
import Table from "../../components/Table";
import Button from "../../components/Button";
import EmptyState from "../../components/EmptyState";
import Avatar from "../../components/Avatar";
import Breadcrumbs from "../../components/BreadCrumbs";
import Pagination from "../../components/Pagination";
import employeesPlaceholder from "../../assets/images/employees/placeholder.png";
import messages from "../../assets/locale/messages";
import { ROUTE_PATHS } from "../../utils/RoutesPaths";
import { textContainOnlySpaces, renderTextEllipsis } from "../../utils/Helpers";
import { isSuperAdmin } from "../../utils/Permissions";
import "./Employees.scss";
import {
  initialFetchEmployee,
  setEmployeePage,
  setEmployeeSearch,
} from "../../store/PersistEmployees/actions";

const Employees = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  let location = useLocation();

  const lang = useSelector((state) => state.locale.lang);
  const isLoading = useSelector((state) => state.loader);
  const meta = useSelector((state) => state.employees.meta);
  const list = useSelector((state) => state.employees.list);
  const { employees, shared } = messages[lang];

  const { page, searchValue } = useSelector((state) => state.persistEmployee);

  const [q, setQuery] = useState("");

  const handlePageChange = (newPage) => {
    dispatch(setEmployeePage(newPage));
  };

  useEffect(() => {
    if ((q.length >= 3 || q.length === 0) && !textContainOnlySpaces(q)) {
      dispatch(setEmployeeSearch(q));
    }
  }, [q]);

  useEffect(() => {
    dispatch(initialFetchEmployee());
  }, [lang, page, searchValue]);

  // Employees Lis Route is only Accessable by Super Admin and this to handel if PM tries to access it
  useEffect(() => {
    if (location.pathname === ROUTE_PATHS.employees && !isSuperAdmin()) {
      history.push(ROUTE_PATHS.profile);
    }
  }, [location]);

  const renderEmployeesList = () => {
    if (!isLoading && !list?.length) {
      return (
        <>
          <div className="bg-white rounded-4 d-flex justify-content-center align-items-center flex-column my-4">
            <EmptyState
              description={employees.noDataDescription}
              imgSrc={employeesPlaceholder}
              showActionButton={false}
              containerClasses="pt-5"
            />
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="bg-white rounded-4 mt-2 pb-3">
            <Table
              className="rounded-4"
              headlines={[
                shared.image,
                employees.listEmployees.name,
                employees.listEmployees.email,
                employees.listEmployees.mobile,
                employees.listEmployees.jobTitle,
              ]}
              hasActions={false}
              hasSizeLimitation={false}
              rows={list?.map((row, i) => (
                <TableRow
                  key={i}
                  className="row-hover"
                  onClick={() => {
                    history.push(
                      ROUTE_PATHS["viewEmployee"].replace(":id", row.id)
                    );
                  }}
                >
                  <TableCell>
                    <Avatar
                      imgSrcAlt="employee name"
                      imgSrc={row?.image_url}
                      name={row?.name}
                      size={30}
                    />
                  </TableCell>
                  <TableCell className="w-25">
                    {renderTextEllipsis(row.name)}
                  </TableCell>

                  <TableCell className="w-25">
                    {renderTextEllipsis(row.email)}
                  </TableCell>
                  <TableCell className="w-25 phone-number-cell">
                    {row.phone_number
                      ? `${row.dial_code}${" "}${row.phone_number}`
                      : "-"}
                  </TableCell>
                  <TableCell className="w-25">{row.role.name}</TableCell>
                </TableRow>
              ))}
            />
          </div>
          <div className="d-flex justify-content-center py-3 mt-4">
            {meta.pages > 1 && (
              <Pagination
                count={meta.pages}
                page={meta.currentPage || page}
                handleChange={(event, page) => handlePageChange(page)}
                defaultPage={1}
              />
            )}
          </div>
        </>
      );
    }
  };

  return (
    <div className="employees-list-container">
      <div className="page-title-margin">
        <Breadcrumbs
          list={[
            {
              path: "",
              label: employees.employeesList,
            },
          ]}
          BreadCrumbHasBackIcon={false}
        />
      </div>
      <div className="services-header d-flex justify-content-between align-items-center flex-column flex-md-row mt-3 mb-4 gap-2">
        <div className="title-search d-flex ">
          <SearchInput
            placeholder={employees.searchPlaceholder}
            name="search"
            value={q}
            onChange={(value) => setQuery(value)}
          />
        </div>
        <div className="add-employee-btn-container">
          <Button
            label={
              <span className="d-flex align-items-center">
                <Add fontSize="small" />
                <span className="ps-1">
                  {employees.addProjectManager.addEmployee}
                </span>
              </span>
            }
            labelClass="text-white text-nowrap"
            onClick={() => {
              history.push(ROUTE_PATHS.addProjectManager);
            }}
            block
          />
        </div>
      </div>
      <div className="d-flex flex-column justify-content-between">
        {renderEmployeesList()}
      </div>
    </div>
  );
};
export default Employees;
