import * as types from "./types";

const initialState = {
  list: [],
  meta: {},
};

const report = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_REPORTS_RESPONSE:
      return {
        ...state,
        list: action.payload.list,
        meta: action.payload.meta,
      };

    case types.GET_REPORT_DETAIL_RESPONSE:
      return {
        ...state,
        list: action.payload,
      };
    default:
      return state;
  }
};

export default report;
