import PptxGenJS from "pptxgenjs";

export const downloadProfile = (
  applicant,
  applicants,
  lang,
  proLineLogo,
  userBase
) => {
  const isListingPage = Array.isArray(applicant);
  const textStyle = {
    fontSize: 10,
    color: "FFFFFF",
    fontFace: "Times New Roman",
  };

  const boldTitleStyle = {
    bold: true,
    color: "FFFFFF",
    fontFace: "Times New Roman",
  };
  const pptx = new PptxGenJS();
  const _applicant = Array.isArray(applicant) ? applicant : [applicant];
  _applicant.forEach((info, index) => {
    const slide = pptx.addSlide();

    // Background
    slide.background = { color: "000000" };

    // LOGO
    slide.addImage({
      data: proLineLogo,
      x: 0.5,
      y: 0.2,
      w: 0.5,
      h: 0.9,
    });

    // Title
    slide.addText(applicants.listApplicants.getKnowTeam, {
      x: 1.5,
      y: 0.5,
      fontSize: 20,
      ...boldTitleStyle,
    });

    slide.addShape(pptx.ShapeType.rect, {
      x: 1.6,
      y: 0.7,
      w: 7,
      h: 0.05,
      fill: { color: "4C4C4C" },
    });

    // Add Image
    slide.addImage({
      data: isListingPage ? userBase[index]?.imageBase64 : userBase,
      x: 0.5,
      y: 1.3,
      w: 2,
      h: 3,
    });

    // Name
    slide.addText(
      lang === "en" ? info?.full_name_en.toUpperCase() : info?.full_name_ar,
      {
        x: 2.8,
        y: 1.3,
        fontSize: 24,
        ...boldTitleStyle,
      }
    );

    // Subtitle with Blue Background
    slide.addShape(pptx.ShapeType.rect, {
      x: 2.8,
      y: 1.5,
      w: 2.8,
      h: 0.3,
      fill: { color: "2D9CDB" },
    });

    // subtitle
    slide.addText(info?.role?.name, {
      x: 2.9,
      y: 1.65,
      fontSize: 14,
      ...boldTitleStyle,
    });

    //Basic info
    slide.addText(applicants.applicantExportDetails.basicInfo, {
      x: 2.8,
      y: 2.1,
      fontSize: 12,
      ...boldTitleStyle,
    });

    //id title
    slide.addText(`${applicants.applicantExportDetails.id} :`, {
      x: 2.8,
      y: 2.4,
      ...textStyle,
    });

    //id
    slide.addText(info?.id, {
      x: 3.4,
      y: 2.4,
      ...textStyle,
    });

    //Nationality tile
    slide.addText(`${applicants.applicantExportDetails.nationality} :`, {
      x: 2.8,
      y: 2.8,
      ...textStyle,
    });

    //Nationality
    slide.addText(info?.nationality?.name, {
      x: 3.8,
      y: 2.8,
      ...textStyle,
    });

    //City title
    slide.addText(`${applicants.applicantExportDetails.city} :`, {
      x: 2.8,
      y: 3.2,
      ...textStyle,
    });

    //City
    slide.addText(info?.city?.name, {
      x: 3.4,
      y: 3.2,
      ...textStyle,
    });

    //
    //Education title
    slide.addText(applicants.applicantExportDetails.education, {
      x: 2.8,
      y: 3.6,
      fontSize: 12,
      ...boldTitleStyle,
    });

    //Education level
    slide.addText(`${applicants.applicantExportDetails.educationLevel} :`, {
      x: 2.8,
      y: 4,
      ...textStyle,
    });

    // education
    slide.addText(info?.educational_level?.name, {
      x: lang === "en" ? 4.4 : 4.8,
      y: 4,
      ...textStyle,
    });

    //language
    slide.addText(`${applicants.applicantExportDetails.language} :`, {
      x: 2.8,
      y: 4.4,
      ...textStyle,
    });

    // language
    {
      info?.user_languages?.forEach((language, index) => {
        slide.addText(language?.language, {
          x: 4.4 + index * 0.6,
          y: 4.4,
          ...textStyle,
        });
      });
    }
  });
  pptx.writeFile(
    isListingPage
      ? applicants.applicantExportDetails.applicantProfile
      : lang === "ar"
      ? `${applicant?.full_name_ar}-${applicants.applicantExportDetails.profile}`
      : `${applicant?.full_name_en}-${applicants.applicantExportDetails.profile}`
  );
};
