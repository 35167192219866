import shared from "./shared";
import login from "./login";
import changePassword from "./changePassword";
import forgetPassword from "./forgetPassword";
import admins from "./admins";
import inputsValidations from "./inputsValidations";
import pagesTitles from "./pagesTitles";
import successMsgs from "./successMsgs";
import employees from "./employees";
import events from "./events";
import requests from "./requests";
import applicants from "./applicants";
import timeSheet from "./timeSheet";
import promotionRequests from "./promotionRequests";
import zoneApplicants from "./zoneApplicants";
import evaluations from "./evaluations";
import notifications from "./notifications";
import report from "./report";

const en = {
  shared,
  login,
  changePassword,
  forgetPassword,
  admins,
  inputsValidations,
  pagesTitles,
  successMsgs,
  employees,
  events,
  applicants,
  zoneApplicants,
  requests,
  timeSheet,
  promotionRequests,
  evaluations,
  notifications,
  report,
};

export default en;
