import * as types from "./types";

export const getApplicantTabsRequest = (payload) => ({
  type: types.INITIAL_FETCH_REQUEST,
  payload,
});

export const setPageRequest = (payload) => ({
  type: types.SET_PAGE_REQUEST,
  payload,
});

export const setFiltersRequest = (payload) => ({
  type: types.SET_FILTERS_VALUE_REQUEST,
  payload,
});

export const setSearchValueRequest = (payload) => ({
  type: types.SET_SEARCH_VALUE_REQUEST,
  payload,
});

export const setSupervisorValueRequest = (payload) => ({
  type: types.SET_SUPERVISOR_VALUE_REQUEST,
  payload,
});

export const setFlagCheckInValueRequest = (payload) => ({
  type: types.SET_FLAG_CHECK_IN_VALUE_REQUEST,
  payload,
});

export const setTabValueRequest = (payload) => ({
  type: types.SET_TABS_VALUE_REQUEST,
  payload,
});

export const setResettingFilters = (payload) => ({
  type: types.SET_RESETTING_FILTERS,
  payload,
});
