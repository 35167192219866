import { axiosInstance } from "./";

const getRequestsList = async (params) =>
  await axiosInstance.get("/event_requests", { params });

const approveRequest = async ({ data, id }) =>
  await axiosInstance.patch(`/event_requests/${id}/approve`, data);

const updateEventRequest = async ({ data, id }) =>
  await axiosInstance.patch(`/event_requests/${id}`, data);

const removeEventRequest = async ({ id, data }) =>
  await axiosInstance.patch(`/event_requests/${id}/remove_applicant`, data);

const updateSupervisorEventRequest = async ({ data, id }) =>
  await axiosInstance.patch(`/zones/${id}`, data);

const bulkAssign = async ({ data }) =>
  await axiosInstance.patch(`/event_requests/zone_bulk_assign`, data);

const getEventRequestList = async ({ id, params }) =>
  await axiosInstance.get(`/events/${id}/event_requests`, { params });

const updateBulkApprovedRequests = async (params) =>
  await axiosInstance.patch(`/event_requests/bulk_approve_requests`, params);

export {
  getRequestsList,
  approveRequest,
  updateEventRequest,
  updateSupervisorEventRequest,
  bulkAssign,
  getEventRequestList,
  updateBulkApprovedRequests,
  removeEventRequest,
};
