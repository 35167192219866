import * as types from "./types";

const initialState = {
  page: 1,
  rowsCount: 10,
  searchValue: "",
  filterRequestStatus: null,
  filterRating: null,
  filterGender: null,
  filterRole: null,
  filterCity: null,
  filterNationality: null,
  filterLanguage: null,
  filterSpeaking: null,
};

const joiningRequest = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_FILTERS_REQUEST:
      return {
        ...state,
        ...action.payload,
      };
    case types.SET_FILTER_STATUS_REQUEST:
      return {
        ...state,
        ...action.payload,
      };
    case types.SET_PAGE_REQUEST:
      return {
        ...state,
        page: action.payload,
      };
    case types.SET_SEARCH_VALUE_REQUEST:
      return {
        ...state,
        searchValue: action.payload,
      };
    case types.SET_ROWS_COUNT_REQUEST:
      return {
        ...state,
        rowsCount: action.payload,
      };
    default:
      return state;
  }
};

export default joiningRequest;
