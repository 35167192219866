const evaluations = {
  listEvaluations: {
    title: "Evaluations List",
    evaluationName: "Event Evaluation Name",
    creationDate: "Evaluation Creation Date",
    totalNumber: "Applicants Total Number",
    evaluatedApplicants: "Evaluated Applicants",
    remainingApplicants: "Remaining Applicants",
    searchPlaceholder: "Search by event evaluation name",
    noData: "No Evaluations yet !",
  },
  listForms: {
    title: "Events' Evaluations List",
    searchPlaceholder: "Search by applicant name",
    event: "Event",
    applicantName: "Applicant Name",
    email: "Email",
    role: "Role",
    zoneName: "Zone Name",
    finalRate: "Final Rate",
    zone: "Zone",
    noData: "No Evaluations yet !",
  },
  evaluationsForm: {
    applicantInfo: "Applicant Info",
    evaluationSkills: "Applicant Skills",
    comments: "Comments",
    submittedBy: "Submitted By:",
    confirmMsg: "Are you sure you want to submit ",
    evaluation: " Evaluation?",
    noComment: "No comment",
  },
  statusOptions: {
    submitted: "Completed",
    pending: "In Progress",
  },
  inProgressStatusOptions: {
    submitted: "Done",
    pending: "Pending",
  },
  noAccessMsg: "Sorry you don't have permission to submit this evaluation",
  evaluations: "Evaluations",
  editEvaluation: "Edit Evaluation",
};

export default evaluations;
