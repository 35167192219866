const events = {
  creatEvent: {
    stepOne: "Basic Info & PMs",
    verificationMethod: "Check-In Verification Method",
    stepTwo: "Roles",
    stepThree: "Zones & Requirements",
    basicInfo: "Basic Info",
    projectManagers: "Project Managers",
    roles: "Roles",
    zones: "Zones",
    requirements: "Requirements",
    comments: "Comments",
    agreementFile: "Agreement file",
    projectManager: "Project Manager",
    role: "Role",
    zone: "Zone",
    requirement: "Requirement",
    publishModalConfirmMessage:
      "Kindly note that upon publishing the event, it will be displayed on the App upcoming events",
    publishPrivateConfirmMsg:
      "Kindly note that it’s a private event, applicants should be invited by the admins!",
    confirm: "Confirm",
    cancel: "Cancel",
    optional: "(Optional)",
    labels: {
      uploadLabel: "Upload Image",
      eventImage: "Event Image",
      eventName: "Event Name",
      eventBrief: "Event Brief",
      eventLocation: "Event Location",
      organizerScanning: "Organizer QR Scanning",
      supervisorScanning: "Supervisor QR Scanning",
      supervisorApproval: "Supervisor Approval",
      basicVerification: "Basic Verification",
      diameter: "Location Boundaries (in meters)",
      eventType: "Event Type",
      publishingMode: "Publishing Mode",
      startBuildUpStart: "Buildup Start Date & Time",
      startBuildUpEnd: "Buildup End Date & Time",
      eventDayStart: "Event Start Date & Time",
      eventDayEnd: "Event End Date & Time",
      dismantlingDayStart: "Dismantling Start Date & Time",
      dismantlingDayEnd: "Dismantling End Date & Time",
      pmName: "Name",
      responsibility: "Responsibility",
      nameOfRole: "Name",
      numberOfMales: "Number of Males",
      numberOfFemales: "Number of Females",
      numberOfShifts: "Number of Shifts",
      payrollAmount: "Payroll Amount",
      workingHours: "Working Time Slots",
      uniformCode: "Uniform Code",
      timeToArriveToTheSite: "Time to Arrive to The Site",
      requiredTraining: "Required Training",
      zoneName: "Zone Name",
      zoneManager: "Manager",
      managerName: "Manager Name",
      zoneManagerLabel: "Zone Manager",
      email: "Manager Email",
      mobileNumber: "Manager Phone",
      supervisorName: "Supervisor Name",
      supervisorInfo: "Supervisor Info",
      managerInfo: "Manager Info",
      zonesMap: "Zones Map",
      nameOfRequirement: "Name",
      quantity: "Quantity",
      explanation: "Explanation",
      uploadFile: "Upload file",
      checkInTimeMargin: "Check-In Time Margin",
      checkOutTimeMargin: "Check-Out Time Margin",
      checkinStart: "Before working time starts (minutes)",
      checkinEnd: "After working time starts (minutes)",
      checkoutStart: "Before working time ends (minutes)",
      checkoutEnd: "After working time ends (minutes)",
    },
    placeholders: {
      eventName: "Enter Event Name",
      eventBrief: "Enter Event Brief",
      eventLocation: "Enter Event Location",
      diameter: "Enter Location Boundaries",
      eventType: "Enter Event Type",
      startBuildUpStart: "Buildup Start Date & Time",
      startBuildUpEnd: "Buildup End Date & Time",
      eventDayStart: "Event Start Date & Time",
      eventDayEnd: "Event End Date & Time",
      dismantlingDayStart: "Dismantling Start Date & Time",
      dismantlingDayEnd: "Dismantling End Date & Time",
      pmName: "Enter Project Manager Name",
      responsibility: "Enter Responsibility",
      nameOfRole: "Enter Role Name",
      numberOfMales: "Enter Number of Males",
      numberOfFemales: "Enter Number of Females",
      numberOfShifts: "Enter Number of Shifts",
      payrollAmount: "Enter Payroll Amount",
      workingHours: "Enter Working Time Slots",
      uniformCode: "Enter Uniform Code",
      timeToArriveToTheSite: "Enter Time to Arrive to The Site",
      requiredTraining: "Enter Required Training",
      zoneName: "Enter Zone Name",
      zoneManager: "Enter Zone Manager",
      email: "Enter Email",
      mobileNumber: "Enter Mobile Number",
      nameOfRequirement: "Enter Name of Requirement",
      quantity: "Enter Quantity",
      explanation: "Enter Explanation",
      enterYourComments: "Enter Enter your Comments",
      selectSupervisor: "Select Supervisor",
      checkinStart: "Before working time starts",
      checkinEnd: "After working time starts",
      checkoutStart: "Before working time ends",
      checkoutEnd: "After working time ends",
    },
    singleDayEvent: "Single Day Event",
    daily: "Daily",
    monthly: "Monthly",
    public: "Public",
    private: "Private",
    add: "Add",
    continue: "Continue",
    saveContinue: "Save & Continue",
    saveLater: "Save & Complete Later",
    start: "Start",
    end: "End",
    save: "Save",
    saveAndPublish: "Save & publish",
    selectLocationOnMap: "Select location on map to publish event",
    addAtListOnePm: "Add at least one project manager to publish event",
    addAtListOneRole: "Add at least one Role to publish event",
    addSlot: "fill time slot to publish event",
    addAtListOneZone: "Add at least one zone to publish event",
    addAtListOneRequirement: "Add at least one requirement to publish event",
    addAtListOnePmEditMode: "Add at least one project manager",
    addAtListOneRoleEditMode: "Add at least one Role",
    addAtListOneZoneEditMode: "Add at least one zone",
    addAtListOneRequirementEditMode: "Add at least one requirement",
    noPhoneProvided: "No phone number provided for this employee",
  },
  listEvents: {
    listEventsBreadcrumb: "Events List",
    eventType: "Event Type",
    details: "'s details",
    startDate: "Start Date",
    endDate: "End Date",
    projectManager: "Project Manager",
    searchPlaceHolder: "Search Events",
    projectManagers: "Project Managers",
    type: "Type",
    publishMode: "Publishing Mode",
    daily: "Daily",
    monthly: "Monthly",
    noDataDescription: "Sorry, No events found.",
    eventTabs: {
      ongoing: "Ongoing",
      upcoming: "Upcoming",
      previous: "Completed",
      pending: "Pending",
      cancelled: "Cancelled",
    },
    eventStatus: {
      ongoing: "Ongoing",
      upcoming: "Upcoming",
      past: "Completed",
      pending: "Pending",
      canceled: "Cancelled",
    },
  },
  viewEvents: {
    diameter: "Location Boundaries",
    viewEventsBreadcrumb: "Event Details",
    deleteBtn: "Delete Event",
    cancelBtn: "Cancel Event",
    editBtn: "Edit Event",
    basicInfo: "Basic Info",
    projectManagers: "Project Managers",
    roles: "Roles",
    zones: "Zones",
    requirementsAndComments: "Requirements & Comments",
    add: "Add",
    edit: "Edit",
    noLocation: "No Location Selected yet",
    noImage: "No Image Selected yet",
    noProjectManagers: "No Project Managers Added",
    noRoles: "No Roles Added",
    noZones: "No Zones Added",
    noRequirements: "No Requirements Added",
    managerInfo: "Manager Info",
    managerEmail: "Manager Email",
    managerPhone: "Manager Phone",
    supervisorInfo: "Supervisor Info",
    supervisorName: "Supervisor Name",
    applicants: "Applicants",
    totalNumberOfApplicants: "Total Number of Applicants",
    viewApplicantsList: "View Applicants List",
    applicantsTab: {
      supervisor: "Supervisor",
      others: "Other roles",
      status: "Status",
      gender: "Gender",
      zone: "Zone",
      role: "Role",
      name: "Name",
      email: "Email",
      note: "Note",
      placeholders: {
        supervisor: "Search by supervisor name",
        others: "Search by applicant name, supervisor name",
        noData: "No applicants accepted yet",
        noSearchResults: "Sorry, no applicants found",
      },
      assign: "Assign",
      assigned: "Assigned",
      notAssigned: "Not Assigned",
      unassign: "Unassign",
      reallocate: "Reallocate",
      remove: "Remove",
      unassignApplicant: "Unassign applicant",
      reallocateApplicant: "Reallocate applicant to another zone",
      assignApplicant: "Assign applicant to zones",
      removeApplicant: "Remove applicant to zones",
      unassignConfirmationMsg:
        "Are you sure that you want to unassign this applicant from the zone?",
      removeConfirmationMsg:
        "Are you sure that you want to remove this applicant from the zone?",
      inviteMoreApplicants: "Invite applicants",
      inviteApplicants: "Invite applicants",
      invite: "Invite",
      searchByApplicant: "Search by applicant name",
      hint: "To change the role before inviting the applicant, edit the dropdown selection",
      selectFile: "Select Fields For Export CSV",
      export: "Export",
    },
    sending: "Sending",
    sendNotification: "Send Notification",
    messageRequired: "Message is Required",
    pushNotification: "Push Notification",
    sendPushNotification: "Send Push Notification",
    notificationSend: "Notification has been sent",
    enterPushNotification: "Enter Push Notification Message",
    cancelModalConfirmMessage: "Are you sure you want to cancel this event?",
    submitEventPushNotification:
      "Are you sure you want to send the notification?",
    deleteModalConfirmMessage:
      "Deleted event cannot be retrieved , Are you sure you want to delete this event?",
    completedEvaluationBtn: "Start Evaluation Round",
    startEvaluationRound:
      "Are you sure that you want to start an evaluation round for",
    disbaledCreateEvaluationBtnHint:
      "Button is disabled because dismantling days have started",
    checkedinApplicants: "Checked-in Applicants",
    noncheckedinApplicants: "Not Checked-in Applicants",
    checkinStatus: "Check-in Status",
    checkedIn: "Checked-in",
    nonCheckedIn: "Not checked-in",
  },
};

export default events;
