import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Block, Downloading } from "@mui/icons-material";
import messages from "../../assets/locale/messages";
import { ROUTE_PATHS } from "../../utils/RoutesPaths";
import {
  applicantsProfileStatus,
  genders,
  lookupsKeys,
} from "../../utils/Constants";
import Pagination from "../../components/Pagination";
import Rate from "../../components/Rate";
import Breadcrumbs from "../../components/BreadCrumbs";
import EmptyState from "../../components/EmptyState";
import DynamicTable from "../../components/DynamicTable";
import applicantPlaceholder from "../../assets/images/applicant-screen/applicants-placeholder.svg";
import Modal from "../../components/Modal";
import Avatar from "../../components/Avatar";
import "./Applicants.scss";
import Note from "../../components/Note";
import {
  createNoteRequest,
  deleteNoteRequest,
  getNoteRequest,
  updateNoteRequest,
} from "../../store/Notes/action";
import {
  deleteLocationRequest,
  getLocationsRequest,
} from "../../store/Locations/actions";
import Button from "../../components/Button";
import {
  initialFetch,
  setFilters,
  setOrder,
  setPage,
  setRowsCount,
  setSearchValue,
} from "../../store/Filter/actions";
import Filters from "../../components/Filter";
import {
  getCitiesRequest,
  getCountriesRequest,
  getLookupsRequest,
  getSpeakingProficiencyRequest,
} from "../../store/Lookups/actions";
import Select from "../../components/Select";
import { fetchImageAsBase64, textContainOnlySpaces } from "../../utils/Helpers";
import logo from "../../assets/images/proline-logo-export.png";
import { downloadProfile } from "./downloadProfile";

const renderColumns = (
  applicants,
  shared,
  handleAddNote,
  handleEditNote,
  handleDeleteNote,
  handleEditProfile
) => {
  return [
    {
      field: "id",
      headerName: shared.id,
      editable: false,
      filterable: false,
      hideable: false,
      hideSortIcons: true,
      sortable: false,
      width: 105,
      renderCell: (params) => {
        return (
          <div className="MuiDataGrid-cellContent">
            {params.row?.id ? <span>{params.row.id}</span> : "-"}
          </div>
        );
      },
    },
    {
      field: "img",
      headerName: shared.image,
      editable: false,
      filterable: false,
      hideable: false,
      hideSortIcons: true,
      sortable: false,
      width: 70,
      renderCell: (params) => {
        return (
          <div className={`MuiDataGrid-cellContent`}>
            {params.row?.name && (
              <Avatar
                imgSrcAlt="employee img"
                imgSrc={params.row?.img}
                name={params?.value}
                size={30}
              ></Avatar>
            )}
          </div>
        );
      },
    },
    {
      field: "name",
      headerName: applicants.listApplicants.name,
      editable: false,
      filterable: false,
      hideable: false,
      hideSortIcons: true,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className={`MuiDataGrid-cellContent d-flex align-items-center`}>
            <div className="user-name">
              {params.value.data}
              {params.value.isBlocked && (
                <Block color="error" className="mx-2" fontSize="small" />
              )}
            </div>
          </div>
        );
      },
    },
    {
      field: "email",
      headerName: applicants.listApplicants.email,
      editable: false,
      filterable: false,
      hideable: false,
      hideSortIcons: true,
      sortable: false,
    },
    {
      field: "role",
      headerName: applicants.listApplicants.role,
      editable: false,
      filterable: false,
      hideable: true,
      hideSortIcons: true,
      sortable: false,
    },
    {
      field: "note",
      headerName: applicants.listApplicants.note,
      editable: false,
      filterable: false,
      hideable: true,
      hideSortIcons: true,
      sortable: false,
    },
    {
      field: "nationality",
      headerName: applicants.listApplicants.nationality,
      editable: false,
      filterable: false,
      hideable: true,
      hideSortIcons: true,
      sortable: false,
    },
    {
      field: "city",
      headerName: applicants.listApplicants.cityOfResidence,
      editable: false,
      filterable: false,
      hideable: true,
      hideSortIcons: true,
      sortable: false,
    },
    {
      field: "fieldsOfStudy",
      headerName: applicants.listApplicants.studyField,
      editable: false,
      filterable: false,
      hideable: true,
      hideSortIcons: true,
      sortable: false,
    },
    {
      field: "profileRate",
      headerName: applicants.listApplicants.profileRate,
      editable: false,
      filterable: false,
      hideable: true,
      hideSortIcons: true,
      sortable: false,
      renderCell: (params) =>
        params.row.profileRate ? (
          <div className="MuiDataGrid-cellContent">
            <Rate rating={params.value.toFixed(2)} />
          </div>
        ) : (
          "-"
        ),
    },
    {
      field: "age",
      headerName: applicants.listApplicants.age,
      hide: true,
      editable: false,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
    },
    {
      field: "language",
      headerName: applicants.listApplicants.language,
      editable: false,
      hide: true,
      filterable: false,
      hideable: true,
      hideSortIcons: true,
      sortable: false,
      renderCell: (params) =>
        params.row.language.length ? (
          <div className="MuiDataGrid-cellContent">
            {params.row.language.map((lang, i) => (
              <span key={i}>
                <span>{lang.language}</span>
                {i < params.row.language.length - 1 && <span>, </span>}
              </span>
            ))}
          </div>
        ) : (
          "-"
        ),
    },
    {
      field: "gender",
      headerName: applicants.listApplicants.gender,
      editable: false,
      hide: true,
      filterable: false,
      hideable: true,
      hideSortIcons: true,
      sortable: false,
    },
    {
      field: "weight",
      headerName: applicants.listApplicants.weight,
      editable: false,
      hide: true,
      filterable: false,
      hideable: true,
      hideSortIcons: true,
      sortable: false,
    },
    {
      field: "height",
      headerName: applicants.listApplicants.height,
      editable: false,
      hide: true,
      filterable: false,
      hideable: true,
      hideSortIcons: true,
      sortable: false,
    },
    {
      field: "profileStatus",
      headerName: applicants.listApplicants.profileStatus,
      editable: false,
      hide: true,
      filterable: false,
      hideable: true,
      hideSortIcons: true,
      sortable: false,
    },
    {
      field: "applicantStatus",
      headerName: applicants.listApplicants.applicantStatus,
      editable: false,
      hide: true,
      filterable: false,
      hideable: true,
      hideSortIcons: true,
      sortable: false,
    },
    {
      field: "disability",
      headerName: applicants.listApplicants.disability,
      editable: false,
      hide: true,
      filterable: false,
      hideable: true,
      hideSortIcons: true,
      sortable: false,
      renderCell: (params) =>
        params.row.disability.data.length ? (
          <div className="MuiDataGrid-cellContent">
            {params.row.disability.data.map((disability, i) => (
              <span key={i}>
                <span>{disability.name}</span>
                {i < params.row.disability.data.length - 1 && <span>, </span>}
              </span>
            ))}
          </div>
        ) : (
          <span>
            {params.row.disability.status === "verified"
              ? "-"
              : applicants.listApplicants.noDisability}
          </span>
        ),
    },
    {
      field: "diseases",
      headerName: applicants.listApplicants.diseases,
      editable: false,
      hide: true,
      filterable: false,
      hideable: true,
      hideSortIcons: true,
      sortable: false,
      renderCell: (params) =>
        params.row.diseases.data.length ? (
          <div className="MuiDataGrid-cellContent">
            {params.row.diseases.data.map((diseases, i) => (
              <span key={i}>
                <span>{diseases.name}</span>
                {i < params.row.diseases.data.length - 1 && <span>, </span>}
              </span>
            ))}
          </div>
        ) : (
          <span>
            {params.row.diseases.status === "verified"
              ? "-"
              : applicants.listApplicants.noDisease}
          </span>
        ),
    },
    {
      field: "Actions",
      headerName: applicants.applicantRequsets.actions,
      editable: false,
      width: 280,
      filterable: false,
      hideable: true,
      hideSortIcons: true,
      sortable: false,
      renderCell: (params) => {
        const noteContentExists = params.row.note && params.row.note !== "-";
        return (
          <div className="d-flex gap-1 w-100">
            {noteContentExists ? (
              <>
                <Button
                  label={shared.editNote}
                  className="MuiDataGrid-cellContent"
                  small={true}
                  onClick={(e) => handleEditNote(params.row.id, e)}
                />
                <Button
                  className={"MuiDataGrid-cellContent"}
                  negative={true}
                  outlined={true}
                  label={shared.deleteNote}
                  small={true}
                  onClick={(e) => handleDeleteNote(params.row.id, e)}
                />
              </>
            ) : (
              <Button
                className={"MuiDataGrid-cellContent"}
                label={shared.addNote}
                small={true}
                onClick={(e) => handleAddNote(params.row.id, e)}
              />
            )}
            <Button
              className={"MuiDataGrid-cellContent"}
              small={true}
              label={shared.editProfile}
              onClick={(e) => handleEditProfile(params.row.id, e)}
            />
          </div>
        );
      },
    },
  ];
};

const Applicants = () => {
  // 1) REDUX SAGA STATES
  const dispatch = useDispatch();
  const history = useHistory();
  const lang = useSelector((state) => state.locale.lang);
  const { applicants, shared } = messages[lang];
  const filters = useSelector((state) => state._filters);
  const { cities, countries, speakingProficiency } = useSelector(
    (state) => state.lookups
  );
  const list = useSelector((state) => state.applicants.list);
  const meta = useSelector((state) => state.applicants.meta);
  const note = useSelector((state) => state.note.list);
  const isLoading = useSelector((state) => state.loader);
  const { roles, languages, disabilities, chronicDiseases } = useSelector(
    (state) => state.lookups.general
  );

  // Profile & Logo State
  const [userBase, setUserBase] = useState(null);
  const [proLineLogo, setProLineLogo] = useState(null);

  // 2) FILTER PERSIST STATES
  const [q, setQuery] = useState("");
  const [rows, setRows] = useState([]);

  // 3) NOTE STATES
  const [selectValue, setSelectValue] = useState(null);
  const [otherValue, setOtherValue] = useState("");
  const [validate, setValidate] = useState(false);
  const location = useSelector((state) => state.locations.list);
  const [selectedApplicantId, setSelectedApplicantId] = useState(null);
  const [isNoteModalOpen, setIsNoteModalOpen] = useState(false);

  // 4) DEFAULT RECORDS FOR FILTERS
  const profileStatusOptions = [
    {
      id: 1,
      name: applicants.listApplicants.verified,
      value: applicantsProfileStatus.verified,
    },
    {
      id: 2,
      name: applicants.listApplicants.profile_completed,
      value: applicantsProfileStatus.profileCompleted,
    },
    {
      id: 3,
      name: applicants.listApplicants.basic_profile_completed,
      value: applicantsProfileStatus.basicProfileCompleted,
    },
  ];
  const applicantStatusOptions = [
    { id: 1, name: applicants.listApplicants.assigned, value: true },
    { id: 2, name: applicants.listApplicants.notAssigned, value: false },
  ];
  const genderOptions = [
    {
      id: 1,
      name: applicants.listApplicants.notProvided,
      value: genders.notProvided,
    },
    { id: 2, name: applicants.listApplicants.male, value: genders.male },
    { id: 3, name: applicants.listApplicants.female, value: genders.female },
  ];
  const ageOptions = [
    { id: 1, name: applicants.listApplicants.ageRange1, value: [16, 25] },
    { id: 2, name: applicants.listApplicants.ageRange2, value: [25, 30] },
    { id: 3, name: applicants.listApplicants.ageRange3, value: [30, 35] },
    { id: 4, name: applicants.listApplicants.ageRange4, value: [35, 40] },
  ];
  const weightOptions = [
    { id: 1, name: applicants.listApplicants.weightRange1, value: [40, 50] },
    { id: 2, name: applicants.listApplicants.weightRange2, value: [50, 70] },
    { id: 3, name: applicants.listApplicants.weightRange3, value: [70, 100] },
    { id: 4, name: applicants.listApplicants.weightRange4, value: [100, 120] },
    { id: 5, name: applicants.listApplicants.weightRange5, value: [120, 170] },
  ];
  const heightOptions = [
    { id: 1, name: applicants.listApplicants.heightRange1, value: [140, 160] },
    { id: 2, name: applicants.listApplicants.heightRange2, value: [160, 180] },
    { id: 3, name: applicants.listApplicants.heightRange3, value: [180, 200] },
    { id: 4, name: applicants.listApplicants.heightRange4, value: [200, 300] },
  ];

  const rowsCountOptions = [
    { id: 10, name: "10" },
    { id: 20, name: "20" },
    { id: 30, name: "30" },
    { id: 50, name: "50" },
  ];

  // 5) REDUX MAIN FUN PERSIST STATES FILTERS
  const handleFilterChange = (newFilter) => {
    dispatch(setFilters({ ...filters, ...newFilter }));
  };

  const handlePageChange = (newPage) => {
    dispatch(setPage(newPage));
  };

  const handleRowsCountChange = (newRowsCount) => {
    dispatch(setRowsCount(newRowsCount));
  };

  // 6) HANDLE EDIT NOTE
  const handleEditNote = (applicantId, e) => {
    e.stopPropagation();
    setIsNoteModalOpen(true);
    setSelectedApplicantId(applicantId);
    dispatch(getNoteRequest({ id: applicantId }));
  };

  // ?) Added Name Speaking
  const updatedSpeaking = speakingProficiency.map((item) => ({
    ...item,
    name: item.label,
  }));

  useEffect(() => {
    (async () => {
      if (list.length > 0) {
        const updatedList = await Promise.all(
          list.map(async (item) => {
            const profileBase64 = item.image
              ? await fetchImageAsBase64(item.image)
              : null;
            return {
              imageBase64: profileBase64,
            };
          })
        );
        setUserBase(updatedList);
      }
      const _logo = await fetchImageAsBase64(logo);
      setProLineLogo(_logo);
    })();
  }, [list, lang, filters]);

  useEffect(() => {
    const _location = location.find((item) => item.id === note.location_id);
    setSelectValue(
      note.custom_location
        ? shared.other
        : _location?.name || shared.selectedLocation
    );
    setOtherValue(note.custom_location || "");
  }, [note, dispatch]);

  // 7) HANDLE ADD NOTE
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!selectValue) return;
    const location_id = location.find((item) => item.name === selectValue);
    handleModalClose();
    setSelectValue(shared.selectedLocation);
    setOtherValue("");
    dispatch(
      note?.custom_location
        ? updateNoteRequest({
            id: selectedApplicantId,
            data: {
              custom_location: otherValue,
              location_id: location_id?.id,
            },
          })
        : createNoteRequest({
            id: selectedApplicantId,
            data: {
              custom_location: otherValue,
              location_id: location_id?.id,
            },
          })
    );
  };

  const handleAddNote = (applicantId, e) => {
    e.stopPropagation();
    setSelectedApplicantId(applicantId);
    setIsNoteModalOpen(true);
    setSelectValue(shared.selectedLocation);
    setOtherValue("");
    dispatch(getLocationsRequest());
  };

  const handleModalClose = () => setIsNoteModalOpen(false);

  const handleDeleteNote = (applicantId, e) => {
    e.stopPropagation();
    setSelectedApplicantId(applicantId);
    dispatch(deleteNoteRequest({ id: applicantId }));
    dispatch(getNoteRequest({ id: applicantId }));
  };

  const handleEditProfile = (id, e) => {
    e.stopPropagation();
    history.push(ROUTE_PATHS.editApplicantProfile.replace(":id", id));
  };

  const columns = useMemo(() => {
    return renderColumns(
      applicants,
      shared,
      handleAddNote,
      handleEditNote,
      handleDeleteNote,
      handleEditProfile
    );
  }, [applicants]);

  useEffect(() => {
    return () => {
      sessionStorage.removeItem("applicantsVisibleCols");
    };
  }, []);

  useEffect(() => {
    setRows(
      list.map((row) => ({
        id: row.id,
        name: {
          data:
            lang === "en" && row.full_name_en.trim()
              ? row.full_name_en
              : lang === "ar" && row.full_name_ar.trim()
              ? row.full_name_ar
              : "-",
          isBlocked: row.is_blocked,
        },
        email: row.email || "-",
        nationality: row.nationality?.name || "-",
        fieldsOfStudy: row.field_of_study?.name || "-",
        profileRate: row.rating,
        role: row.role?.name || "-",
        note: row.note?.custom_location || row.note?.location?.name || "-",
        city: row.city?.name || "-",
        age: row.age || "-",
        language: row.user_languages || [],
        gender: shared.genderOptions[row.gender] || "-",
        weight: row.weight ? `${row.weight} ${shared.kg}` : "-",
        height: row.height ? `${row.height} ${shared.cm}` : "-",
        profileStatus: applicants.listApplicants[row.profile_status] || "-",
        applicantStatus: row.participated_in_events_count
          ? applicants.listApplicants.assigned
          : applicants.listApplicants.notAssigned,
        disability: {
          data: row.disabilities || [],
          status: row.profile_status,
        },
        diseases: {
          data: row.chronic_diseases || [],
          status: row.profile_status,
        },
        profileStatusValue: row.profile_status,
        img: row.image,
      }))
    );
  }, [list]);

  // 8) EFFECT TO INITIAL FILTER'S & INITIAL APPLICANTS
  useEffect(() => {
    dispatch(initialFetch());
  }, [filters, note]);

  // 9) QUERY TO SEARCH APPLICANTS
  useEffect(() => {
    if ((q.length >= 3 || q.length === 0) && !textContainOnlySpaces(q)) {
      dispatch(setSearchValue(q));
    }
  }, [q]);

  useEffect(() => {
    dispatch(getLocationsRequest());
    dispatch(getSpeakingProficiencyRequest());
    dispatch(getCountriesRequest({ page: -1 }));
    dispatch(
      setOrder({
        order_by_first_name_en: lang === "en" ? "asc" : null,
        order_by_first_name_ar: lang === "ar" ? "asc" : null,
      })
    );
    dispatch(
      getLookupsRequest([
        lookupsKeys.disabilities,
        lookupsKeys.chronicDiseases,
        lookupsKeys.languages,
        lookupsKeys.roles,
      ])
    );
    dispatch(
      getCitiesRequest({
        page: -1,
        order_by_name_en: lang === "en" ? "asc" : null,
        order_by_name_ar: lang === "ar" ? "asc" : null,
      })
    );
  }, [lang, note]);

  const handleDeleteLocation = (id) => {
    dispatch(deleteLocationRequest({ id }));
    history.push(ROUTE_PATHS["applicants"]);
    setIsNoteModalOpen(false);
  };

  const viewApplicantHandler = (event) => {
    const applicant = list.find((applicant) => applicant.id === event.row.id);
    if (
      applicant?.profile_status === applicantsProfileStatus.profileCompleted ||
      applicant?.profile_status ===
        applicantsProfileStatus.basicProfileCompleted
    ) {
      history.push(ROUTE_PATHS.viewApplicant.replace(":id", event.row.id));
    }
  };

  const renderApplicantList = () => {
    if (!isLoading && !list?.length) {
      return (
        <>
          <div className="bg-white rounded-4 d-flex justify-content-center align-items-center flex-column my-4 pt-5">
            <EmptyState
              description={applicants.listApplicants.noDataDescription}
              showActionButton={false}
              imgSrc={applicantPlaceholder}
            />
          </div>
        </>
      );
    } else {
      return (
        <>
          <div>
            <DynamicTable
              clickCellHandler={viewApplicantHandler}
              rows={rows}
              columns={columns}
              checkboxSelection={false}
              getRowClassName={(params) => {
                if (
                  !params.row.name.isBlocked &&
                  params.row.profileStatusValue !==
                    applicantsProfileStatus.verified
                ) {
                  return "row-hover";
                }
              }}
            />
          </div>
          <div className="row my-4">
            <div className="col-md-6 col-xl-7 d-flex align-items-center justify-content-end">
              <div className="d-flex justify-content-center py-3 mt-4">
                {meta.pages > 1 && (
                  <Pagination
                    count={meta.pages}
                    page={meta.currentPage}
                    handleChange={(event, page) => {
                      handlePageChange(page);
                    }}
                    defaultPage={1}
                  />
                )}
              </div>
            </div>
            <div className="col-md-6 col-xl-5 d-flex align-items-center justify-content-end">
              <div className="rows-count-selection">
                <Select
                  name="rows-count"
                  label={shared.numberOfRows}
                  value={rowsCountOptions.find(
                    (count) => count.id === filters.rowsCount
                  )}
                  onChange={(count) => {
                    handleRowsCountChange(count.id);
                  }}
                  options={rowsCountOptions}
                  wrapperClass={`rows-count-input`}
                  disableClearable={true}
                />
              </div>
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <div className="applicants-page-container">
      <div className="page-title-margin">
        <Breadcrumbs
          list={[
            {
              path: "",
              label: applicants.applicantsList,
            },
          ]}
          BreadCrumbHasBackIcon={false}
        />
      </div>
      <Filters
        searchInput={{
          placeholder: applicants.listApplicants.searchPlaceHolder,
          name: "search",
          value: q,
          onChange: (value) => setQuery(value),
        }}
        renderActionNextToSearch={
          list.length > 0 && (
            <Button
              label={
                <span className="d-flex align-items-center gap-2">
                  <Downloading />
                  <span>{applicants.applicantDetails.downloadProfiles}</span>
                </span>
              }
              className={lang === "en" ? "ms-3" : "me-3"}
              onClick={() => {
                downloadProfile(list, applicants, lang, proLineLogo, userBase);
              }}
            />
          )
        }
        filters={[
          {
            type: "filter",
            name: "profileStatus",
            placeholder: applicants.listApplicants.profileStatus,
            value: filters.profile_status,
            options: profileStatusOptions,
            onChange: (value) => {
              handleFilterChange({ profile_status: value });
            },
          },
          {
            type: "filter",
            name: "applicantStatus",
            placeholder: applicants.listApplicants.applicantStatus,
            value: filters.is_assigned_to_event,
            options: applicantStatusOptions,
            onChange: (value) =>
              handleFilterChange({ is_assigned_to_event: value }),
          },
          {
            type: "filter",
            name: "role",
            placeholder: applicants.listApplicants.role,
            value: filters.role_id,
            options: roles,
            onChange: (value) => handleFilterChange({ role_id: value }),
          },
          {
            type: "filter",
            name: "gender",
            placeholder: applicants.listApplicants.gender,
            value: filters.gender,
            options: genderOptions,
            onChange: (value) => handleFilterChange({ gender: value }),
          },
          {
            type: "filter",
            name: "height",
            placeholder: applicants.listApplicants.height,
            value: filters.height,
            options: heightOptions,
            onChange: (value) => handleFilterChange({ height: value }),
          },
          {
            type: "filter",
            name: "weight",
            placeholder: applicants.listApplicants.weight,
            value: filters.weight,
            options: weightOptions,
            onChange: (value) => handleFilterChange({ weight: value }),
          },
          {
            type: "filter",
            name: "disability",
            placeholder: applicants.listApplicants.disability,
            value: filters.disability_id,
            options: disabilities
              ? [
                  ...disabilities,
                  { id: 0, name: applicants.listApplicants.noDisability },
                ]
              : [],
            onChange: (value) => handleFilterChange({ disability_id: value }),
          },
          {
            type: "filter",
            name: "diseases",
            placeholder: applicants.listApplicants.diseases,
            value: filters.chronic_disease_id,
            options: chronicDiseases
              ? [
                  ...chronicDiseases,
                  { id: 0, name: applicants.listApplicants.noDisease },
                ]
              : [],
            onChange: (value) =>
              handleFilterChange({ chronic_disease_id: value }),
          },
          {
            type: "filter",
            name: "age",
            placeholder: applicants.listApplicants.age,
            value: filters.age,
            options: ageOptions,
            onChange: (value) => handleFilterChange({ age: value }),
          },
          {
            type: "filter",
            name: "city",
            placeholder: applicants.listApplicants.cityOfResidence,
            value: filters.city_id,
            options: cities,
            onChange: (value) => handleFilterChange({ city_id: value }),
          },
          {
            type: "filter",
            name: "nationality",
            placeholder: applicants.listApplicants.nationality,
            value: filters.nationality_id,
            options: countries,
            onChange: (value) => handleFilterChange({ nationality_id: value }),
          },
          {
            type: "filter",
            name: "language",
            placeholder: applicants.listApplicants.language,
            value: filters.language_id,
            options: languages,
            onChange: (value) => handleFilterChange({ language_id: value }),
          },
          {
            type: "filter",
            name: "Speaking Proficiency",
            placeholder: applicants.listApplicants.speaking,
            value: filters?.speaking_proficiency,
            options: updatedSpeaking,
            onChange: (value) =>
              handleFilterChange({ speaking_proficiency: value }),
          },
        ]}
      />
      <div className="d-flex flex-column justify-content-between">
        {renderApplicantList()}
        <Modal
          open={isNoteModalOpen}
          title={shared.addYourNote}
          handleClose={handleModalClose}
          cancelBtnTxt={shared.cancel}
          handleCancel={handleModalClose}
          confirmBtnTxt={shared.submit}
          handleConfirm={handleSubmit}
          isDisabled={validate}
          content={
            <Note
              validate={validate}
              setValidate={setValidate}
              handleSubmit={handleSubmit}
              handleDelete={(id) => handleDeleteLocation(id)}
              setOtherValue={setOtherValue}
              otherValue={otherValue}
              selectValue={selectValue}
              setSelectValue={setSelectValue}
              location={location}
              handleCancel={handleModalClose}
            />
          }
        />
      </div>
    </div>
  );
};

export default Applicants;
