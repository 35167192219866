import { put, takeLatest, select, call } from "redux-saga/effects";
import * as types from "./types";
import * as actions from "./actions";
import { showErrorMsg } from "../../utils/Helpers";
import { getDailyAndMonthlyReportsRequests } from "../TimeSheet/actions";

function* fetchTimeSheetEventListSaga(action) {
  const { id } = action.payload;
  const { page, rowsCount, searchValue, filterRole, filterStartDate } =
    yield select((state) => state.persistTimeSheetEvent);

  yield put(
    getDailyAndMonthlyReportsRequests({
      page,
      items: rowsCount,
      name: searchValue,
      event_id: id,
      role_id: filterRole.join(","),
      month_in_a_date: filterStartDate,
    })
  );
}

function* fetchInitialStateEventSaga(action) {
  try {
    yield call(fetchTimeSheetEventListSaga, action);
  } catch (error) {
    yield showErrorMsg(error);
  }
}

function* pageTimeSheetEventSaga(action) {
  try {
    const { page } = yield select((state) => state.persistTimeSheetEvent);
    if (action.payload !== page) {
      yield put(actions.setTimeSheetEventPage(action.payload));
      yield call(fetchTimeSheetEventListSaga);
    }
  } catch (error) {
    yield showErrorMsg(error);
  }
}

function* filterTimeSheetEventSaga(action) {
  try {
    const { currentRoles } = yield select(
      (state) => state.persistTimeSheetEvent
    );
    if (JSON.stringify(action.payload) !== JSON.stringify(currentRoles)) {
      yield put(actions.setTimeSheetEventFilters(currentRoles));
      yield call(fetchTimeSheetEventListSaga);
    }
  } catch (error) {
    yield showErrorMsg(error);
  }
}

export default function* persistTimeSheetEventSaga() {
  yield takeLatest(
    types.INITIAL_TIMESHEET_EVENT_FETCH,
    fetchInitialStateEventSaga
  );
  yield takeLatest(types.SET_TIMESHEET_EVENT_PAGE, pageTimeSheetEventSaga);
  yield takeLatest(types.SET_TIMESHEET_EVENT_FILTERS, filterTimeSheetEventSaga);
}
