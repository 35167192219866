import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Clear,
  AccessTime,
  // TODO: Uncomment when needed again
  // AddIcon
} from "@mui/icons-material";
import { FormHelperText } from "@mui/material";
import { FieldArray } from "formik";
import dayjs from "dayjs";

import messages from "../../../../assets/locale/messages";
import Input from "../../../../components/Input";
import Select from "../../../../components/Select";
import TimePicker from "../../../../components/TimePicker";
// TODO: Uncomment when needed again
// import Button from "../../../../components/Button";
import Accordion from "../../../../components/Accordion";
import { eventStatuses } from "../../../../utils/Constants";
import { checkFor12amIssue } from "../../../../utils/Helpers";
import "./RoleFields.scss";

const isSameOrBefore = require("dayjs/plugin/isSameOrBefore");
dayjs.extend(isSameOrBefore);

// Fixed slot margins equals to 4 min
const marginLimit = 240;

const RoleFields = ({
  role,
  removeClickFunction,
  formik,
  roleFieldindex,
  rolesCount,
  isEventUpcoming,
  isEventOngoing,
  isEventDaily,
  setAreMarginsValid,
}) => {
  const { id } = useParams();

  const lang = useSelector((state) => state.locale.lang);
  const rolesOptions = useSelector((state) => state.lookups.general.roles);
  const event = useSelector((state) => state.events.event);
  const activePublishMode = useSelector(
    (state) => state.routing.activePublishMode
  );
  const {
    events: { creatEvent },
    inputsValidations,
  } = messages[lang];
  const workingHoursSlotsArrayHelpersRef = useRef(null);

  const [roleName, setRoleName] = useState(creatEvent.role);
  const [checkinٍStartMarginErr, setCheckinStartMarginErr] = useState("");
  const [checkinٍEndMarginErr, setCheckinEndMarginErr] = useState("");
  const [checkinٍMarginTotalErr, setCheckinMarginTotalErr] = useState("");
  const [checkoutStartMarginErr, setCheckoutStartMarginErr] = useState("");
  const [checkoutEndMarginErr, setCheckoutEndMarginErr] = useState("");
  const [checkoutMarginTotalErr, setCheckoutMarginTotalErr] = useState("");
  const [marginsOverlapErr, setMarginsOverlapErr] = useState("");

  // TODO: Uncomment when needed again
  // const onAddSlotBtnClick = () => {
  //   workingHoursSlotsArrayHelpersRef.current.push({
  //     starts_at: null,
  //     ends_at: null,
  //   });
  // };

  useEffect(() => {
    if (role?.role_id) {
      const selectedItemLabel = rolesOptions?.find(
        (item) => item.id === role?.role_id
      )?.name;
      setRoleName(selectedItemLabel);
    } else {
      setRoleName(creatEvent.role);
    }
  }, [role, event, lang, rolesOptions]);

  useEffect(() => {
    if (
      checkinٍStartMarginErr ||
      checkinٍEndMarginErr ||
      checkinٍMarginTotalErr ||
      checkoutStartMarginErr ||
      checkoutEndMarginErr ||
      checkoutMarginTotalErr ||
      marginsOverlapErr
    ) {
      setAreMarginsValid(false);
    } else {
      setAreMarginsValid(true);
    }
  }, [
    checkinٍStartMarginErr,
    checkinٍEndMarginErr,
    checkinٍMarginTotalErr,
    checkoutStartMarginErr,
    checkoutEndMarginErr,
    checkoutMarginTotalErr,
    marginsOverlapErr,
  ]);

  useEffect(() => {
    validateTimeMargins();
  }, [
    formik.values.event_roles_attributes[roleFieldindex].before_check_in_margin,
    formik.values.event_roles_attributes[roleFieldindex].after_check_in_margin,
    formik.values.event_roles_attributes[roleFieldindex]
      .before_check_out_margin,
    formik.values.event_roles_attributes[roleFieldindex].after_check_out_margin,
    formik.values.event_roles_attributes[roleFieldindex]
      .working_hours_intervals,
  ]);

  const validateTimeMargins = () => {
    const {
      before_check_in_margin: checkinStart,
      after_check_in_margin: checkinEnd,
      before_check_out_margin: checkoutStart,
      after_check_out_margin: checkoutEnd,
      working_hours_intervals,
    } = formik.values.event_roles_attributes[roleFieldindex];
    const { starts_at: slotStart, ends_at: slotEnd } =
      working_hours_intervals[0];

    // Validate checkin margins maximum & minimum limits
    setCheckinMarginTotalErr(
      checkinStart && checkinEnd && checkinStart + checkinEnd > marginLimit
        ? "checkinMarginsTotalMax"
        : checkinStart == 0 && checkinEnd == 0
        ? "checkinMarginsTotalMin"
        : ""
    );
    setCheckinStartMarginErr(
      checkinStart && checkinStart > marginLimit ? "checkinStartMarginMax" : ""
    );
    setCheckinEndMarginErr(
      checkinEnd && checkinEnd > marginLimit ? "checkinEndMarginMax" : ""
    );

    // Validate checkout margins maximum & minimum limit
    setCheckoutMarginTotalErr(
      checkoutStart && checkoutEnd && checkoutStart + checkoutEnd > marginLimit
        ? "checkoutMarginsTotalMax"
        : checkoutStart == 0 && checkoutEnd == 0
        ? "checkoutMarginsTotalMin"
        : ""
    );
    setCheckoutStartMarginErr(
      checkoutStart && checkoutStart > marginLimit
        ? "checkoutStartMarginMax"
        : ""
    );
    setCheckoutEndMarginErr(
      checkoutEnd && checkoutEnd > marginLimit ? "checkoutEndMarginMax" : ""
    );

    // Get Shift duration to validate margins overlapping
    let shiftTime = 0;
    if (slotStart && slotEnd) {
      const startDate = dayjs(slotStart, "HH:mm");
      let endDate = dayjs(slotEnd, "HH:mm");
      if (endDate.isSameOrBefore(startDate)) {
        endDate = endDate.add(1, "day");
      }
      shiftTime = endDate.diff(startDate, "minute");
    }
    setMarginsOverlapErr(
      shiftTime &&
        checkinEnd &&
        checkoutStart &&
        checkinEnd + checkoutStart > shiftTime
        ? "marginsOverlapWithSlot"
        : ""
    );
  };

  const calculateMarginTime = (slot, margin, isBefore) => {
    if (slot && (margin || margin == 0)) {
      const slotDate = dayjs(slot, "HH:mm");
      const marginDate = isBefore
        ? slotDate.subtract(margin, "minute")
        : slotDate.add(margin, "minute");
      const marginTime =
        lang === "en"
          ? dayjs(marginDate).format("hh:mm a")
          : checkFor12amIssue(marginDate);
      return marginTime;
    } else {
      return "--:-- --";
    }
  };

  useEffect(() => {
    if (
      checkinٍStartMarginErr ||
      checkinٍEndMarginErr ||
      checkinٍMarginTotalErr ||
      checkoutStartMarginErr ||
      checkoutEndMarginErr ||
      checkoutMarginTotalErr ||
      marginsOverlapErr
    ) {
      setAreMarginsValid(false);
    } else {
      setAreMarginsValid(true);
    }
  }, [
    checkinٍStartMarginErr,
    checkinٍEndMarginErr,
    checkinٍMarginTotalErr,
    checkoutStartMarginErr,
    checkoutEndMarginErr,
    checkoutMarginTotalErr,
    marginsOverlapErr,
  ]);

  return (
    <div className="roles-container">
      <Accordion
        title={roleName}
        accordionNumber={roleFieldindex}
        isExpandedInitially={activePublishMode}
        extraFunctionBtn={
          (roleFieldindex === 0 && rolesCount === 1) ||
          (id &&
            [
              eventStatuses.ongoing,
              eventStatuses.cancelled,
              eventStatuses.past,
              eventStatuses.upcoming,
            ].includes(event?.status)) ? (
            <></>
          ) : (
            <span
              className="cursor-pointer"
              onClick={() => removeClickFunction(true)}>
              <Clear />
            </span>
          )
        }
        content={
          <>
            <Select
              id={`event_roles_attributes.${roleFieldindex}.role_id`}
              name={`event_roles_attributes.${roleFieldindex}.role_id`}
              options={rolesOptions}
              label={creatEvent.labels.nameOfRole}
              variant
              block
              labelClassName="text-gray-title  pb-2"
              placeholder={creatEvent.placeholders.nameOfRole}
              value={
                rolesOptions?.find((option) => option.id == role?.role_id) ||
                null
              }
              onChange={(value) => {
                formik.setFieldTouched(
                  `event_roles_attributes.${roleFieldindex}.role_id`
                );
                formik.setFieldValue(
                  `event_roles_attributes.${roleFieldindex}.role_id`,
                  value ? value.id : ""
                );
                if (!value && role.id) {
                  removeClickFunction(false);
                }
              }}
              hasError={
                !!(
                  formik.touched?.event_roles_attributes?.[roleFieldindex]
                    ?.role_id &&
                  formik.errors?.event_roles_attributes?.[roleFieldindex]
                    ?.role_id
                )
              }
              errMsg={
                formik.errors?.event_roles_attributes?.[roleFieldindex]?.role_id
              }
              required={activePublishMode || isEventUpcoming}
              disabled={
                id &&
                role.id &&
                [
                  eventStatuses.ongoing,
                  eventStatuses.cancelled,
                  eventStatuses.past,
                  eventStatuses.upcoming,
                ].includes(event?.status)
              }
            />
            <div className="row">
              <div className="col-md-6">
                <Input
                  id={`event_roles_attributes.${roleFieldindex}.maximum_number_of_males`}
                  name={`event_roles_attributes.${roleFieldindex}.maximum_number_of_males`}
                  label={creatEvent.labels.numberOfMales}
                  type="text"
                  placeholder={creatEvent.placeholders.numberOfMales}
                  value={role?.maximum_number_of_males || ""}
                  onChange={(value) => {
                    formik.setFieldTouched(
                      `event_roles_attributes.${roleFieldindex}.maximum_number_of_males`
                    );
                    formik.setFieldValue(
                      `event_roles_attributes.${roleFieldindex}.maximum_number_of_males`,
                      value || null
                    );
                  }}
                  inputClass="mb-1"
                  labelClassName="font-medium mb-2 text-primary field-label"
                  inputWrapperClass="create-event-input"
                  isInputHasErr={
                    !!(
                      formik.touched?.event_roles_attributes?.[roleFieldindex]
                        ?.maximum_number_of_males &&
                      formik.errors?.event_roles_attributes?.[roleFieldindex]
                        ?.maximum_number_of_males
                    )
                  }
                  errMsg={
                    formik.errors?.event_roles_attributes?.[roleFieldindex]
                      ?.maximum_number_of_males
                  }
                  disabled={
                    id &&
                    [
                      eventStatuses.cancelled,
                      eventStatuses.past,
                    ].includes(event?.status)
                  }
                  required={activePublishMode || isEventUpcoming}
                />
              </div>
              <div className="col-md-6">
                <Input
                  id={`event_roles_attributes.${roleFieldindex}.maximum_number_of_females`}
                  name={`event_roles_attributes.${roleFieldindex}.maximum_number_of_females`}
                  label={creatEvent.labels.numberOfFemales}
                  type="text"
                  placeholder={creatEvent.placeholders.numberOfFemales}
                  value={role?.maximum_number_of_females || ""}
                  onChange={(value) => {
                    formik.setFieldTouched(
                      `event_roles_attributes.${roleFieldindex}.maximum_number_of_females`
                    );
                    formik.setFieldValue(
                      `event_roles_attributes.${roleFieldindex}.maximum_number_of_females`,
                      value || null
                    );
                  }}
                  inputClass="mb-1"
                  labelClassName="font-medium mb-2 text-primary field-label"
                  inputWrapperClass="create-event-input"
                  isInputHasErr={
                    !!(
                      formik.touched?.event_roles_attributes?.[roleFieldindex]
                        ?.maximum_number_of_females &&
                      formik.errors?.event_roles_attributes?.[roleFieldindex]
                        ?.maximum_number_of_females
                    )
                  }
                  errMsg={
                    formik.errors?.event_roles_attributes?.[roleFieldindex]
                      ?.maximum_number_of_females
                  }
                  disabled={
                    id &&
                    [
                      eventStatuses.cancelled,
                      eventStatuses.past,
                    ].includes(event?.status)
                  }
                  required={activePublishMode || isEventUpcoming}
                />
              </div>
            </div>
            {/* TODO: Uncomment when needed again */}
            {/* <Input
              id={`event_roles_attributes.${roleFieldindex}.number_of_shifts`}
              name={`event_roles_attributes.${roleFieldindex}.number_of_shifts`}
              label={creatEvent.labels.numberOfShifts}
              type="text"
              placeholder={creatEvent.placeholders.numberOfShifts}
              value={role?.number_of_shifts || ""}
              onChange={(value) => {
                formik.setFieldTouched(
                  `event_roles_attributes.${roleFieldindex}.number_of_shifts`
                );
                formik.setFieldValue(
                  `event_roles_attributes.${roleFieldindex}.number_of_shifts`,
                  value || null
                );
              }}
              inputClass="mb-1"
              labelClassName="font-medium mb-2 text-primary field-label"
              inputWrapperClass="create-event-input"
              isInputHasErr={
                !!(
                  formik.touched?.event_roles_attributes?.[roleFieldindex]
                    ?.number_of_shifts &&
                  formik.errors?.event_roles_attributes?.[roleFieldindex]
                    ?.number_of_shifts
                )
              }
              errMsg={
                formik.errors?.event_roles_attributes?.[roleFieldindex]
                  ?.number_of_shifts
              }
              disabled={
                id &&
                [
                  eventStatuses.ongoing,
                  eventStatuses.cancelled,
                  eventStatuses.past,
                ].includes(event?.status)
              }
              required={activePublishMode || isEventUpcoming}
            /> */}
            <div className="slots-list">
              <FieldArray
                name={`event_roles_attributes.${roleFieldindex}.working_hours_intervals`}
                render={(arrayHelpers) => {
                  workingHoursSlotsArrayHelpersRef.current = arrayHelpers;

                  return role?.working_hours_intervals?.map((slot, index) => (
                    <div
                      className="row"
                      id={`slot-${index}`}
                      key={`slot-${index}`}>
                      <div className="col-12">
                        <p className="time-slots-label fsize-16">
                          {index === 0 ? creatEvent.labels.workingHours : ""}
                          {(activePublishMode ||
                            isEventUpcoming ||
                            isEventOngoing) &&
                          index === 0 ? (
                            <span className="err px-1">*</span>
                          ) : (
                            ""
                          )}
                        </p>
                      </div>
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-6">
                            <TimePicker
                              id={`working_hours_intervals.${index}.starts_at`}
                              name={`working_hours_intervals.${index}.starts_at`}
                              value={slot?.starts_at || ""}
                              onChange={(value) => {
                                formik.setFieldTouched(
                                  `event_roles_attributes.${roleFieldindex}.working_hours_intervals.${index}.starts_at`
                                );
                                formik.setFieldValue(
                                  `event_roles_attributes.${roleFieldindex}.working_hours_intervals.${index}.starts_at`,
                                  value || null
                                );
                              }}
                              isInputHasErr={
                                !!(
                                  formik.touched?.event_roles_attributes?.[
                                    roleFieldindex
                                  ]?.working_hours_intervals?.[index]
                                    ?.starts_at &&
                                  formik.errors?.event_roles_attributes?.[
                                    roleFieldindex
                                  ]?.working_hours_intervals?.[index]?.starts_at
                                )
                              }
                              errMsg={
                                formik.errors?.event_roles_attributes?.[
                                  roleFieldindex
                                ]?.working_hours_intervals?.[index]?.starts_at
                              }
                              placeholder={"hh:mm AM"}
                              innerLabel={creatEvent.start}
                              disabled={
                                id &&
                                [
                                  eventStatuses.cancelled,
                                  eventStatuses.past,
                                ].includes(event?.status)
                              }
                            />
                          </div>
                          <div className="col-md-6">
                            <TimePicker
                              id={`working_hours_intervals.${index}.ends_at`}
                              name={`working_hours_intervals.${index}.ends_at`}
                              value={slot?.ends_at || ""}
                              onChange={(value) => {
                                formik.setFieldTouched(
                                  `event_roles_attributes.${roleFieldindex}.working_hours_intervals.${index}.ends_at`
                                );
                                formik.setFieldValue(
                                  `event_roles_attributes.${roleFieldindex}.working_hours_intervals.${index}.ends_at`,
                                  value || null
                                );
                              }}
                              isInputHasErr={
                                !!(
                                  formik.touched?.event_roles_attributes?.[
                                    roleFieldindex
                                  ]?.working_hours_intervals?.[index]
                                    ?.ends_at &&
                                  formik.errors?.event_roles_attributes?.[
                                    roleFieldindex
                                  ]?.working_hours_intervals?.[index]?.ends_at
                                )
                              }
                              errMsg={
                                formik.errors?.event_roles_attributes?.[
                                  roleFieldindex
                                ]?.working_hours_intervals?.[index]?.ends_at
                              }
                              placeholder={"hh:mm AM"}
                              innerLabel={creatEvent.end}
                              disabled={
                                id &&
                                [
                                  eventStatuses.cancelled,
                                  eventStatuses.past,
                                ].includes(event?.status)
                              }
                            />
                          </div>
                        </div>
                      </div>
                      {/* TODO: Uncomment when needed again */}
                      {/* <div className="col-md-1">
                        {index === 0 &&
                          [
                            eventStatuses.pending,
                            eventStatuses.upcoming,
                          ].includes(event?.status) && (
                            <div className="w-100 h-100 d-flex align-items-end justify-content-center">
                              <Button
                                onClick={() => {
                                  onAddSlotBtnClick();
                                }}
                                block
                                label={
                                  <AddIcon
                                    fontSize="medium"
                                    className="text-white"
                                  />
                                }
                                labelClass="px-4"
                                outlined
                                className="mx-2 my-2 mt-md-0 add-slot-btn"
                              />
                            </div>
                          )}

                        {index !== 0 &&
                          [
                            eventStatuses.pending,
                            eventStatuses.upcoming,
                          ].includes(event?.status) && (
                            <div className="w-100 h-100 d-flex align-items-end justify-content-center">
                              <Button
                                onClick={() => {
                                  arrayHelpers.remove(index);
                                }}
                                block
                                label={
                                  <Clear
                                    fontSize="medium"
                                    className="text-white"
                                  />
                                }
                                labelClass="px-4"
                                outlined
                                className="mx-2 my-2 mt-md-0 add-slot-btn"
                              />
                            </div>
                          )}
                      </div> */}
                    </div>
                  ));
                }}
              />
            </div>
            {/* TODO: Uncomment when needed again */}
            {/* <TimePicker
              id={`event_roles_attributes.${roleFieldindex}.arriving_at`}
              name={`event_roles_attributes.${roleFieldindex}.arriving_at`}
              value={role?.arriving_at || ""}
              onChange={(value) => {
                formik.setFieldTouched(
                  `event_roles_attributes.${roleFieldindex}.arriving_at`
                );
                formik.setFieldValue(
                  `event_roles_attributes.${roleFieldindex}.arriving_at`,
                  value
                );
              }}
              isInputHasErr={
                !!(
                  formik.touched?.event_roles_attributes?.[roleFieldindex]
                    ?.arriving_at &&
                  formik.errors?.event_roles_attributes?.[roleFieldindex]
                    ?.arriving_at
                )
              }
              errMsg={
                formik.errors?.event_roles_attributes?.[roleFieldindex]
                  ?.arriving_at
              }
              placeholder={"hh:mm (A|P)M"}
              innerLabel={creatEvent.placeholders.timeToArriveToTheSite}
              label={creatEvent.labels.timeToArriveToTheSite}
              inputClass="remove-touched-label"
              disabled={
                id &&
                [
                  eventStatuses.ongoing,
                  eventStatuses.cancelled,
                  eventStatuses.past,
                ].includes(event?.status)
              }
              required={activePublishMode || isEventUpcoming}
              helperTextClass="mb-3"
            /> */}
            {isEventDaily && (
              <div className="row time-margins">
                <div className="col-12">
                  <p className="margins-labels fsize-16 mb-0 mt-4">
                    {creatEvent.labels.checkInTimeMargin}
                  </p>
                </div>
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-xl-9 col-lg-8 col-md-7">
                      <Input
                        id={`event_roles_attributes.${roleFieldindex}.before_check_in_margin`}
                        name={`event_roles_attributes.${roleFieldindex}.before_check_in_margin`}
                        label={creatEvent.labels.checkinStart}
                        type="text"
                        placeholder={creatEvent.placeholders.checkinStart}
                        value={
                          role?.before_check_in_margin ||
                          role?.before_check_in_margin == 0
                            ? role?.before_check_in_margin
                            : ""
                        }
                        onChange={(value) => {
                          formik.setFieldTouched(
                            `event_roles_attributes.${roleFieldindex}.before_check_in_margin`
                          );
                          formik.setFieldValue(
                            `event_roles_attributes.${roleFieldindex}.before_check_in_margin`,
                            value ? +value : null
                          );
                        }}
                        inputClass="mb-1"
                        labelClassName="font-medium mb-2 text-primary field-label"
                        inputWrapperClass="create-event-input"
                        isInputHasErr={
                          !!(
                            formik.touched?.event_roles_attributes?.[
                              roleFieldindex
                            ]?.before_check_in_margin &&
                            formik.errors?.event_roles_attributes?.[
                              roleFieldindex
                            ]?.before_check_in_margin
                          )
                        }
                        errMsg={
                          formik.errors?.event_roles_attributes?.[
                            roleFieldindex
                          ]?.before_check_in_margin
                        }
                        disabled={
                          id &&
                          [
                            eventStatuses.cancelled,
                            eventStatuses.past,
                          ].includes(event?.status)
                        }
                        required={
                          (activePublishMode ||
                            isEventUpcoming ||
                            isEventOngoing) &&
                          isEventDaily
                        }
                      />
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-5 d-flex align-items-end justify-content-center">
                      <div className="show-time-margin mb-2 w-100">
                        <div>
                          <AccessTime
                            className={`clock-icon ${
                              lang === "en" ? "me-1" : "ms-1"
                            }`}
                          />
                          <span className="time">
                            {calculateMarginTime(
                              role?.working_hours_intervals[0].starts_at,
                              role?.before_check_in_margin,
                              true
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <FormHelperText
                    error
                    className={`font-medium fsize-12 ${
                      lang === "en" ? "" : "text-end"
                    }`}>
                    {inputsValidations[checkinٍStartMarginErr]}
                  </FormHelperText>
                </div>
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-xl-9 col-lg-8 col-md-7">
                      <Input
                        id={`event_roles_attributes.${roleFieldindex}.after_check_in_margin`}
                        name={`event_roles_attributes.${roleFieldindex}.after_check_in_margin`}
                        label={creatEvent.labels.checkinEnd}
                        type="text"
                        placeholder={creatEvent.placeholders.checkinEnd}
                        value={
                          role?.after_check_in_margin ||
                          role?.after_check_in_margin == 0
                            ? role?.after_check_in_margin
                            : ""
                        }
                        onChange={(value) => {
                          formik.setFieldTouched(
                            `event_roles_attributes.${roleFieldindex}.after_check_in_margin`
                          );
                          formik.setFieldValue(
                            `event_roles_attributes.${roleFieldindex}.after_check_in_margin`,
                            value ? +value : null
                          );
                        }}
                        inputClass="mb-1"
                        labelClassName="font-medium mb-2 text-primary field-label"
                        inputWrapperClass="create-event-input"
                        isInputHasErr={
                          !!(
                            formik.touched?.event_roles_attributes?.[
                              roleFieldindex
                            ]?.after_check_in_margin &&
                            formik.errors?.event_roles_attributes?.[
                              roleFieldindex
                            ]?.after_check_in_margin
                          )
                        }
                        errMsg={
                          formik.errors?.event_roles_attributes?.[
                            roleFieldindex
                          ]?.after_check_in_margin
                        }
                        disabled={
                          id &&
                          [
                            eventStatuses.cancelled,
                            eventStatuses.past,
                          ].includes(event?.status)
                        }
                        required={
                          (activePublishMode ||
                            isEventUpcoming ||
                            isEventOngoing) &&
                          isEventDaily
                        }
                      />
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-5 d-flex align-items-end justify-content-center">
                      <div className="show-time-margin mb-2 w-100">
                        <div>
                          <AccessTime
                            className={`clock-icon ${
                              lang === "en" ? "me-1" : "ms-1"
                            }`}
                          />
                          <span className="time">
                            {calculateMarginTime(
                              role?.working_hours_intervals[0].starts_at,
                              role?.after_check_in_margin,
                              false
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <FormHelperText
                    error
                    className={`font-medium fsize-12 ${
                      lang === "en" ? "" : "text-end"
                    }`}>
                    {inputsValidations[checkinٍEndMarginErr]}
                  </FormHelperText>
                </div>
                <div className="col-12">
                  <FormHelperText
                    error
                    className={`font-medium fsize-12 ${
                      lang === "en" ? "" : "text-end"
                    }`}>
                    {inputsValidations[checkinٍMarginTotalErr]}
                  </FormHelperText>
                </div>
                <div className="col-12">
                  <p className="margins-labels fsize-16 mb-0 mt-3">
                    {creatEvent.labels.checkOutTimeMargin}
                  </p>
                </div>
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-xl-9 col-lg-8 col-md-7">
                      <Input
                        id={`event_roles_attributes.${roleFieldindex}.before_check_out_margin`}
                        name={`event_roles_attributes.${roleFieldindex}.before_check_out_margin`}
                        label={creatEvent.labels.checkoutStart}
                        type="text"
                        placeholder={creatEvent.placeholders.checkoutStart}
                        value={
                          role?.before_check_out_margin ||
                          role?.before_check_out_margin == 0
                            ? role?.before_check_out_margin
                            : ""
                        }
                        onChange={(value) => {
                          formik.setFieldTouched(
                            `event_roles_attributes.${roleFieldindex}.before_check_out_margin`
                          );
                          formik.setFieldValue(
                            `event_roles_attributes.${roleFieldindex}.before_check_out_margin`,
                            value ? +value : null
                          );
                        }}
                        inputClass="mb-1"
                        labelClassName="font-medium mb-2 text-primary field-label"
                        inputWrapperClass="create-event-input"
                        isInputHasErr={
                          !!(
                            formik.touched?.event_roles_attributes?.[
                              roleFieldindex
                            ]?.before_check_out_margin &&
                            formik.errors?.event_roles_attributes?.[
                              roleFieldindex
                            ]?.before_check_out_margin
                          )
                        }
                        errMsg={
                          formik.errors?.event_roles_attributes?.[
                            roleFieldindex
                          ]?.before_check_out_margin
                        }
                        disabled={
                          id &&
                          [
                            // eventStatuses.ongoing,
                            eventStatuses.cancelled,
                            eventStatuses.past,
                          ].includes(event?.status)
                        }
                        required={
                          (activePublishMode ||
                            isEventUpcoming ||
                            isEventOngoing) &&
                          isEventDaily
                        }
                      />
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-5 d-flex align-items-end justify-content-center">
                      <div className="show-time-margin mb-2 w-100">
                        <div>
                          <AccessTime
                            className={`clock-icon ${
                              lang === "en" ? "me-1" : "ms-1"
                            }`}
                          />
                          <span className="time">
                            {calculateMarginTime(
                              role?.working_hours_intervals[0].ends_at,
                              role?.before_check_out_margin,
                              true
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <FormHelperText
                    error
                    className={`font-medium fsize-12 ${
                      lang === "en" ? "" : "text-end"
                    }`}>
                    {inputsValidations[checkoutStartMarginErr]}
                  </FormHelperText>
                </div>
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-xl-9 col-lg-8 col-md-7">
                      <Input
                        id={`event_roles_attributes.${roleFieldindex}.after_check_out_margin`}
                        name={`event_roles_attributes.${roleFieldindex}.after_check_out_margin`}
                        label={creatEvent.labels.checkoutEnd}
                        type="text"
                        placeholder={creatEvent.placeholders.checkoutEnd}
                        value={
                          role?.after_check_out_margin ||
                          role?.after_check_out_margin == 0
                            ? role?.after_check_out_margin
                            : ""
                        }
                        onChange={(value) => {
                          formik.setFieldTouched(
                            `event_roles_attributes.${roleFieldindex}.after_check_out_margin`
                          );
                          formik.setFieldValue(
                            `event_roles_attributes.${roleFieldindex}.after_check_out_margin`,
                            value ? +value : null
                          );
                        }}
                        inputClass="mb-1"
                        labelClassName="font-medium mb-2 text-primary field-label"
                        inputWrapperClass="create-event-input"
                        isInputHasErr={
                          !!(
                            formik.touched?.event_roles_attributes?.[
                              roleFieldindex
                            ]?.after_check_out_margin &&
                            formik.errors?.event_roles_attributes?.[
                              roleFieldindex
                            ]?.after_check_out_margin
                          )
                        }
                        errMsg={
                          formik.errors?.event_roles_attributes?.[
                            roleFieldindex
                          ]?.after_check_out_margin
                        }
                        disabled={
                          id &&
                          [
                            eventStatuses.cancelled,
                            eventStatuses.past,
                          ].includes(event?.status)
                        }
                        required={
                          (activePublishMode ||
                            isEventUpcoming ||
                            isEventOngoing) &&
                          isEventDaily
                        }
                      />
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-5 d-flex align-items-end justify-content-center">
                      <div className="show-time-margin mb-2 w-100">
                        <div>
                          <AccessTime
                            className={`clock-icon ${
                              lang === "en" ? "me-1" : "ms-1"
                            }`}
                          />
                          <span className="time">
                            {calculateMarginTime(
                              role?.working_hours_intervals[0].ends_at,
                              role?.after_check_out_margin,
                              false
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <FormHelperText
                    error
                    className={`font-medium fsize-12 ${
                      lang === "en" ? "" : "text-end"
                    }`}>
                    {inputsValidations[checkoutEndMarginErr]}
                  </FormHelperText>
                </div>
                <div className="col-12">
                  <FormHelperText
                    error
                    className={`font-medium fsize-12 ${
                      lang === "en" ? "" : "text-end"
                    }`}>
                    {inputsValidations[checkoutMarginTotalErr]}
                  </FormHelperText>
                </div>
                <div className="col-12">
                  <FormHelperText
                    error
                    className={`font-medium fsize-12 ${
                      lang === "en" ? "" : "text-end"
                    }`}>
                    {inputsValidations[marginsOverlapErr]}
                  </FormHelperText>
                </div>
              </div>
            )}
            <Input
              id={`event_roles_attributes.${roleFieldindex}.payroll_amount`}
              name={`event_roles_attributes.${roleFieldindex}.payroll_amount`}
              label={creatEvent.labels.payrollAmount}
              type="text"
              placeholder={creatEvent.placeholders.payrollAmount}
              value={role?.payroll_amount}
              onChange={(value) => {
                formik.setFieldTouched(
                  `event_roles_attributes.${roleFieldindex}.payroll_amount`
                );
                formik.setFieldValue(
                  `event_roles_attributes.${roleFieldindex}.payroll_amount`,
                  value || null
                );
              }}
              inputClass="mb-1"
              labelClassName="font-medium mb-2 text-primary field-label"
              inputWrapperClass="create-event-input"
              isInputHasErr={
                !!(
                  formik.touched?.event_roles_attributes?.[roleFieldindex]
                    ?.payroll_amount &&
                  formik.errors?.event_roles_attributes?.[roleFieldindex]
                    ?.payroll_amount
                )
              }
              errMsg={
                formik.errors?.event_roles_attributes?.[roleFieldindex]
                  ?.payroll_amount
              }
              disabled={
                id &&
                [
                  eventStatuses.ongoing,
                  eventStatuses.cancelled,
                  eventStatuses.past,
                ].includes(event?.status)
              }
              required={activePublishMode || isEventUpcoming}
            />
            <Input
              id={`event_roles_attributes.${roleFieldindex}.uniform_code`}
              name={`event_roles_attributes.${roleFieldindex}.uniform_code`}
              label={
                <div>
                  <span>{creatEvent.labels.uniformCode}</span>
                  <span className="mx-1">{creatEvent.optional}</span>
                </div>
              }
              type="text"
              placeholder={creatEvent.placeholders.uniformCode}
              value={role?.uniform_code}
              onChange={(value) => {
                formik.setFieldTouched(
                  `event_roles_attributes.${roleFieldindex}.uniform_code`
                );
                formik.setFieldValue(
                  `event_roles_attributes.${roleFieldindex}.uniform_code`,
                  value || null
                );
              }}
              inputClass="mb-1"
              labelClassName="font-medium mb-2 text-primary field-label"
              inputWrapperClass="pt-2 create-event-input"
              isInputHasErr={
                !!(
                  formik.touched?.event_roles_attributes?.[roleFieldindex]
                    ?.uniform_code &&
                  formik.errors?.event_roles_attributes?.[roleFieldindex]
                    ?.uniform_code
                )
              }
              errMsg={
                formik.errors?.event_roles_attributes?.[roleFieldindex]
                  ?.uniform_code
              }
              disabled={
                id &&
                [
                  eventStatuses.ongoing,
                  eventStatuses.cancelled,
                  eventStatuses.past,
                ].includes(event?.status)
              }
            />
            <Input
              id={`event_roles_attributes.${roleFieldindex}.required_training`}
              name={`event_roles_attributes.${roleFieldindex}.required_training`}
              label={
                <div>
                  <span>{creatEvent.labels.requiredTraining}</span>
                  <span className="mx-1">{creatEvent.optional}</span>
                </div>
              }
              type="text"
              placeholder={creatEvent.placeholders.requiredTraining}
              value={role?.required_training}
              onChange={(value) => {
                formik.setFieldTouched(
                  `event_roles_attributes.${roleFieldindex}.required_training`
                );
                formik.setFieldValue(
                  `event_roles_attributes.${roleFieldindex}.required_training`,
                  value || null
                );
              }}
              inputClass="mb-1"
              labelClassName="font-medium mb-2 text-primary field-label"
              inputWrapperClass="create-event-input"
              disabled={
                id &&
                [
                  eventStatuses.ongoing,
                  eventStatuses.cancelled,
                  eventStatuses.past,
                ].includes(event?.status)
              }
            />
          </>
        }
      />
    </div>
  );
};

export default RoleFields;

RoleFields.propTypes = {
  role: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    role_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    maximum_number_of_males: PropTypes.string,
    maximum_number_of_females: PropTypes.string,
    number_of_shifts: PropTypes.string,
    payroll_amount: PropTypes.string,
    before_check_in_margin: PropTypes.string,
    after_check_in_margin: PropTypes.string,
    before_check_out_margin: PropTypes.string,
    after_check_out_margin: PropTypes.string,
    working_hours_intervals: PropTypes.arrayOf(
      PropTypes.shape({
        starts_at: PropTypes.string,
        ends_at: PropTypes.string,
      })
    ),
    uniform_code: PropTypes.string,
    arriving_at: PropTypes.string,
    required_training: PropTypes.string,
  }),
  removeClickFunction: PropTypes.func,
  formik: PropTypes.any,
  roleFieldindex: PropTypes.number,
  rolesCount: PropTypes.number,
  isEventUpcoming: PropTypes.bool,
  isEventOngoing: PropTypes.bool,
  isEventDaily: PropTypes.bool,
  setAreMarginsValid: PropTypes.func,
};
