export const INITIAL_FETCH_REQUEST = "INITIAL_FETCH_REQUEST";

export const SET_PAGE_REQUEST = "SET_PAGE_REQUEST";

export const SET_FILTERS_VALUE_REQUEST = "SET_FILTERS_VALUE_REQUEST";

export const SET_SEARCH_VALUE_REQUEST = "SET_SEARCH_VALUE_REQUEST";

export const SET_SUPERVISOR_VALUE_REQUEST = "SET_SUPERVISOR_VALUE_REQUEST";

export const SET_FLAG_CHECK_IN_VALUE_REQUEST =
  "SET_FLAG_CHECK_IN_VALUE_REQUEST";

export const SET_TABS_VALUE_REQUEST = "SET_TABS_VALUE_REQUEST";

export const SET_RESETTING_FILTERS = "SET_RESETTING_FILTERS";
