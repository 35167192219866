const applicants = {
  applicantsList: "قائمة المتقدمين",
  listApplicants: {
    listApplicantsBreadcrumb: "قائمة المتقدمين",
    getKnowTeam: "تعرف على فريقنا!",
    name: "الاسم",
    email: "البريد الالكتروني",
    fullName: "الاسم بالكامل",
    profileStatus: "حاله الملف",
    verified: "تم التحقق",
    unverified: "لم يتم التحقق",
    profile_completed: "جميع معلومات الملف كاملة",
    basic_profile_completed: "معلومات الاساسية للملف  كاملة",
    applicantStatus: "حاله المتقدم",
    unblocked: "غير محظور",
    block: "محظور",
    assigned: "مُكلَف",
    notAssigned: "غير مُكلَف",
    nationality: "الجنسيه",
    studyField: "مجالات الدراسه",
    profileRate: "التققيم",
    role: "الوظيفه",
    note: "ملاحظة",
    gender: "الجنس",
    notProvided: "غير مُزود",
    male: "ذكر",
    female: "أنثى",
    prefer_not_to_say: "غير مُزود",
    language: "اللغه",
    speaking: "إجادة التحدث",
    age: "العمر",
    zone: "المنطقة",
    ageRange1: "16 - 25",
    ageRange2: "25 - 30",
    ageRange3: "30 - 35",
    ageRange4: "35 - 40",
    height: "الطول",
    heightRange1: "140 - 160 سم",
    heightRange2: "160 - 180 سم",
    heightRange3: "180 - 200 سم",
    heightRange4: "200 -300 سم",
    weight: "الوزن",
    weightRange1: "40 - 50 كجم",
    weightRange2: "50 - 70 كجم",
    weightRange3: "70 - 100 كجم",
    weightRange4: "100 - 120 كجم",
    weightRange5: "120 - 170 كجم",
    disability: "اعاقه",
    diseases: "امراض",
    searchPlaceHolder: "ابحث عن طريق اسم المتقدم، ورقم هوية المتقدم",
    noDataDescription: "عذرا لا يوجد متقدمين",
    noDisease: "لا توجد أمراض",
    noDisability: "لا توجد إعاقة",
    cityOfResidence: "المدينة",
  },
  applicantDetails: {
    applicantDetailsBreadcrumb: "تفاصيل المتقدم",
    applicantDetails: "تفاصيل المتقدم",
    totalHours: "اجمالي ساعات العمل",
    requests: "طلبات",
    events: "الفعاليات",
    rated: "التقييم",
    blockBtn: "حظر المتقدم",
    blockModalConfirmMessage: "هل أنت متأكد أنك تريد حظر هذا المتقدم؟",
    unBlockModalConfirmMessage: "هل أنت متأكد أنك تريد الغاء حظر هذا المتقدم؟",
    pendingRequests: "عرض الطلبات قيد الانتظار",
    showEvents: "عرض الفاعليات",
    downloadProfiles: "تنزيل الملفات الشخصية",
    downloadProfile: "تنزيل الملف الشخصي",
    applicantProfile: {
      labels: {
        basicInfo: {
          title: "معلومات أساسية",
          enName: "الاسم (الانجليزية)",
          arName: "الاسم (العربية)",
          firstName: "الاسم الأول",
          middleName: "اسم الأب",
          lastName: "الاسم الأخير",
          countryCode: "رمز الدولة",
          iqmaExpireDate: "تاريخ انتهاء الإقامة",
          stcNumber: "رقم STC",
          phoneNumber: "رقم الهاتف",
          nationalId: "رقم الهوية",
          iqamaNumber: "رقم الإقامة",
          expiryDate: "تاريخ الانتهاء",
          whatsAppNumber: " رقم واتساب",
          dateOfBirthHijri: "تاريخ الميلاد (هجري)",
          dateOfBirthGregorian: " تاريخ الميلاد (الميلادي)",
          matlooobNumber: "رقم مطلوب",
          nationality: "الجنسيه",
          role: "الوظيفه",
          gender: "الجنس",
          age: "العمر",
          height: "الطول",
          weight: "الوزن",
          disability: "اعاقه",
          diseases: "امراض",
          chronicDisease: "مرض مزمن",
          cityOfResidence: "مدينة الإقامة",
        },
        educationInfo: {
          title: "التعليم",
          education: "مستوى التعليم",
          filedsOfStudy: "مجال الدراسة",
          fieldOfStudyName: "اسم مجال الدراسة",
        },
        languageInfo: {
          title: "مهارات لغوية",
          language: "اللغة",
          proficiency: "إجادة",
        },
        documentInfo: {
          title: "المستندات",
          certificates: "الشهادات",
          academicQualifications: "المؤهلات العلمية",
          cv: "السيرة الذاتية",
          other: "مستندات أخرى",
        },
        bankInfo: {
          title: "معلومات البنك",
          bankName: "اسم البنك",
          IBAN: "رقم الحساب المصرفي الدولي",
          cardHolder: "اسم حامل البطاقة",
        },
      },
    },
  },
  applicantRequsets: {
    eventName: "اسم الفاعلية",
    role: "الوظيفة",
    requestDate: "تاريخ الطلب",
    actions: "أفعال",
    accepted: "مقبول",
  },
  applicantEvents: {
    eventName: "اسم الفعالية",
    eventStatus: "حالة الفعالية",
    role: "الوظيفة",
    totalWorkingHours: "اجمالي ساعات العمل",
    supervisorComments: "تعليقات المشرف",
    rating: "التقييم",
    notYetProvided: "غير مزود حاليا",
    evaluationRounds: "جولات التقييم",
    round: "جولة ",
    evaluationForm: "استمارة التقييم",
  },
  search: "أبحث باستخدام اسم الفاعلية",
  applicantExportDetails: {
    applicantProfile: "ملفات تعريف المتقدمين",
    basicInfo: "معلومات أساسية",
    gender: "الجنس",
    nationality: "الجنسية",
    city: "المدينة",
    iqamaNumber: "رقم الإقامة",
    whatsAppNumber: "رقم واتساب",
    id: "الهوية",
    education: "التعليم",
    educationLevel: "المستوى التعليمي",
    filedsOfStudy: "مجالات الدراسة",
    language: "اللغة",
    bankInfo: "معلومات البنك",
    bankName: "اسم البنك",
    bankIban: "رقم الآيبان",
    bankCardHolder: "اسم حامل البطاقة",
    profile: "حساب تعريفي",
  },
};

export default applicants;
