import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { TableCell, TableRow } from "@mui/material";

import Breadcrumbs from "../../components/BreadCrumbs";
import Table from "../../components/Table";
import Pagination from "../../components/Pagination";
import EmptyState from "../../components/EmptyState";
import Filters from "../../components/Filter";
import messages from "../../assets/locale/messages";
import eventPlaceholder from "../../assets/images/event-screen/events-placeholder.svg";
import {
  textContainOnlySpaces,
  formatDate,
  renderTextEllipsis,
} from "../../utils/Helpers";
import { eventStatuses, eventTypes } from "../../utils/Constants";
import { ROUTE_PATHS } from "../../utils/RoutesPaths";
import {
  initialTimeSheetFetch,
  setTimeSheetFilters,
  setTimeSheetPage,
  setTimeSheetSearch,
} from "../../store/PersistTimeSheet/actions";

const TimeSheet = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.locale.lang);
  const { timeSheet, events } = messages[lang];
  const isLoading = useSelector((state) => state.loader);
  const meta = useSelector((state) => state.events.meta);
  const list = useSelector((state) => state.events.list);
  const persistState = useSelector((state) => state.persistTimeSheet);

  const [q, setQuery] = useState("");

  const eventTypeOptions = [
    { id: 1, name: events.listEvents.daily, value: eventTypes.daily },
    { id: 2, name: events.listEvents.monthly, value: eventTypes.monthly },
  ];
  const eventOptions = [
    {
      id: 1,
      value: eventStatuses.ongoing,
      name: events.listEvents.eventTabs.ongoing,
    },
    {
      id: 2,
      value: eventStatuses.past,
      name: events.listEvents.eventTabs.previous,
    },
  ];

  const handleFilterChange = (newValue) => {
    dispatch(setTimeSheetFilters({ ...persistState, ...newValue }));
  };
  const handlePageChange = (newPage) => {
    dispatch(setTimeSheetPage(newPage));
  };

  useEffect(() => {
    if ((q.length >= 3 || q.length === 0) && !textContainOnlySpaces(q)) {
      dispatch(setTimeSheetSearch(q));
    }
  }, [q]);

  useEffect(() => {
    dispatch(initialTimeSheetFetch());
  }, [lang, persistState]);

  const renderEventsTimesheetList = () => {
    if (!isLoading && !list?.length) {
      return (
        <>
          <div className="bg-white rounded-4 d-flex justify-content-center align-items-center flex-column my-4">
            <EmptyState
              description={events.listEvents.noDataDescription}
              imgSrc={eventPlaceholder}
              showActionButton={false}
            />
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="bg-white rounded-4 mt-2 pb-3">
            <Table
              className="rounded-4"
              headlines={[
                timeSheet.eventName,
                timeSheet.type,
                timeSheet.status,
                timeSheet.startDate,
                timeSheet.endDate,
                timeSheet.link,
              ]}
              hasActions={false}
              hasSizeLimitation={false}
              rows={list?.map((row, i) => (
                <TableRow
                  key={i}
                  className="row-hover"
                  onClick={() => {
                    row.payment_period &&
                      history.push(
                        ROUTE_PATHS[
                          row.payment_period == eventTypes.daily
                            ? "dailyReport"
                            : "monthlyReport"
                        ].replace(":eventid", row.id),
                        { event_name: row.name }
                      );
                  }}
                >
                  <TableCell className="pointer">
                    {renderTextEllipsis(row.name)}
                  </TableCell>
                  <TableCell className="w-15">
                    <span
                      className={`type-container fsize-14 ${row.payment_period}`}
                    >
                      {row.payment_period
                        ? messages[lang].events.listEvents[row.payment_period]
                        : "-"}
                    </span>
                  </TableCell>
                  <TableCell>
                    {events.listEvents.eventStatus[row.status]}
                  </TableCell>
                  {/* <TableCell className="mobile-style">
                    {row.building_start_at && row.dismantling_end_at
                      ? formatDate(row.building_start_at)
                      : formatDate(row.event_start_at)}
                  </TableCell>
                  <TableCell className="mobile-style">
                    {row.building_start_at && row.dismantling_end_at
                      ? formatDate(row.dismantling_end_at)
                      : formatDate(row.event_end_at)}
                  </TableCell> */}
                  <TableCell className="mobile-style">
                    {formatDate(row.event_start_at)}
                  </TableCell>
                  <TableCell className="mobile-style">
                    {formatDate(row.event_end_at)}
                  </TableCell>
                  <TableCell className="mobile-style">
                    {row.payment_period ? (
                      <a
                        className="text-decoration-underline text-main pointer"
                        onClick={() =>
                          history.push(
                            ROUTE_PATHS[
                              row.payment_period == eventTypes.daily
                                ? "dailyReport"
                                : "monthlyReport"
                            ].replace(":eventid", row.id),
                            { event_name: row.name }
                          )
                        }
                      >
                        {timeSheet.attendanceReport}
                      </a>
                    ) : (
                      <span>-</span>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            />
          </div>
          <div className="d-flex justify-content-center py-3 mt-4">
            {meta.pages > 1 && (
              <Pagination
                count={meta.pages}
                page={meta.currentPage || persistState.page}
                handleChange={(event, page) => handlePageChange(page)}
                defaultPage={1}
              />
            )}
          </div>
        </>
      );
    }
  };

  return (
    <div className="events-list-container">
      <div className="page-title-margin">
        <Breadcrumbs
          list={[
            {
              path: "",
              label: timeSheet.listTimeSheetBreadcrumb,
            },
          ]}
          BreadCrumbHasBackIcon={false}
        />
      </div>
      <Filters
        searchInput={{
          placeholder: timeSheet.search,
          name: "search",
          value: q,
          onChange: (value) => setQuery(value),
        }}
        filters={[
          {
            type: "filter",
            name: "eventType",
            placeholder: timeSheet.type,
            value: persistState.event_type,
            options: eventTypeOptions,
            onChange: (value) => handleFilterChange({ event_type: value }),
          },
          {
            type: "filter",
            name: "eventStatus",
            placeholder: timeSheet.status,
            value: persistState.event_status,
            options: eventOptions,
            onChange: (value) => handleFilterChange({ event_status: value }),
          },
          {
            type: "date",
            name: "startDate",
            placeholder: timeSheet.startDate,
            label: timeSheet.startDate,
            value: persistState.event_start_date,
            onChange: (value) =>
              handleFilterChange({ event_start_date: value }),
          },
          {
            type: "date",
            name: "endDate",
            placeholder: timeSheet.endDate,
            label: timeSheet.endDate,
            value: persistState.event_end_date,
            onChange: (value) => handleFilterChange({ event_end_date: value }),
            helperText:
              persistState.event_end_date !== "" &&
              persistState.event_end_date < persistState.event_start_date
                ? timeSheet.dailyReport.endDateValidation
                : "",
            helperTextClass: "err",
          },
        ]}
      />

      <div className="d-flex flex-column justify-content-between">
        {renderEventsTimesheetList()}
      </div>
    </div>
  );
};

export default TimeSheet;
