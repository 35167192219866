import { put, call, takeEvery } from "redux-saga/effects";
import { showErrorMsg, showSuccessMsg } from "../../utils/Helpers";

import * as apis from "../../network/apis/report";
import * as types from "./types";
import * as actions from "./actions";

function* getReportsListSaga({ payload }) {
  try {
    yield put(
      actions.getReportsResponse({
        list: [],
        meta: {},
      })
    );
    const response = yield call(apis.getReportList, payload);
    yield put(
      actions.getReportsResponse({
        list: response.data.data,
        meta: {
          currentPage: +response.headers["current-page"],
          count: +response.headers["total-count"],
          pages: +response.headers["total-pages"],
        },
      })
    );
    if (+response.headers["current-page"] > +response.headers["total-pages"]) {
      yield put(
        actions.getReportsRequest({
          ...payload,
          page: +response.headers["total-pages"],
        })
      );
    }
  } catch (error) {
    yield error.response && showErrorMsg(error.response?.data?.message);
  }
}

function* getReportDetailSaga({ payload }) {
  try {
    const response = yield call(apis.getReportDetail, payload);
    yield put(actions.getReportDetailResponse(response.data.data));
  } catch (error) {
    yield error.response && showErrorMsg(error.response?.data?.message);
  }
}

function* exportReportSheetListSaga({ payload }) {
  try {
    const response = yield call(apis.exportReportSheet, payload);
    const blob = new Blob([response.data], {
      type: response.headers["content-type"],
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "reports_issue.xlsx";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
    yield put(showSuccessMsg("File downloaded successfully!", true));
  } catch (error) {
    yield error.response && showErrorMsg(error.response?.data?.message);
  }
}

export default function* ReportSagas() {
  yield takeEvery(types.GET_REPORTS_REQUEST, getReportsListSaga);
  yield takeEvery(types.GET_REPORT_DETAIL_REQUEST, getReportDetailSaga);
  yield takeEvery(types.EXPORT_REPORT_SHEET_REQUEST, exportReportSheetListSaga);
}
