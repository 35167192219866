import * as types from "./types";

export const initialFetchEmployee = () => ({
  type: types.INITIAL_FETCH_EMPLOYEE,
});

export const setEmployeePage = (payload) => ({
  type: types.SET_EMPLOYEE_PAGE,
  payload,
});

export const setEmployeeSearch = (payload) => ({
  type: types.SET_EMPLOYEE_SEARCH,
  payload,
});
