import * as types from "./types";

export const initialTimeSheetEventFetch = (payload) => ({
  type: types.INITIAL_TIMESHEET_EVENT_FETCH,
  payload,
});

export const setTimeSheetEventFilters = (payload) => ({
  type: types.SET_TIMESHEET_EVENT_FILTERS,
  payload,
});

export const setTimeSheetEventPage = (payload) => ({
  type: types.SET_TIMESHEET_EVENT_PAGE,
  payload,
});

export const setTimeSheetEventSearch = (payload) => ({
  type: types.SET_TIMESHEET_EVENT_SEARCH,
  payload,
});
