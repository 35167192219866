import { axiosInstance } from "./";

const getReportList = async ({ id, params }) => {
  return await axiosInstance.get(`/events/${id}/logistics_issues`, { params });
};
const getReportDetail = async ({ event_id, id }) =>
  await axiosInstance.get(`/events/${event_id}/logistics_issues/${id}`);

const exportReportSheet = async ({ id, params }) =>
  await axiosInstance.get(`/events/${id}/logistics_issues/export_to_xls`, {
    params,
    responseType: "blob",
  });

export { getReportList, getReportDetail, exportReportSheet };
