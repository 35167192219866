import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { Clear } from "@mui/icons-material";

import messages from "../../../../assets/locale/messages";
import RichText from "../../../../components/RichText";
import Select from "../../../../components/Select";
import Accordion from "../../../../components/Accordion";
import { eventStatuses } from "../../../../utils/Constants";
import { isSuperAdmin } from "../../../../utils/Permissions";

const ProjectManagerFields = ({
  projectManager,
  removeClickFunction,
  formik,
  index,
  projectManagersCount,
  isEventUpcoming,
}) => {
  const { id } = useParams();

  const lang = useSelector((state) => state.locale.lang);
  const pmsOptions = useSelector((state) => state.lookups.projectManagers);
  const event = useSelector((state) => state.events.event);
  const activePublishMode = useSelector(
    (state) => state.routing.activePublishMode
  );
  const { creatEvent } = messages[lang].events;

  const [managerName, setManagerName] = useState(creatEvent.projectManager);

  useEffect(() => {
    if (projectManager?.admin_id) {
      const selectedItemLabel = pmsOptions.find(
        (item) => item.id === projectManager?.admin_id
      )?.name;
      setManagerName(selectedItemLabel);
    } else {
      setManagerName(creatEvent.projectManager);
    }
  }, [projectManager?.admin_id, lang]);

  return (
    <div>
      <Accordion
        accordionNumber={index}
        title={managerName}
        isExpandedInitially={activePublishMode}
        extraFunctionBtn={
          (index === 0 && projectManagersCount === 1) ||
          (id &&
            [
              eventStatuses.cancelled,
              eventStatuses.past,
            ].includes(event?.status)) ||
          (id && !isSuperAdmin()) ? (
            <></>
          ) : (
            <span
              className="cursor-pointer"
              onClick={() => removeClickFunction(true)}>
              <Clear />
            </span>
          )
        }
        content={
          <>
            <Select
              id={`projectManagers.${index}.admin_id`}
              name={`projectManagers.${index}.admin_id`}
              options={pmsOptions}
              label={creatEvent.labels.pmName}
              variant
              block
              labelClassName="text-gray-title  pb-2"
              placeholder={creatEvent.placeholders.pmName}
              value={
                pmsOptions.find(
                  (option) => option.id == projectManager?.admin_id
                ) || null
              }
              onChange={(value) => {
                formik.setFieldTouched(`projectManagers.${index}.admin_id`);
                formik.setFieldValue(
                  `projectManagers.${index}.admin_id`,
                  value ? value.id : ""
                );
                if (!value && projectManager.id) {
                  removeClickFunction(false);
                }
              }}
              hasError={
                !!(
                  formik.touched?.projectManagers?.[index]?.admin_id &&
                  formik.errors?.projectManagers?.[index]?.admin_id
                )
              }
              errMsg={formik.errors?.projectManagers?.[index]?.admin_id}
              required={activePublishMode || isEventUpcoming}
              disabled={
                (id &&
                  [
                    eventStatuses.cancelled,
                    eventStatuses.past,
                  ].includes(event?.status)) ||
                (id && !isSuperAdmin())
              }
            />

            <RichText
              id={`projectManagers.${index}.responsibility`}
              name={`projectManagers.${index}.responsibility`}
              label={creatEvent.labels.responsibility}
              value={projectManager?.responsibility || ""}
              onChange={(value) => {
                formik.setFieldTouched(
                  `projectManagers.${index}.responsibility`
                );

                if (value?.replace(/(<([^>]+)>)/gi, "").length > 0) {
                  formik.setFieldValue(
                    `projectManagers.${index}.responsibility`,
                    value
                  );
                } else {
                  formik.setFieldValue(
                    `projectManagers.${index}.responsibility`,
                    null
                  );
                }
              }}
              maxLength={1000}
              readOnly={
                (id &&
                  [
                    eventStatuses.cancelled,
                    eventStatuses.past,
                  ].includes(event?.status)) ||
                (id && !isSuperAdmin())
              }
              noData={
                id &&
                event?.status === eventStatuses.ongoing &&
                !projectManager.responsibility
              }
              // required={activePublishMode}
            />
          </>
        }
      />
    </div>
  );
};

export default ProjectManagerFields;

ProjectManagerFields.propTypes = {
  projectManager: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    admin_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    responsibility: PropTypes.string,
  }),
  removeClickFunction: PropTypes.func,
  formik: PropTypes.any,
  index: PropTypes.number,
  projectManagersCount: PropTypes.number,
  isEventUpcoming: PropTypes.bool,
};
