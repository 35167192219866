import { combineReducers } from "redux";
import locale from "./Lang/reducer";
import snackbar from "./Snackbar/reducer";
import loader from "./Loader/reducer";
import navigation from "./Navigation/reducer";
import lookups from "./Lookups/reducer";
import auth from "./Auth/reducer";
import events from "./Events/reducer";
import routing from "./Routing/reducers";
import requests from "./Requests/reducer";
import employees from "./Employees/reducer";
import applicants from "./applicants/reducer";
import promotionRequests from "./PromotionRequests/reducer";
import notifications from "./Notifications/reducer";
import timesheet from "./TimeSheet/reducer";
import applicantsTimeSheetRequest from "./ApplicantsTimeSheet/reducer";
import evaluations from "./Evaluations/reducer";
import locations from "./Locations/reducer";
import note from "./Notes/reducer";
import download from "./Download/reducer";
import _filters from "./Filter/reducer";
import joiningRequest from "./JoiningRequest/reducer";
import report from "./Reports/reducer";
import applicantTabs from "./ApplicantsTab/reducer";
import persistEmployee from "./PersistEmployees/reducer";
import persistTimeSheet from "./PersistTimeSheet/reducer";
import persistTimeSheetEvent from "./PersistTimeSheetEvent/reducer";

export default combineReducers({
  locale,
  snackbar,
  loader,
  navigation,
  auth,
  lookups,
  events,
  routing,
  requests,
  employees,
  applicants,
  promotionRequests,
  notifications,
  timesheet,
  applicantsTimeSheetRequest,
  evaluations,
  locations,
  note,
  download,
  _filters,
  joiningRequest,
  report,
  applicantTabs,
  persistEmployee,
  persistTimeSheet,
  persistTimeSheetEvent,
});
