import { put, takeLatest, select, call } from "redux-saga/effects";
import * as types from "./types";
import * as actions from "./actions";
import { showErrorMsg } from "../../utils/Helpers";
import { getEmployeesRequest } from "../Employees/actions";

function* fetchEmployeeListSaga() {
  const { page, rowCount, searchValue } = yield select(
    (state) => state.persistEmployee
  );
  yield put(
    getEmployeesRequest({
      page,
      items: rowCount,
      name: searchValue,
      order_by_name: "asc",
    })
  );
}

function* fetchInitialStateSaga() {
  try {
    yield call(fetchEmployeeListSaga);
  } catch (error) {
    yield showErrorMsg(error);
  }
}

function* pageEmployeeSaga(action) {
  try {
    const { page } = yield select((state) => state.persistEmployee);
    if (action.payload !== page) {
      yield put(actions.setEmployeePage(action.payload));
      yield call(fetchEmployeeListSaga);
    }
  } catch (error) {
    yield showErrorMsg(error);
  }
}

export default function* persistEmployeeSaga() {
  yield takeLatest(types.INITIAL_FETCH_EMPLOYEE, fetchInitialStateSaga);
  yield takeLatest(types.SET_EMPLOYEE_PAGE, pageEmployeeSaga);
}
