import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import messages from "../../../assets/locale/messages";

const SelectedExportColumnsModal = ({
  selectedFields,
  handleChange,
  handleSubmit,
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const { report } = messages[lang];
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="d-flex flex-column justify-content-start  gap-4">
          <label className="d-inline-flex align-items-center gap-2 cursor-pointer">
            <input
              type="checkbox"
              name="title"
              checked={selectedFields.title}
              onChange={handleChange}
              className="cursor-pointer"
            />
            {report.exportSheetColumns.reportList}
          </label>

          <label className="d-inline-flex align-items-center gap-2 cursor-pointer">
            <input
              type="checkbox"
              name="description"
              checked={selectedFields.description}
              onChange={handleChange}
              className="cursor-pointer"
            />
            {report.exportSheetColumns.description}
          </label>

          <label className="d-inline-flex align-items-center gap-2 cursor-pointer">
            <input
              type="checkbox"
              name="date"
              checked={selectedFields.date}
              onChange={handleChange}
              className="cursor-pointer"
            />
            {report.listReport.date}
          </label>

          <label className="d-inline-flex align-items-center gap-2 cursor-pointer">
            <input
              type="checkbox"
              name="time"
              checked={selectedFields.time}
              onChange={handleChange}
              className="cursor-pointer"
            />
            {report.listReport.time}
          </label>

          <label className="d-inline-flex align-items-center gap-2 cursor-pointer">
            <input
              type="checkbox"
              name="status"
              checked={selectedFields.status}
              onChange={handleChange}
              className="cursor-pointer"
            />
            {report.listReport.status}
          </label>
          <label className="d-inline-flex align-items-center gap-2 cursor-pointer">
            <input
              type="checkbox"
              name="zone_name"
              checked={selectedFields.zone_name}
              onChange={handleChange}
              className="cursor-pointer"
            />
            {report.exportSheetColumns.zone}
          </label>
          <label className="d-inline-flex align-items-center gap-2 cursor-pointer">
            <input
              type="checkbox"
              name="event_name"
              checked={selectedFields.event_name}
              onChange={handleChange}
              className="cursor-pointer"
            />
            {report.exportSheetColumns.eventName}
          </label>
          <label className="d-inline-flex align-items-center gap-2 cursor-pointer">
            <input
              type="checkbox"
              name="supervisor_name"
              checked={selectedFields.supervisor_name}
              onChange={handleChange}
              className="cursor-pointer"
            />
            {report.exportSheetColumns.supervisor}
          </label>
          <label className="d-inline-flex align-items-center gap-2 cursor-pointer">
            <input
              type="checkbox"
              name="pictures_url"
              checked={selectedFields.pictures_url}
              onChange={handleChange}
              className="cursor-pointer"
            />
            {report.listReport.images}
          </label>
          <label className="d-inline-flex align-items-center gap-2 cursor-pointer">
            <input
              type="checkbox"
              name="comments"
              checked={selectedFields.comments}
              onChange={handleChange}
              className="cursor-pointer"
            />
            {report.updates}
          </label>
        </div>
      </form>
    </div>
  );
};

export default SelectedExportColumnsModal;

SelectedExportColumnsModal.propTypes = {
  handleSubmit: PropTypes.func,
  selectedFields: PropTypes.any,
  handleChange: PropTypes.func,
};
