const report = {
  logistics: "Reports",
  listIssuesBreadcrumb: "Issues List",
  listReportBreadcrumb: "Report Details",
  listReport: {
    noDataFound: "Sorry, No issues found",
    open: "Open",
    closed: "Closed",
    reportList: "Report List",
    zones: "Zones",
    supervisor: "Supervisor",
    createdDate: "Created Date",
    createdTime: "Created Time",
    closedTime: "Closed Time",
    closedDate: "Closed Date",
    date: "Date",
    time: "Time",
    status: "Status",
    zoneNo: "Zone No.",
    issueDesc: "Issue Description",
    images: "Images",
  },
  modalTitle: "Select Fields For Export CSV",
  exportSheetColumns: {
    reportList: "Report List",
    description: "Description ",
    zone: "Zones",
    eventName: "Event Name",
    supervisor: "Supervisor",
    exportReportToExcel: "Export Reports to Excel Sheet",
  },
  noPreview: " No preview available!",
  updates: "Comments",
};
export default report;
