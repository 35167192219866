import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { Alert, Chip, TableCell, TableRow, Checkbox } from "@mui/material";
import { BorderColorOutlined } from "@mui/icons-material";
import PropTypes from "prop-types";

import Table from "../Table";
import Button from "../Button";
import Breadcrumbs from "../BreadCrumbs";
import Pagination from "../Pagination";
import EmptyState from "../EmptyState";
import Modal from "../Modal";
import Rate from "../Rate";
import RadioButtonsGroup from "../RadioButton";
import Avatar from "../Avatar";
import { renderTextEllipsis, textContainOnlySpaces } from "../../utils/Helpers";
import {
  approveRequest,
  bulkApprovedRequestsRequest,
} from "../../store/Requests/actions";
import {
  getCitiesRequest,
  getLookupsRequest,
  getCountriesRequest,
  getSpeakingProficiencyRequest,
} from "../../store/Lookups/actions";
import { inviteApplicantsRequest } from "../../store/Events/actions";
import { genders, lookupsKeys, requestStatuses } from "../../utils/Constants";
import messages from "../../assets/locale/messages";
import requestPlaceholder from "../../assets/images/request-screen/request-placeholder.svg";
import applicantPlaceholder from "../../assets/images/applicant-screen/applicants-placeholder.svg";
import { ROUTE_PATHS } from "../../utils/RoutesPaths";
import "./RequestsTable.scss";
import {
  deleteLocationRequest,
  getLocationsRequest,
} from "../../store/Locations/actions";
import {
  createNoteRequest,
  deleteNoteRequest,
  getNoteRequest,
  updateNoteRequest,
} from "../../store/Notes/action";
import Note from "../Note";
import {
  initialFetchRequest,
  setFiltersRequest,
  setFilterStatusRequest,
  setPageRequest,
  setRowsCountRequest,
  setSearchValueRequest,
} from "../../store/JoiningRequest/actions";
import Select from "../Select";
import Filters from "../Filter";

const rowsCountOptions = [
  { id: 10, name: "10" },
  { id: 20, name: "20" },
  { id: 30, name: "30" },
  { id: 50, name: "50" },
];

const RequestsTable = ({
  isApplicant,
  isInviteApplicants,
  headlines,
  breadcrumbsList,
  userId,
  searchPlaceholder,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  const lang = useSelector((state) => state.locale.lang);
  const { requests, events, shared, applicants } = messages[lang];

  const meta = useSelector((state) =>
    isInviteApplicants
      ? state.applicants.eligibleApplicants.meta
      : state.requests.requestsList.meta
  );
  const list = useSelector((state) =>
    isInviteApplicants
      ? state.applicants.eligibleApplicants.list
      : state.requests.requestsList.list
  );
  const { roles, languages } = useSelector((state) => state.lookups.general);
  const { cities, countries, speakingProficiency } = useSelector(
    (state) => state.lookups
  );
  const location = useSelector((state) => state.locations.list);
  const note = useSelector((state) => state.note.list);
  const isLoading = useSelector((state) => state.loader);
  const [q, setQuery] = useState("");
  const [openWarningApproveModal, setOpenWarningApproveModal] = useState(false);
  const [singleRole, setSingleRole] = useState(null);
  const [requestId, setRequestId] = useState(null);
  const [selectedApplicants, setSelectedApplicants] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [openRoleModal, setOpenRoleModal] = useState(false);
  const [userName, setUserName] = useState(null);
  const [activeUserId, setActiveUserId] = useState(null);
  const [rolesOptions, setRolesOptions] = useState([]);
  const [originalRole, setOriginalRole] = useState(null);
  const [roleIndex, setRoleIndex] = useState(null);
  const [isRoleSelected, setIsRoleSelected] = useState(false);
  const [autoSelect, setAutoSelect] = useState(false);
  const [isNoteModalOpen, setIsNoteModalOpen] = useState(false);
  const [selectValue, setSelectValue] = useState(null);
  const [otherValue, setOtherValue] = useState("");
  const [validate, setValidate] = useState(false);
  const [selectedApplicantId, setSelectedApplicantId] = useState(null);
  const [approvedModalClose, setApprovedModalClose] = useState(false);
  const [approvedCheckedId, setApprovedCheckedId] = useState([]);
  const [selectedCheckedIds, setSelectedCheckedIds] = useState([]);
  const isSelectedIds = (id) => selectedCheckedIds.includes(id);

  /**********************************
  // 1) GET REDUX PERSIST ALL STATE
  *********************************/
  const filters = useSelector((state) => state.joiningRequest);

  const handleSelectAllClick = (event, _rows) => {
    setSelectedCheckedIds(event.target.checked ? _rows : []);
  };
  const handleCheckboxClick = (event, id) => {
    const newSelected = selectedCheckedIds.includes(id)
      ? selectedCheckedIds.filter((item) => item !== id)
      : [...selectedCheckedIds, id];
    setSelectedCheckedIds(newSelected);
  };

  const handleApprovedCloseModal = () => {
    setApprovedModalClose(false);
    setSelectedCheckedIds([]);
  };
  const handleApprovedOpenModal = () => setApprovedModalClose(true);

  useEffect(() => {
    setApprovedCheckedId(selectedCheckedIds.map((item) => list[item].id));
  }, [selectedCheckedIds]);

  useEffect(() => {
    const _location = location.find((item) => item.id === note.location_id);
    setSelectValue(
      note.custom_location
        ? "other"
        : _location?.name || shared.selectedLocation
    );
    setOtherValue(note.custom_location || "");
  }, [note]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!selectValue) return;
    const location_id = location.find((item) => item?.name === selectValue);
    handleModalClose();
    setSelectValue(shared.selectedLocation);
    setOtherValue("");
    dispatch(
      note?.custom_location
        ? updateNoteRequest({
            id: selectedApplicantId,
            data: {
              custom_location: otherValue,
              location_id: location_id?.id,
            },
          })
        : createNoteRequest({
            id: selectedApplicantId,
            data: {
              custom_location: otherValue,
              location_id: location_id?.id,
            },
          })
    );
  };
  const handleEditNote = (applicantId) => {
    dispatch(getNoteRequest({ id: applicantId }));
    setSelectedApplicantId(applicantId);
    setIsNoteModalOpen(true);
  };
  const handleDeleteLocation = (id) => {
    dispatch(deleteLocationRequest({ id }));
    history.push(ROUTE_PATHS["joining-festivities"]);
    setIsNoteModalOpen(false);
  };

  const handleAddNote = (applicantId) => {
    setSelectedApplicantId(applicantId);
    setIsNoteModalOpen(true);
    setSelectValue(shared.selectedLocation);
    setOtherValue("");
    dispatch(getLocationsRequest());
  };
  const handleModalClose = () => setIsNoteModalOpen(false);

  const handleDeleteNote = (applicantId) => {
    setSelectedApplicantId(applicantId);
    dispatch(deleteNoteRequest({ id: applicantId }));
    dispatch(initialFetchRequest({ id }));
  };

  const statusOptions = [
    { id: 1, name: requests.listRequests.all, value: null },
    {
      id: 2,
      name: requests.listRequests.approved,
      value: requestStatuses.accepted,
    },
    {
      id: 3,
      name: requests.listRequests.pending,
      value: requestStatuses.notAccepted,
    },
    {
      id: 4,
      name: requests.listRequests.cancelled,
      value: requestStatuses.cancelled,
    },
    {
      id: 5,
      name: requests.listRequests.auto_cancelled,
      value: requestStatuses.autoCancelled,
    },
  ];
  const genderOptions = [
    {
      id: 1,
      name: shared.male,
      value: genders.male,
    },
    {
      id: 2,
      name: shared.female,
      value: genders.female,
    },
    {
      id: 3,
      name: shared.notProvided,
      value: genders.notProvided,
    },
  ];
  const ratingOptions = [
    {
      id: 1,
      name: "1",
      value: 1,
    },
    {
      id: 2,
      name: "2",
      value: 2,
    },
    {
      id: 3,
      name: "3",
      value: 3,
    },
    {
      id: 4,
      name: "4",
      value: 4,
    },
    {
      id: 5,
      name: "5",
      value: 5,
    },
  ];
  useEffect(() => {}, [approvedCheckedId, rowsCountOptions]);

  useEffect(() => {
    const allRoles = list?.map((applicant) => {
      const previouslySelectedApplicant = selectedApplicants.find(
        (selected) => selected.user_id === applicant.id
      );
      return isInviteApplicants && previouslySelectedApplicant
        ? previouslySelectedApplicant.role
        : applicant.role;
    });
    setSelectedRoles(allRoles);
  }, [!!list?.length, list]);

  const approveRequestHandler = (
    selectedRole,
    requestId,
    acceptableRoles,
    gender
  ) => {
    if (
      (acceptableRoles[0]?.maximum_number_of_females <=
        acceptableRoles[0]?.number_of_approved_female_requests &&
        gender == genders.female) ||
      (acceptableRoles[0]?.maximum_number_of_males <=
        acceptableRoles[0]?.number_of_approved_male_requests &&
        gender == genders.male) ||
      (acceptableRoles[0]?.maximum_number_of_males <=
        acceptableRoles[0]?.number_of_approved_male_requests &&
        acceptableRoles[0]?.maximum_number_of_females <=
          acceptableRoles[0]?.number_of_approved_female_requests &&
        gender == genders.notProvided)
    ) {
      setOpenWarningApproveModal(true);
      setRequestId(requestId);
      setSingleRole(selectedRole);
    } else {
      onApproveRequest(selectedRole, requestId);
    }
  };
  const onApproveRequest = (selectedRole, requestId) => {
    let params = {
      role_id: selectedRole.id,
    };
    dispatch(
      approveRequest({
        data: params,
        id: requestId,
        listParams: isApplicant
          ? {
              id,
              params: {
                page: filters.page,
                items: 10,
                user_id: userId,
                event_name: filters.searchValue,
                status: "pending",
                role_id: filters.filterRole?.id,
                order_by_created_at: "desc",
                responseType: "requests",
              },
            }
          : {
              id,
              params: {
                page: filters.page,
                items: 10,
                event_and_user_name: filters.searchValue,
                status: filters.filterRequestStatus?.value ?? "pending",
                role_id: filters.filterRole?.id,
                min_user_rating: filters.filterRating?.value,
                max_user_rating: filters.filterRating?.value
                  ? filters.filterRating?.value + 0.99
                  : null,
                order_by_created_at: "asc",
                responseType: "requests",
                city_id: filters.filterCity?.id,
              },
            },
      })
    );
    handleCloseWarningApproveModal();
  };

  const handleCloseWarningApproveModal = () => {
    setOpenWarningApproveModal(false);
  };

  /********************************************
  // 2) REDUX MAIN FUN PERSIST STATES FILTERS
  ********************************************/
  const handlePageChange = (newPage) => {
    dispatch(setPageRequest(newPage));
    dispatch(initialFetchRequest({ id }));
  };

  const handleRowsRequestChange = (rows) => {
    dispatch(setRowsCountRequest(rows));
    dispatch(setPageRequest(1));
  };

  const handleFilterRequest = (newFilter) => {
    dispatch(setFiltersRequest({ ...filters, ...newFilter }));
    dispatch(setPageRequest(1));
  };
  const handleFilterStatus = (status) => {
    dispatch(setFilterStatusRequest({ filterRequestStatus: status }));
  };

  const handleRow = (row) => {
    if (isInviteApplicants ? !row.deleted_at : !row.user?.deleted_at) {
      history.push(
        ROUTE_PATHS.viewRequestsApplicant.replace(
          ":id",
          isInviteApplicants ? row.id : row.user?.id
        )
      );
    }
  };

  /************************************
   INITIAL FETCH THE EVENT APPLICANTS
   ***********************************/

  // 8) EFFECT TO INITIAL FILTER'S & INITIAL APPLICANTS
  useEffect(() => {
    dispatch(initialFetchRequest({ id }));
  }, [filters, note]);

  // 9) QUERY TO SEARCH APPLICANTS
  useEffect(() => {
    if ((q.length >= 3 || q.length === 0) && !textContainOnlySpaces(q)) {
      dispatch(setSearchValueRequest(q));
    }
    if (q.length >= 3) {
      dispatch(setPageRequest(1));
    }
  }, [q]);

  useEffect(() => {
    dispatch(initialFetchRequest({ id }));
    dispatch(getLocationsRequest());
    dispatch(getLookupsRequest([lookupsKeys.languages, lookupsKeys.roles]));
    dispatch(getCountriesRequest({ page: -1 }));
    dispatch(getSpeakingProficiencyRequest());
    dispatch(
      getCitiesRequest({
        page: -1,
        order_by_name_en: lang === "en" ? "asc" : null,
        order_by_name_ar: lang === "ar" ? "asc" : null,
      })
    );
  }, [lang, note]);

  const updatedSpeaking = speakingProficiency.map((item) => ({
    ...item,
    name: item.label,
  }));

  const onEditRole = ({
    options,
    currentRole,
    applicantId,
    index,
    applicantName,
  }) => {
    setRolesOptions(options);
    setOriginalRole(currentRole);
    setRoleIndex(index);
    setUserName(applicantName);
    setActiveUserId(applicantId);
    setOpenRoleModal(true);
  };

  const handleSelectRole = (value, index) => {
    if (value) {
      const selectedRolesCopy = [...selectedRoles];
      selectedRolesCopy[index] = value;
      setSelectedRoles(selectedRolesCopy);
    }
  };

  const handleCloseRoleModal = (selectedRole) => {
    handleSelectRole(selectedRole || originalRole, roleIndex);
    setOpenRoleModal(false);
    setUserName(null);
    setActiveUserId(null);
    setOriginalRole(null);
    setRolesOptions([]);
    setRoleIndex(null);
    setIsRoleSelected(false);
    setAutoSelect(false);
  };

  const renderRolesOptions = () => {
    const radioList = rolesOptions?.map((option) => ({
      value: option?.id,
      label: option?.name,
    }));
    const currentRole = selectedRoles?.[roleIndex]?.id;
    const selectedRole = radioList.find(
      (item) => item.value == selectedRoles?.[roleIndex]?.id
    );
    if (isRoleSelected != !!selectedRole) setIsRoleSelected(!!selectedRole);
    return (
      <RadioButtonsGroup
        id="user-role"
        name="user-role"
        handleChange={(e) => {
          const selectedRole = rolesOptions.find(
            (elt) => elt.id == e.target.value
          );
          handleSelectRole(selectedRole, roleIndex);
        }}
        value={currentRole}
        listOfRadios={radioList}
      />
    );
  };

  const handleConfirmRole = () => {
    const requestData = list[roleIndex];
    if (isInviteApplicants) {
      handleChangeSelectedApplicantRole();
      handleCloseRoleModal(selectedRoles[roleIndex]);
    } else {
      approveRequestHandler(
        selectedRoles[roleIndex],
        requestData.id,
        requestData.acceptable_roles,
        requestData.user?.gender
      );
      handleCloseRoleModal();
    }
  };

  const handleChangeSelectedApplicantRole = () => {
    const selectedApplicant = selectedApplicants.find(
      (applicant) => applicant.user_id === activeUserId
    );
    const selectedApplicantIndex = selectedApplicants.findIndex(
      (applicant) => applicant.user_id === activeUserId
    );
    if (selectedApplicant) {
      const copySelectedApplicantsList = [...selectedApplicants];
      copySelectedApplicantsList.splice(selectedApplicantIndex, 1, {
        ...selectedApplicant,
        role: selectedRoles[roleIndex],
      });
      setSelectedApplicants(copySelectedApplicantsList);
    } else {
      if (autoSelect) handleSelectApplicant(true, activeUserId, roleIndex);
    }
  };

  const handleSelectApplicant = (isSelected, applicantId, applicantIndex) => {
    if (isSelected) {
      const applicantSelectedRole = selectedRoles[applicantIndex];
      setSelectedApplicants([
        ...selectedApplicants,
        {
          user_id: applicantId,
          role: applicantSelectedRole,
        },
      ]);
    } else {
      setSelectedApplicants(
        selectedApplicants.filter(
          (applicant) => applicant.user_id !== applicantId
        )
      );
    }
  };

  const handleInviteApplicants = () => {
    const invitations = selectedApplicants
      .filter(({ role }) => role)
      .map(({ user_id, role }) => ({
        user_id,
        role_id: role.id,
      }));
    dispatch(
      inviteApplicantsRequest({
        event_id: +id,
        invitations,
      })
    );
  };

  const handleApprovedBulkRequest = () => {
    dispatch(
      bulkApprovedRequestsRequest({
        event_request_ids: approvedCheckedId,
        event_id: +id,
        listParams: isApplicant
          ? {
              id,
              params: {
                page: filters.page,
                items: 10,
                user_id: userId,
                event_name: filters.searchValue,
                status: "pending",
                role_id: filters.filterRole?.id,
                order_by_created_at: "desc",
                responseType: "requests",
              },
            }
          : {
              id,
              params: {
                page: filters.page,
                items: 10,
                event_and_user_name: filters.searchValue,
                status: filters.filterRequestStatus?.value ?? 'pending',
                role_id: filters.filterRole?.id,
                min_user_rating: filters.filterRating?.value,
                max_user_rating: filters.filterRating?.value
                  ? filters.filterRating?.value + 0.99
                  : null,
                order_by_created_at: "asc",
                responseType: "requests",
                city_id: filters.filterCity?.id,
              },
            },
      })
    );
    handleApprovedCloseModal();
  };

  const renderRequestsList = () => {
    if (!isLoading && !list?.length) {
      return (
        <>
          <div className="bg-white rounded-4 mt-4 pb-3">
            <EmptyState
              description={
                isInviteApplicants
                  ? applicants.listApplicants.noDataDescription
                  : requests.listRequests.placeholder
              }
              imgSrc={
                isInviteApplicants ? applicantPlaceholder : requestPlaceholder
              }
              showActionButton={false}
            />
          </div>
        </>
      );
    } else {
      return (
        <>
          {isInviteApplicants && (
            <div className="selected-applicants-count text-end">
              <p className="mb-0">
                <span
                  className={`text-light-grey ${
                    lang === "en" ? "me-2" : "ms-2"
                  }`}
                >
                  {shared.selected}
                </span>
                <span
                  className={`${
                    selectedApplicants?.length > 50
                      ? "text-warning"
                      : "text-primary-blue"
                  } fweight-500`}
                >
                  {selectedApplicants?.length}
                </span>
                <span className="text-light-grey mx-2">{shared.outOf}</span>
                <span className="text-primary-blue fweight-500">{50}</span>
              </p>
            </div>
          )}
          {approvedCheckedId?.length > 0 && (
            <div className="mb-3">
              <Button
                onClick={handleApprovedOpenModal}
                label={requests.listRequests.approvedRequest}
                disabled={
                  filters.filterRequestStatus?.value
                    ? filters.filterRequestStatus.value !==
                      requestStatuses?.notAccepted
                    : false
                }
              />
            </div>
          )}
          <div className="bg-white rounded-4 mt-2 pb-3">
            <Table
              className="rounded-4"
              headlines={headlines}
              hasActions={false}
              hasCheckbox={true && !isInviteApplicants && !isApplicant}
              isSelected={(id) => isSelectedIds(id)}
              selected={selectedCheckedIds}
              handleSelectAllClick={(event, _row) =>
                handleSelectAllClick(event, _row)
              }
              handleCheckboxClick={(event, id) =>
                handleCheckboxClick(event, id)
              }
              rows={list?.map((row, i) => {
                const acceptableRoles = row.acceptable_roles;
                const currentRole = selectedRoles[i];
                const isRoleAcceptable = !!acceptableRoles.find(
                  (role) => role?.id == currentRole?.id
                );
                return (
                  <TableRow key={i}>
                    {isInviteApplicants && (
                      <TableCell className="select-column">
                        <Checkbox
                          name={row.id}
                          id={row.id}
                          checked={
                            !!selectedApplicants.find(
                              (applicant) => applicant.user_id === row.id
                            )
                          }
                          onChange={(isChecked) => {
                            if (isChecked && !isRoleAcceptable) {
                              setAutoSelect(true);
                              onEditRole({
                                options: row.acceptable_roles,
                                currentRole: selectedRoles[i],
                                index: i,
                                applicantId: row.id,
                                applicantName:
                                  row[
                                    lang === "en"
                                      ? "full_name_en"
                                      : "full_name_ar"
                                  ],
                              });
                            } else {
                              handleSelectApplicant(isChecked, row.id, i);
                            }
                          }}
                          sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                        />
                      </TableCell>
                    )}
                    {!isApplicant && (
                      <TableCell className="mobile-style city-cell">
                        {row.user?.id || "-"}
                      </TableCell>
                    )}

                    {!isApplicant && (
                      <TableCell className="avatar-column">
                        <Avatar
                          imgSrc={
                            isInviteApplicants ? row.image : row.user?.image
                          }
                          imgSrcAlt="user-picture"
                          size={30}
                          name={
                            isInviteApplicants
                              ? row.full_name_en
                              : row.user?.full_name_en
                          }
                        />
                      </TableCell>
                    )}
                    {!isApplicant && (
                      <TableCell
                        className={`${
                          (isInviteApplicants
                            ? !row.deleted_at
                            : !row.user?.deleted_at) && "pointer"
                        } mobile-style name-cell`}
                        onClick={() => handleRow(row)}
                      >
                        {(
                          isInviteApplicants
                            ? row["full_name_" + lang]
                            : row.user?.["full_name_" + lang]
                        )
                          ? renderTextEllipsis(
                              isInviteApplicants
                                ? row["full_name_" + lang]
                                : row.user?.["full_name_" + lang]
                            )
                          : "-"}
                      </TableCell>
                    )}
                    {!isInviteApplicants && (
                      <TableCell className="pointer mobile-style event-cell">
                        <Link
                          onClick={() => {
                            history.push(
                              ROUTE_PATHS.viewEvent.replace(
                                ":id",
                                row.event?.id
                              )
                            );
                          }}
                        >
                          {row.event?.name
                            ? renderTextEllipsis(row.event?.name)
                            : "-"}
                        </Link>
                      </TableCell>
                    )}
                    {!isApplicant && (
                      <TableCell className="mobile-style city-cell">
                        {(isInviteApplicants
                          ? row.city?.name
                          : row.user?.city?.name) || "-"}
                      </TableCell>
                    )}
                    {!isApplicant && (
                      <TableCell className="mobile-style">
                        {(isInviteApplicants ? row.gender : row.user.gender)
                          ? shared.genderOptions[
                              isInviteApplicants ? row.gender : row.user.gender
                            ]
                          : "-"}
                      </TableCell>
                    )}
                    {!isApplicant && (
                      <TableCell className="mobile-style date-cell">
                        {row?.user?.note?.custom_location ||
                          row?.user?.note?.location?.name ||
                          "-"}
                      </TableCell>
                    )}
                    <TableCell className="mobile-style role-cell">
                      {(
                        isInviteApplicants
                          ? row.role?.name_en !== "Supervisor"
                          : row.status === "pending" &&
                            row.role?.name_en !== "Supervisor"
                      )
                        ? selectedRoles[i] && (
                            <div className="d-flex align-items-center justify-content-between edit-role-wrapper">
                              <span>{selectedRoles[i]?.name}</span>
                              <BorderColorOutlined
                                className="mx-2 pointer"
                                color={
                                  isInviteApplicants
                                    ? isRoleAcceptable
                                      ? "primary"
                                      : "error"
                                    : "primary"
                                }
                                fontSize="14px"
                                onClick={() =>
                                  onEditRole({
                                    options: row.acceptable_roles,
                                    currentRole: selectedRoles[i],
                                    index: i,
                                    applicantId: isInviteApplicants
                                      ? row.id
                                      : row.user?.id,
                                    applicantName: isInviteApplicants
                                      ? row[
                                          lang === "en"
                                            ? "full_name_en"
                                            : "full_name_ar"
                                        ]
                                      : row.user?.[
                                          lang === "en"
                                            ? "full_name_en"
                                            : "full_name_ar"
                                        ],
                                  })
                                }
                              />
                            </div>
                          )
                        : row.role?.name}
                    </TableCell>
                    {!isApplicant && (
                      <TableCell>
                        {(
                          isInviteApplicants ? row.rating : row.user?.rating
                        ) ? (
                          <div className="MuiDataGrid-cellContent d-flex">
                            <Rate
                              rating={
                                isInviteApplicants
                                  ? +row.rating
                                  : +row.user?.rating.toFixed(2)
                              }
                            />
                          </div>
                        ) : (
                          "-"
                        )}
                      </TableCell>
                    )}
                    {!isInviteApplicants && (
                      <TableCell className="mobile-style date-cell">
                        {row?.user?.nationality?.name}
                      </TableCell>
                    )}

                    {!isApplicant && !isInviteApplicants && (
                      <TableCell>
                        <Chip
                          label={requests.listRequests[row.status]}
                          className={`fsize-14 fweight-500 text-white status-container ${row.status}`}
                        />
                      </TableCell>
                    )}
                    {!isInviteApplicants && (
                      <TableCell>
                        <Button
                          label={shared.approve}
                          className="request-action"
                          labelClass={`fsize-14 fweight-500 text-white`}
                          block
                          onClick={() => {
                            approveRequestHandler(
                              selectedRoles[i],
                              row.id,
                              row.acceptable_roles,
                              row.user?.gender
                            );
                          }}
                          disabled={row.status !== "pending"}
                        />
                      </TableCell>
                    )}

                    <TableCell className="mobile-style date-cell">
                      <div className="d-flex align-items-center gap-2">
                        {!isInviteApplicants && !row?.user?.note && (
                          <Button
                            block
                            labelClass={`fsize-14 fweight-500 text-white`}
                            label={shared.addNote}
                            onClick={() => handleAddNote(row?.user.id)}
                          />
                        )}
                        {!isInviteApplicants && row?.user?.note && (
                          <div className="d-flex gap-2 flex-wrap">
                            <Button
                              label={shared.editNote}
                              labelClass={`fsize-14 fweight-500 text-white`}
                              onClick={() => handleEditNote(row?.user.id)}
                            />
                            <Button
                              outlined={true}
                              label={shared.deleteNote}
                              labelClass={`fsize-14 fweight-500`}
                              negative={true}
                              onClick={() => handleDeleteNote(row?.user.id)}
                            />
                          </div>
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            />
          </div>
          <div className="row my-4">
            <div className="col-md-6 col-xl-7 d-flex align-items-center justify-content-end">
              <div
                className={`d-flex justify-content-center ${
                  isInviteApplicants ? "" : "py-3 mt-3"
                }`}
              >
                {meta.pages > 1 && (
                  <Pagination
                    count={meta.pages}
                    page={meta.currentPage}
                    handleChange={(event, page) => {
                      handlePageChange(page);
                    }}
                    defaultPage={1}
                  />
                )}
              </div>
            </div>
            <div className="col-md-6 col-xl-5 d-flex align-items-center justify-content-end">
              <div className="rows-count-selection">
                <Select
                  name="rows-count"
                  label={shared.numberOfRows}
                  value={rowsCountOptions.find(
                    (count) => count.id === filters.rowsCount
                  )}
                  onChange={(count) => {
                    handleRowsRequestChange(count.id);
                  }}
                  options={rowsCountOptions}
                  wrapperClass={`rows-count-input`}
                  disableClearable={true}
                />
              </div>
            </div>
          </div>

          {isInviteApplicants && (
            <div className="d-flex justify-content-end my-3">
              <div className="d-flex justify-content-end align-items-center invite-btns-container">
                <Button
                  type="button"
                  block
                  label={shared.invite}
                  labelClass="px-4"
                  disabled={
                    selectedApplicants?.length > 50 ||
                    selectedApplicants?.length === 0
                  }
                  className="mx-2 continue-btn"
                  onClick={handleInviteApplicants}
                />
                <Button
                  onClick={() => {
                    history.push(ROUTE_PATHS.viewEvent.replace(":id", id));
                  }}
                  type="button"
                  block
                  label={shared.cancel}
                  labelClass="px-4"
                  outlined
                  className="mx-2 cancel-btn"
                />
              </div>
            </div>
          )}
          {openRoleModal && (
            <Modal
              open={openRoleModal}
              handleClose={() => handleCloseRoleModal()}
              title={`${requests.listRequests.changeRoleTitle} ${userName}`}
              content={renderRolesOptions()}
              isContentText={false}
              confirmBtnTxt={isInviteApplicants ? shared.save : shared.approve}
              maxWidth="xs"
              cancelBtnTxt={shared.cancel}
              handleCancel={() => handleCloseRoleModal()}
              handleConfirm={handleConfirmRole}
              modalClass="role-select-modal"
              isDisabled={!isRoleSelected}
            />
          )}
          {openWarningApproveModal && (
            <Modal
              open={openWarningApproveModal}
              handleClose={handleCloseWarningApproveModal}
              title={requests.listRequests.warningTitle}
              content={
                <Alert severity="warning" className="fsize-16">
                  {requests.listRequests.warningMessage}
                </Alert>
              }
              isContentText={false}
              confirmBtnTxt={shared.approve}
              maxWidth="xs"
              cancelBtnTxt={shared.cancel}
              handleConfirm={() => onApproveRequest(singleRole, requestId)}
              handleCancel={handleCloseWarningApproveModal}
              modalClass="warning-modal"
              actionsClass="container"
              confirmBtnClass="btn-secondary text-white"
            />
          )}
        </>
      );
    }
  };

  return (
    <div
      className={`conatiner requests-list-container ${
        isInviteApplicants && "invite-applicants-list-container"
      }`}
    >
      <div className="page-title-margin">
        <Breadcrumbs list={breadcrumbsList} BreadCrumbHasBackIcon={false} />
      </div>
      <Filters
        searchInput={{
          placeholder: searchPlaceholder,
          name: "search",
          value: q,
          onChange: (value) => setQuery(value),
        }}
        filters={
          isApplicant
            ? [
                {
                  type: "filter",
                  name: "role",
                  placeholder: shared.role,
                  value: filters.filterRole,
                  options: roles,
                  onChange: (value) =>
                    handleFilterRequest({ filterRole: value }),
                },
              ]
            : isInviteApplicants
            ? [
                {
                  type: "filter",
                  name: "rating",
                  placeholder: requests.listRequests.rating,
                  value: filters.filterRating,
                  options: ratingOptions,
                  isOptionsElt: true,
                  onChange: (value) =>
                    handleFilterRequest({ filterRating: value }),
                },
                {
                  type: "filter",
                  name: "gender",
                  placeholder: events?.viewEvents?.applicantsTab?.gender,
                  value: filters.filterGender,
                  options: genderOptions,
                  onChange: (value) =>
                    handleFilterRequest({ filterGender: value }),
                },
                {
                  type: "filter",
                  name: "role",
                  placeholder: events?.viewEvents?.applicantsTab?.role,
                  value: filters.filterRole,
                  options: roles,
                  onChange: (value) =>
                    handleFilterRequest({ filterRole: value }),
                },
                {
                  type: "filter",
                  name: "city",
                  placeholder: requests.listRequests.cityOfResidence,
                  value: filters.filterCity,
                  options: cities,
                  onChange: (value) =>
                    handleFilterRequest({ filterCity: value }),
                },
              ]
            : [
                {
                  type: "filter",
                  name: "status",
                  placeholder: requests.listRequests.status,
                  value: filters.filterRequestStatus,
                  options: statusOptions,
                  onChange: (value) => handleFilterStatus(value),
                },
                {
                  type: "filter",
                  name: "rating",
                  placeholder: requests.listRequests.rating,
                  value: filters.filterRating,
                  options: ratingOptions,
                  isOptionsElt: true,
                  onChange: (value) =>
                    handleFilterRequest({ filterRating: value }),
                },
                {
                  type: "filter",
                  name: "gender",
                  placeholder: events?.viewEvents?.applicantsTab?.gender,
                  value: filters.filterGender,
                  options: genderOptions,
                  onChange: (value) =>
                    handleFilterRequest({ filterGender: value }),
                },
                {
                  type: "filter",
                  name: "role",
                  placeholder: events?.viewEvents?.applicantsTab?.role,
                  value: filters.filterRole,
                  options: roles,
                  onChange: (value) =>
                    handleFilterRequest({ filterRole: value }),
                },
                {
                  type: "filter",
                  name: "city",
                  placeholder: requests.listRequests.cityOfResidence,
                  value: filters.filterCity,
                  options: cities,
                  onChange: (value) =>
                    handleFilterRequest({ filterCity: value }),
                },
                {
                  type: "filter",
                  name: "nationality",
                  placeholder: requests.listRequests.nationality,
                  value: filters.filterNationality,
                  options: countries,
                  onChange: (value) =>
                    handleFilterRequest({ filterNationality: value }),
                },
                {
                  type: "filter",
                  name: "language",
                  placeholder: requests.listRequests.language,
                  value: filters.filterLanguage,
                  options: languages,
                  onChange: (value) =>
                    handleFilterRequest({ filterLanguage: value }),
                },
                {
                  type: "filter",
                  name: "Speaking Proficiency",
                  placeholder: requests.listRequests.speaking,
                  value: filters.filterSpeaking,
                  options: updatedSpeaking,
                  onChange: (value) =>
                    handleFilterRequest({ filterSpeaking: value }),
                },
              ]
        }
      />
      <div className="d-flex flex-column justify-content-between">
        {renderRequestsList()}
      </div>
      <Modal
        open={isNoteModalOpen}
        title={shared.addYourNote}
        handleClose={handleModalClose}
        cancelBtnTxt={shared.cancel}
        handleCancel={handleModalClose}
        confirmBtnTxt={shared.submit}
        handleConfirm={handleSubmit}
        isDisabled={validate}
        content={
          <Note
            validate={validate}
            setValidate={setValidate}
            handleDelete={(id) => handleDeleteLocation(id)}
            handleSubmit={handleSubmit}
            setOtherValue={setOtherValue}
            otherValue={otherValue}
            selectValue={selectValue}
            setSelectValue={setSelectValue}
            location={location}
            handleCancel={handleModalClose}
          />
        }
      />
      <Modal
        open={approvedModalClose}
        handleClose={handleApprovedCloseModal}
        handleCancel={handleApprovedCloseModal}
        confirmBtnTxt={shared.submit}
        cancelBtnTxt={shared.cancel}
        handleConfirm={handleApprovedBulkRequest}
        content={
          <h3 className={"text-center"}>{requests.listRequests.areYouSure}</h3>
        }
      />
    </div>
  );
};
RequestsTable.propTypes = {
  isApplicant: PropTypes.bool,
  isInviteApplicants: PropTypes.bool,
  headlines: PropTypes.array,
  breadcrumbsList: PropTypes.array,
  userId: PropTypes.number,
  searchPlaceholder: PropTypes.string,
};

RequestsTable.defaultProps = { isApplicant: false };

export default RequestsTable;
