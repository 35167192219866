const report = {
  logistics: "التقارير",
  listIssuesBreadcrumb: "قائمة الحالات",
  listReportBreadcrumb: "تفاصيل التقرير",
  listReport: {
    noDataFound: "عذراً، لا توجد قضايا",
    open: "مفتوح",
    closed: "مغلق",
    reportList: "قائمة التقارير",
    zones: "المناطق",
    supervisor: "المشرف",
    createdDate: "تاريخ الإنشاء",
    createdTime: "وقت الإنشاء",
    closedTime: "وقت الإغلاق",
    closedDate: "تاريخ الإغلاق",
    date: "التاريخ",
    time: "الوقت",
    status: "الحالة",
    zoneNo: "رقم المنطقة",
    issueDesc: "وصف الحالة",
    images: "الصور",
  },
  modalTitle: "حدد الحقول لتصدير ملف CSV",
  exportSheetColumns: {
    reportList: "قائمة التقارير",
    description: "الوصف",
    zone: "المنطقة",
    eventName: "اسم الحدث",
    supervisor: "المشرف",
    exportReportToExcel: "تصدير التقارير إلى ملف إكسل",
  },
  noPreview: "لا توجد معاينة متاحة!",
  updates: "تعليقات",
};

export default report;
