import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TableCell, TableRow } from "@mui/material";

import Breadcrumbs from "../../components/BreadCrumbs";
import Table from "../../components/Table";
import Pagination from "../../components/Pagination";
import EmptyState from "../../components/EmptyState";
import messages from "../../assets/locale/messages";
import eventPlaceholder from "../../assets/images/event-screen/events-placeholder.svg";
import { formatDate, renderTextEllipsis } from "../../utils/Helpers";
import { ROUTE_PATHS } from "../../utils/RoutesPaths";
import { useHistory, useParams } from "react-router-dom";
import {
  exportReportSheetRequest,
  getReportsRequest,
} from "../../store/Reports/actions";
import "./Report.scss";
import Button from "../../components/Button";
import { ExcelIcon } from "../../utils/Icons";
import Modal from "../../components/Modal";
import SelectedExportColumnsModal from "./SelectExportedColumnsModal/SelectExportedColumnsModal";
import Select from "../../components/Select";

const rowsCountOptions = [
  { id: 10, name: "10" },
  { id: 20, name: "20" },
  { id: 30, name: "30" },
];

const Reports = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.locale.lang);
  const { report, shared } = messages[lang];
  const isLoading = useSelector((state) => state.loader);
  const list = useSelector((state) => state.report.list);
  const meta = useSelector((state) => state.report.meta);
  const [page, setPage] = useState(1);
  const [rowsCount, setRowsCount] = useState(10);

  const [showModal, setShowModal] = useState(false);
  const [selectedFields, setSelectedFields] = useState({
    title: false,
    date: false,
    time: false,
    status: false,
    zone_name: false,
    event_name: false,
    description: false,
    pictures_url: false,
    supervisor_name: false,
    comments: false,
  });

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedFields({
      title: false,
      date: false,
      time: false,
      status: false,
      zone_name: false,
      event_name: false,
      description: false,
      pictures_url: false,
      supervisor_name: false,
      comments: false,
    });
  };
  const handleChange = (event) => {
    const { name, checked } = event.target;
    setSelectedFields((prevFields) => ({
      ...prevFields,
      [name]: checked,
    }));
  };

  const handleExportSheet = async (event) => {
    event.preventDefault();
    const columns = Object.keys(selectedFields)
      .filter((key) => selectedFields[key])
      .join(",");
    dispatch(
      exportReportSheetRequest({
        id,
        params: { columns },
      })
    );
    handleCloseModal();
  };

  const getReports = (page, perPage) => {
    dispatch(getReportsRequest({ id, params: { page, items: perPage } }));
  };

  useEffect(() => {
    getReports(page, rowsCount);
  }, [lang, page, rowsCount]);

  const renderLogisticsList = () => {
    if (!isLoading && !list?.length) {
      return (
        <>
          <div className="bg-white rounded-4 d-flex justify-content-center align-items-center flex-column my-4">
            <EmptyState
              description={report.listReport.noDataFound}
              imgSrc={eventPlaceholder}
              showActionButton={false}
            />
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="bg-white rounded-4 mt-2 pb-3">
            <Table
              className="rounded-4"
              headlines={[
                report.listReport.reportList,
                report.listReport.zones,
                report.listReport.supervisor,
                report.listReport.createdDate,
                report.listReport.createdTime,
                report.listReport.closedDate,
                report.listReport.closedTime,
                report.listReport.status,
              ]}
              hasActions={false}
              hasSizeLimitation={false}
              rows={list?.map((row, i) => (
                <TableRow
                  key={i}
                  className="row-hover"
                  onClick={() =>
                    history.push(
                      ROUTE_PATHS["viewReportDetail"]
                        .replace(":event_id", id)
                        .replace(":id", row?.id)
                    )
                  }
                >
                  <TableCell className="pointer">
                    {renderTextEllipsis(row?.title)}
                  </TableCell>

                  <TableCell className="w-15">
                    {row?.zone_name ?? "-"}
                  </TableCell>
                  <TableCell>{row?.submitted_by ?? "-"}</TableCell>
                  <TableCell className="mobile-style">
                    {formatDate(row.date)}
                  </TableCell>
                  <TableCell className="mobile-style">
                    {row?.time ?? "-"}
                  </TableCell>
                  <TableCell className="w-15 ">
                    {row?.status === report.listReport.open.toLocaleLowerCase()
                      ? "-"
                      : row?.closed_date}
                  </TableCell>
                  <TableCell className="w-15 ">
                    {row?.status === report.listReport.open.toLocaleLowerCase()
                      ? "-"
                      : row?.closed_time}
                  </TableCell>
                  <TableCell className="w-15 ">
                    <span className={`type-container fsize-14 ${row.status}`}>
                      {row.status &&
                        messages[lang].report.listReport[row.status]}
                    </span>
                  </TableCell>
                </TableRow>
              ))}
            />
          </div>
          <div className="row my-4">
            <div className="col-md-6 d-flex align-items-center justify-content-end">
              <div className="d-flex justify-content-center py-3 mt-4">
                {meta.pages > 1 && (
                  <Pagination
                    count={meta.pages}
                    page={meta.currentPage || page}
                    handleChange={(event, page) => {
                      setPage(page);
                    }}
                    defaultPage={1}
                  />
                )}
              </div>
            </div>
            <div className="col-md-6 d-flex align-items-center justify-content-end">
              <div className="rows-count-selection">
                <Select
                  name="rows-count"
                  label={shared.numberOfRows}
                  value={rowsCountOptions.find(
                    (count) => count.id === rowsCount
                  )}
                  onChange={(count) => {
                    setRowsCount(count.id);
                  }}
                  options={rowsCountOptions}
                  wrapperClass={`rows-count-input`}
                  disableClearable={true}
                />
              </div>
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <div className="events-list-container">
      <div className="w-100 page-title-margin d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-md-center top-container">
        <Breadcrumbs
          list={[
            {
              path: ROUTE_PATHS.reports,
              label: shared.listBreadCrumbs.listReportsBreadcrumb,
            },
            {
              path: "",
              label: report.listIssuesBreadcrumb,
            },
          ]}
          BreadCrumbHasBackIcon={false}
        />
        {list?.length > 0 && (
          <div>
            <Button
              label={
                <>
                  <ExcelIcon />
                  <span className={`${lang === "en" ? "ms-2" : "me-2"}`}>
                    {report.exportSheetColumns.exportReportToExcel}
                  </span>
                </>
              }
              outlined
              small
              positive
              onClick={() => setShowModal(true)}
            />
          </div>
        )}
      </div>
      <div className="d-flex flex-column justify-content-between">
        {renderLogisticsList()}
      </div>
      <Modal
        open={showModal}
        title={report.modalTitle}
        handleClose={handleCloseModal}
        cancelBtnTxt={shared.cancel}
        handleCancel={handleCloseModal}
        confirmBtnTxt={shared.confirm}
        handleConfirm={handleExportSheet}
        maxWidth={"xs"}
        content={
          <SelectedExportColumnsModal
            handleSubmit={handleExportSheet}
            selectedFields={selectedFields}
            handleChange={handleChange}
          />
        }
      />
    </div>
  );
};

export default Reports;
