import * as types from "./types";

const initialState = {
  page: 1,
  searchValue: "",
  rowsCount: 10,
  event_type: null,
  event_status: null,
  event_start_date: null,
  event_end_date: null,
};

const persistTimeSheet = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_TIMESHEET_FILTERS:
      return {
        ...state,
        ...action.payload,
      };
    case types.SET_TIMESHEET_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    case types.SET_TIMESHEET_SEARCH:
      return {
        ...state,
        searchValue: action.payload,
      };
    default:
      return state;
  }
};

export default persistTimeSheet;
