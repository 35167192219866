import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import messages from "../../assets/locale/messages";
import {
  submitEvaluationEventRequest,
  viewFormRequest,
} from "../../store/Evaluations/actions";
import Breadcrumbs from "../../components/BreadCrumbs";
import Button from "../../components/Button";
import { ROUTE_PATHS } from "../../utils/RoutesPaths";
import { Rating } from "@mui/material";
import RichText from "../../components/RichText";
import Modal from "../../components/Modal";

const EditEvaluation = () => {
  const { formid, id: idParam } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const formData = useSelector((state) => state.evaluations.formData);
  const lang = useSelector((state) => state.locale.lang);
  const { evaluations, shared, applicants } = messages[lang];
  const [skillsRate, setSkillsRate] = useState([]); // State to hold user's ratings
  const [comment, setComment] = useState(null);
  const [openSubmitModal, setOpenSubmitModal] = useState(false);

  useEffect(() => {
    if (formData && formData.evaluation_form_questions) {
      const initialRates = formData.evaluation_form_questions.map((ques) => ({
        id: ques.id,
        rate: ques.rate || 0,
      }));
      setSkillsRate(initialRates);
    }
    setComment(formData.comment);
  }, [formData]);

  useEffect(() => {
    if (formid) {
      dispatch(viewFormRequest(formid));
    }
  }, [formid, lang]);

  const areAllSkillsRated = () => {
    const originalRatesIds = formData?.evaluation_form_questions?.map(
      (ques) => ques.id
    );

    return originalRatesIds?.every((id) => {
      return skillsRate.some((elt) => elt.id === id && elt.rate > 0);
    });
  };

  const onRatingChange = (id, rate) => {
    setSkillsRate((prevSkillsRate) => {
      const newSkillsRate = [...prevSkillsRate];
      const skillIndex = newSkillsRate.findIndex((elt) => elt.id === id);
      if (skillIndex !== -1) {
        newSkillsRate[skillIndex] = { id, rate };
      } else {
        newSkillsRate.push({ id, rate });
      }
      return newSkillsRate;
    });
  };

  const getSkillRate = (id) => {
    const skill = skillsRate.find((elt) => elt.id === id);
    return skill ? skill?.rate : 0;
  };

  const handleConfirmModalAction = () => {
    dispatch(
      submitEvaluationEventRequest({
        redirectionPath: ROUTE_PATHS.inprogressEvaluations.replace(
          ":id",
          idParam
        ),
        id: formid,
        data: {
          comment,
          evaluation_form_questions_attributes: [...skillsRate],
        },
      })
    );
    setOpenSubmitModal(false);
  };

  const handleCancel = () => {
    history.goBack();
  };

  const evaluationBreadCrumbs = [
    {
      path: ROUTE_PATHS.evaluations,
      label: evaluations.listForms.title,
    },
    {
      path: "",
      label: formData?.applicant
        ? formData?.applicant?.[lang === "en" ? "full_name_en" : "full_name_ar"]
        : applicants.applicantEvents.evaluationForm,
    },
  ];

  return (
    <div className="form-wrapper evaluation-form-container">
      <div className="w-100 page-title-margin d-flex justify-content-between align-items-center">
        <div className="d-flex w-100 align-items-center justify-content-between gap-2">
          <Breadcrumbs
            list={evaluationBreadCrumbs}
            BreadCrumbHasBackIcon={false}
          />
        </div>
      </div>
      <form noValidate>
        <div className="bg-white p-4 rounded-2 mb-4 evaluations-skills-wrapper">
          <div className="row">
            <div className="title-wrapper col-12 mb-4">
              <span></span>
              <h2 className="mb-0">
                {evaluations.evaluationsForm.evaluationSkills}
              </h2>
            </div>
            {formData?.evaluation_form_questions?.map(({ id, body }) => (
              <div className="col-12 mb-3" key={id}>
                <div className="d-flex flex-wrap  align-items-center">
                  <span className="skill-title w-20">{body}:</span>
                  <Rating
                    className="rating-stars"
                    id={id}
                    name={id}
                    readOnly={false}
                    value={getSkillRate(id)}
                    onChange={(_, newValue) => {
                      onRatingChange(id, newValue);
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="bg-white p-4 rounded-2 mb-4">
          <div className="row">
            <div className="title-wrapper col-12">
              <span></span>
              <h2 className="mb-0">{evaluations.evaluationsForm.comments}</h2>
            </div>
            <div className="col-12">
              <div>
                {
                  <RichText
                    id="comments"
                    name="comments"
                    value={comment}
                    onChange={(value) => {
                      setComment(value);
                    }}
                    className="comments-rich-text"
                    maxLength={300}
                  />
                }
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end align-items-center buttom-btns-container">
          <Button
            label={shared.submit}
            type="button"
            labelClass="px-2 text-white py-1 "
            className="mb-4"
            onClick={() => {
              setOpenSubmitModal(true);
            }}
            disabled={!areAllSkillsRated()}
          />
          <Button
            type="button"
            label={shared.cancel}
            labelClass="px-2 py-1"
            outlined
            className="mb-4 mx-2 cancel-btn"
            onClick={handleCancel}
          />
        </div>
      </form>
      <Modal
        open={openSubmitModal}
        handleClose={() => {
          setOpenSubmitModal(false);
        }}
        content={
          <div className="modal-content">
            <p>
              <span>{evaluations.evaluationsForm.confirmMsg}</span>
              <span className="mx-1 fweight-500">
                {
                  formData?.applicant?.[
                    lang === "en" ? "full_name_en" : "full_name_ar"
                  ]
                }
              </span>
              <span>{evaluations.evaluationsForm.evaluation}</span>
            </p>
          </div>
        }
        hasActions
        confirmBtnTxt={shared.save}
        cancelBtnTxt={shared.cancel}
        handleConfirm={handleConfirmModalAction}
        handleCancel={() => {
          setOpenSubmitModal(false);
        }}
      />
    </div>
  );
};
export default EditEvaluation;
