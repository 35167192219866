import { put, takeLatest, select, call } from "redux-saga/effects";
import * as types from "./types";
import * as actions from "./actions";
import { isNumericValue } from "../../utils/Helpers";
import { getEventRequestRequest } from "../Requests/actions";

function* fetchPersistRequestSaga(action) {
  const { id } = action.payload;
  const {
    page,
    rowsCount,
    searchValue,
    filterRequestStatus,
    filterRating,
    filterGender,
    filterRole,
    filterCity,
    filterLanguage,
    filterNationality,
    filterSpeaking,
  } = yield select((state) => state.joiningRequest);
  const searchParams = isNumericValue(searchValue)
    ? { user_id: searchValue }
    : { event_and_user_name: searchValue };
  yield put(
    getEventRequestRequest({
      id,
      params: {
        page,
        items: rowsCount,
        ...searchParams,
        status: filterRequestStatus?.value,
        role_id: filterRole?.id,
        gender: filterGender?.value,
        order_by_created_at: "asc",
        responseType: "requests",
        min_user_rating: filterRating?.value,
        max_user_rating: filterRating?.value
          ? filterRating?.value + 0.99
          : null,
        city_id: filterCity?.id,
        nationality_id: filterNationality?.id,
        language_id: filterLanguage?.id,
        speaking_proficiency: filterSpeaking?.value,
      },
    })
  );
}

function* loadInitialRequestSaga(action) {
  try {
    yield call(fetchPersistRequestSaga, action);
  } catch (error) {
    console.error("Error loading initial state:", error);
  }
}

function* pageRequestSaga(action) {
  try {
    const { page } = yield select((state) => state.joiningRequest);
    if (action.payload !== page) {
      yield put(actions.setPageRequest(action.payload));
    }
  } catch (error) {
    console.error("Error setting page:", error);
  }
}

function* filtersRequestSaga(action) {
  try {
    const currentFilters = yield select((state) => state.joiningRequest);
    const newFilters = action.payload;
    if (JSON.stringify(currentFilters) !== JSON.stringify(newFilters)) {
      yield put(actions.setFiltersRequest(newFilters));
    }
  } catch (error) {
    console.error("Error updating filters:", error);
  }
}


function* rowsCountRequestSaga(action) {
  try {
    const { rowsCount } = yield select((state) => state.joiningRequest);
    if (action.payload !== rowsCount) {
      yield put(actions.setRowsCountRequest(action.payload));
    }
  } catch (error) {
    console.error("Error setting rows count:", error);
  }
}

export default function* joiningRequest() {
  yield takeLatest(types.SET_PAGE_REQUEST, pageRequestSaga);
  yield takeLatest(types.SET_FILTERS_REQUEST, filtersRequestSaga);
  yield takeLatest(types.SET_ROWS_COUNT_REQUEST, rowsCountRequestSaga);
  yield takeLatest(types.INITIAL_FETCH_REQUEST, loadInitialRequestSaga);
}
