import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import messages from "../../../assets/locale/messages";
import { ROUTE_PATHS } from "../../../utils/RoutesPaths";
import { useParams } from "react-router-dom";
import Breadcrumbs from "../../../components/BreadCrumbs";
import Card from "../../../utils/Icons/Card";
import Avatar from "../../../components/Avatar";
import { getReportDetailRequest } from "../../../store/Reports/actions";
import Modal from "../../../components/Modal";
import "../Report.scss";

const ViewReport = () => {
  const { event_id, id } = useParams();
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.locale.lang);
  const { report, shared } = messages[lang];
  const list = useSelector((state) => state.report.list);
  const [previewImage, setPreviewImage] = useState("");
  const [showModal, setShowModal] = useState(false);

  const handlePreview = (url) => {
    if (!url) return;
    setPreviewImage(url);
    setShowModal(true);
  };

  useEffect(() => {
    dispatch(getReportDetailRequest({ event_id, id }));
  }, [lang, id]);

  const renderReportList = () => {
    return (
      <>
        <div className="bg-white rounded-4 mt-2 pb-3">
          <div className="row">
            <div className="col-md-8 col-12 ps-4 pr-0 pb-4 pt-4">
              <h3 className="mb-4 px-4">{list?.title ?? ""}</h3>
              <div className="detail-info p-4">
                <div className="row mb-4 ">
                  <div className="col-lg-3 col-md-6 mb-md-2">
                    <h6 className="mb-2">{report.listReport.zoneNo}</h6>
                    <span className={`type-detail fsize-14 neutral`}>
                      {list?.zone?.name ?? "-"}
                    </span>
                  </div>
                  <div className="col-lg-3 col-md-6 mb-md-2">
                    <h6 className="mb-2">{report.listReport.date}</h6>
                    <span className={`type-detail fsize-14 neutral`}>
                      {list?.date?.split("-").reverse().join("-") ?? "-"}
                    </span>
                  </div>
                  <div className="col-lg-3 col-md-6 mb-md-2">
                    <h6 className="mb-2">{report.listReport.time}</h6>
                    <span className={`type-detail fsize-14 neutral`}>
                      {list?.time?.slice(0, 5) ?? "-"}
                    </span>
                  </div>
                  <div className="col-lg-3 col-md-6 col-12 mb-md-2">
                    <h6 className="mb-2">{report.listReport.status}</h6>
                    <span
                      className={`type-detail px-4 fsize-14 ${list?.status}`}
                    >
                      {list.status &&
                        messages[lang].report.listReport[list.status]}
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mb-4">
                    <h5 className="mb-3">{report.listReport.issueDesc}</h5>
                    <p className="p-4 neutral rounded-3 text-infos">
                      {list?.description}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <h5 className="mb-3 fw-bold">{report.listReport.images}</h5>
                    <div className="row">
                      {list?.pictures?.length > 0 ? (
                        list?.pictures?.map((url, index) => (
                          <div
                            className="col-lg-3 col-6 mb-2 cursor-pointer"
                            onClick={() => handlePreview(list?.pictures[index])}
                            key={index + 1}
                          >
                            <img
                              className="rounded-2 overflow-hidden object-cover"
                              src={url}
                              alt={`Image ${index + 1}`}
                              width={140}
                              height={120}
                            />
                          </div>
                        ))
                      ) : (
                        <span className="fsize-12">{report.noPreview}</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-12 p-4">
              <div className="neutral p-3 rounded-3 d-flex align-items-center justify-content-between">
                <div className="d-flex gap-2 align-items-center">
                  <Card />
                  <h6 className="mb-0">
                    {lang === "en"
                      ? list?.user?.full_name_en ?? "-"
                      : list?.user?.full_name_ar ?? "-"}
                  </h6>
                </div>
                <span>{`(${report.listReport?.supervisor})`}</span>
              </div>
              <div className="mt-5 px-2 aside-bar-main">
                {list.comments?.length > 0 && (
                  <h6 className="mb-3">{report.updates}</h6>
                )}
                {list?.comments?.map((comment) => {
                  const date = comment?.date ? new Date(comment?.date) : null;
                  return (
                    <div
                      className="d-flex gap-2 align-items-start"
                      key={comment?.id}
                    >
                      <Avatar
                        name={"user"}
                        size={24}
                        imgSrc={list?.user?.image}
                      />
                      <div className="mb-3 border-bottom">
                        <div className="d-flex align-items-center mb-1 justify-content-between gap-3">
                          <div className="d-flex align-items-center gap-3">
                            <span className="fw-semibold text-infos">
                              {date.toLocaleDateString("en-GB")}
                            </span>
                            <span className="fw-semibold text-infos">
                              {comment?.time?.slice(0, 5) ?? "-"}
                            </span>
                          </div>
                        </div>
                        <p className="fsize-12 text-infos">
                          {comment?.description ?? ""}
                        </p>
                        <div className="d-flex pb-3 align-items-center gap-3">
                          {comment?.images.length > 0 &&
                            comment?.images.map((image, index) => (
                              <img
                                className="rounded-3 image-cover cursor-pointer"
                                onClick={() =>
                                  handlePreview(comment?.images[index])
                                }
                                key={index}
                                src={image}
                                width={40}
                                height={35}
                                alt={`image ${index + 1}`}
                              />
                            ))}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="events-list-container">
      <div className="page-title-margin">
        <Breadcrumbs
          list={[
            {
              path: ROUTE_PATHS.reports,
              label: shared.listBreadCrumbs.listReportsBreadcrumb,
            },
            {
              path: ROUTE_PATHS.viewReport.replace(":id", event_id),
              label: report.listIssuesBreadcrumb,
            },
            {
              path: "",
              label: report.listReportBreadcrumb,
            },
          ]}
          BreadCrumbHasBackIcon={false}
        />
      </div>
      <div className="d-flex flex-column justify-content-between">
        {renderReportList()}
      </div>

      <Modal
        open={showModal}
        handleClose={() => setShowModal(false)}
        maxWidth={"xs"}
        contentClass="p-0"
        isContentText={false}
        content={
          <div className="preview-image-container">
            <img
              src={previewImage}
              alt="previou image"
              width={"100%"}
              height={"100%"}
              className="image-cover rounded-2"
            />
          </div>
        }
      />
    </div>
  );
};

export default ViewReport;
