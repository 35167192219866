import * as types from "./types";

const initialState = {
  page: 1,
  rowCount: 10,
  searchValue: "",
  status: null,
  gender: null,
  isSupervisor: true,
  zone: null,
  role: null,
  check_in_status: null,
  flag_check_in: null,
  tabValue: "basic",
  resettingFilters: false,
};

const applicantTabs = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_FILTERS_VALUE_REQUEST:
      return {
        ...state,
        ...action.payload,
      };
    case types.SET_PAGE_REQUEST:
      return {
        ...state,
        page: action.payload,
      };
    case types.SET_SEARCH_VALUE_REQUEST:
      return {
        ...state,
        searchValue: action.payload,
      };
    case types.SET_SUPERVISOR_VALUE_REQUEST:
      return {
        ...state,
        isSupervisor: action.payload,
      };
    case types.SET_FLAG_CHECK_IN_VALUE_REQUEST:
      return {
        ...state,
        flag_check_in: action.payload,
      };
    case types.SET_TABS_VALUE_REQUEST:
      return {
        ...state,
        tabValue: action.payload,
      };
    case types.SET_RESETTING_FILTERS:
      return {
        ...state,
        resettingFilters: action.payload,
      };
    default:
      return state;
  }
};
export default applicantTabs;
