import * as types from "./types";

export const initialTimeSheetFetch = () => ({
  type: types.INITIAL_TIMESHEET_FETCH,
});

export const setTimeSheetFilters = (payload) => ({
  type: types.SET_TIMESHEET_FILTERS,
  payload,
});

export const setTimeSheetPage = (payload) => ({
  type: types.SET_TIMESHEET_PAGE,
  payload,
});

export const setTimeSheetSearch = (payload) => ({
  type: types.SET_TIMESHEET_SEARCH,
  payload,
});
