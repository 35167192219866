import * as types from "./types";

const initialState = {
  page: 1,
  searchValue: "",
  rowsCount: 10,
  filterRole: [],
  filterStartDate: null,
};

const persistTimeSheetEvent = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_TIMESHEET_EVENT_FILTERS:
      return {
        ...state,
        ...action.payload,
      };
    case types.SET_TIMESHEET_EVENT_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    case types.SET_TIMESHEET_EVENT_SEARCH:
      return {
        ...state,
        searchValue: action.payload,
      };
    default:
      return state;
  }
};

export default persistTimeSheetEvent;
