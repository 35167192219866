import * as types from "./types";

export const initialFetchRequest = (payload) => ({
  type: types.INITIAL_FETCH_REQUEST,
  payload,
});

export const setFiltersRequest = (payload) => ({
  type: types.SET_FILTERS_REQUEST,
  payload,
});
export const setFilterStatusRequest = (payload) => ({
  type: types.SET_FILTER_STATUS_REQUEST,
  payload,
});

export const setPageRequest = (payload) => ({
  type: types.SET_PAGE_REQUEST,
  payload,
});

export const setSearchValueRequest = (payload) => ({
  type: types.SET_SEARCH_VALUE_REQUEST,
  payload,
});

export const setRowsCountRequest = (payload) => ({
  type: types.SET_ROWS_COUNT_REQUEST,
  payload,
});
