import { put, takeLatest, select, call } from "redux-saga/effects";
import * as types from "./types";
import * as actions from "./actions";
import { showErrorMsg } from "../../utils/Helpers";
import { getEventsRequest } from "../Events/actions";

function* fetchTimeSheetListSaga() {
  const {
    page,
    rowsCount,
    searchValue,
    event_type,
    event_status,
    event_start_date,
    event_end_date,
  } = yield select((state) => state.persistTimeSheet);

  yield put(
    getEventsRequest({
      page,
      items: rowsCount,
      name: searchValue,
      status: event_status?.value ?? "ongoing,past",
      payment_period: event_type?.value,
      event_start_date,
      event_end_date,
      order_by_event_start_at: "desc",
    })
  );
}

function* fetchInitialStateSaga() {
  try {
    yield call(fetchTimeSheetListSaga);
  } catch (error) {
    yield showErrorMsg(error);
  }
}

function* pageTimeSheetSaga(action) {
  try {
    const { page } = yield select((state) => state.persistTimeSheet);
    if (action.payload !== page) {
      yield put(actions.setTimeSheetPage(action.payload));
      yield call(fetchTimeSheetListSaga);
    }
  } catch (error) {
    yield showErrorMsg(error);
  }
}

function* filterTimeSheetSaga(action) {
  try {
    const _currentFilters = yield select((state) => state.persistTimeSheet);
    const _newFilters = action.payload;
    if (JSON.stringify(_currentFilters) !== JSON.stringify(_newFilters)) {
      yield put(actions.setTimeSheetFilters(_newFilters));
      yield call(fetchTimeSheetListSaga);
    }
  } catch (error) {
    yield showErrorMsg(error);
  }
}

export default function* persistTimeSheetSaga() {
  yield takeLatest(types.INITIAL_TIMESHEET_FETCH, fetchInitialStateSaga);
  yield takeLatest(types.SET_TIMESHEET_PAGE, pageTimeSheetSaga);
  yield takeLatest(types.SET_TIMESHEET_FILTERS, filterTimeSheetSaga);
}
