import { put, call, takeEvery } from "redux-saga/effects";

import * as apis from "../../network/apis/requests";
import * as types from "./types";
import * as actions from "./actions";
import { getEventRequest } from "../Events/actions";
import { showErrorMsg, showSuccessMsg } from "../../utils/Helpers";

function* getRequestsListSaga({ payload }) {
  try {
    let actionType = {
      requests: "getRequestsResponse",
      zoneApplicants: "getzoneApplicantsResponse",
      applicantsTab: "getApplicantsTabResponse",
      unassignApplicants: "getUnassignApplicantsResponse",
    };
    yield put(
      actions[actionType[payload["responseType"]]]({
        list: [],
        meta: {},
      })
    );
    const response = yield call(apis.getRequestsList, payload);
    yield put(
      actions[actionType[payload["responseType"]]]({
        list: response.data.data,
        meta: {
          currentPage: +response.headers["current-page"],
          count: +response.headers["total-count"],
          pages: +response.headers["total-pages"],
        },
      })
    );
    if (+response.headers["current-page"] > +response.headers["total-pages"]) {
      yield put(
        actions.getRequestsRequest({
          ...payload,
          page: +response.headers["total-pages"],
        })
      );
    }
  } catch (error) {
    yield showErrorMsg(error.response?.data?.message);
  }
}

function* approveRequestSaga({ payload }) {
  try {
    yield call(apis.approveRequest, payload);
    yield showSuccessMsg("approveRequest");
    yield put(actions.getEventRequestRequest(payload.listParams));
  } catch (error) {
    yield showErrorMsg(error.response?.data?.message);
  }
}

function* updateEventRequestSaga({ payload }) {
  try {
    yield call(apis.updateEventRequest, payload);
    yield showSuccessMsg("updatedSuccessfully");
    yield put(actions.getEventRequestRequest(payload.listParams));
    yield put(getEventRequest(payload.listParams.event_id));
  } catch (error) {
    yield showErrorMsg(error.response?.data?.message);
  }
}

function* removeEventRequestSaga({ payload }) {
  try {
    const response = yield call(apis.removeEventRequest, payload);
    yield showSuccessMsg(response.data?.message, true);
    yield put(actions.removeEventResponse(response.data));
    yield put(actions.getEventRequestRequest(payload.listParams));
    yield put(getEventRequest(payload.listParams.event_id));
  } catch (error) {
    yield showErrorMsg(error.response?.data?.message);
  }
}

function* updateSupervisorEventRequestSaga({ payload }) {
  try {
    yield call(apis.updateSupervisorEventRequest, payload);
    yield showSuccessMsg("updatedSuccessfully");
    yield put(actions.getEventRequestRequest(payload.listParams));
    yield put(getEventRequest(payload.listParams.event_id));
  } catch (error) {
    yield showErrorMsg(error.response?.data?.message);
  }
}

function* bulkAssignSaga({ payload }) {
  try {
    yield call(apis.bulkAssign, payload);
    yield showSuccessMsg("updatedSuccessfully");
    yield put(actions.getEventRequestRequest(payload.listParams));
  } catch (error) {
    yield showErrorMsg(error.response?.data?.message);
  }
}

function* getEventRequestListSaga({ payload }) {
  try {
    const response = yield call(apis.getEventRequestList, payload);
    yield put(
      actions.getEventRequestResponse({
        list: response.data.data,
        meta: {
          currentPage: +response.headers["current-page"],
          count: +response.headers["total-count"],
          pages: +response.headers["total-pages"],
        },
      })
    );
    if (+response.headers["current-page"] > +response.headers["total-pages"]) {
      yield put(
        actions.getEventRequestRequest({
          ...payload,
          page: +response.headers["total-pages"],
        })
      );
    }
  } catch (error) {
    yield error.response && showErrorMsg(error.response?.data?.message);
  }
}

function* updateBulkApprovedRequestSaga({ payload }) {
  try {
    const response = yield call(apis.updateBulkApprovedRequests, payload);
    yield showSuccessMsg(response.data.message, true);
    yield put(actions.getEventRequestRequest(payload.listParams));
  } catch (error) {
    yield error.response && showErrorMsg(error.response?.data?.message);
  }
}

export default function* RequestsSagas() {
  yield takeEvery(types.GET_REQUESTS_REQUEST, getRequestsListSaga);
  yield takeEvery(types.APPROVE_REQUEST, approveRequestSaga);
  yield takeEvery(types.UPDATE_EVENT_REQUEST, updateEventRequestSaga);
  yield takeEvery(types.REMOVE_EVENT_REQUEST, removeEventRequestSaga);
  yield takeEvery(
    types.UPDATE_SUPERVISOR_EVENT_REQUEST,
    updateSupervisorEventRequestSaga
  );
  yield takeEvery(types.BULK_ASSIGN, bulkAssignSaga);
  yield takeEvery(types.GET_EVENT_REQUEST_REQUEST, getEventRequestListSaga);
  yield takeEvery(
    types.BULK_APPROVED_REQUESTS_REQUEST,
    updateBulkApprovedRequestSaga
  );
}
