import React from "react";
import dayjs from "dayjs";
import "dayjs/locale/ar-sa";
import moment from "moment/moment";

import messages from "../assets/locale/messages";
import store from "../store";
import { showHideSnackbar } from "../store/Snackbar/actions";
import Tooltip from "../components/Tooltip";

const hijriMonths = [
  "Muharram",
  "Safar",
  "Rabia-Awwal",
  "Rabia-Thani",
  "Jumaada-Awal",
  "Jumaada-Thani",
  "Rajab",
  "Sha'ban",
  "Ramadan",
  "Shawwal",
  "Dhul-Qi'dah",
  "Dhul-Hijjah",
];

export const showSuccessMsg = (msg, showMsgDirectly) => {
  const lang = store.getState().locale.lang;
  const { successMsgs } = messages[lang];
  store.dispatch(
    showHideSnackbar({
      isOpen: true,
      type: "success",
      message: showMsgDirectly ? msg : successMsgs[msg],
    })
  );
};

export const showErrorMsg = (message) => {
  store.dispatch(
    showHideSnackbar({
      isOpen: true,
      type: "error",
      message,
    })
  );
};

export const renderTextEllipsis = (text = "", length = 30) => {
  if (text?.length > length) {
    const trimmedText = text.slice(0, length);
    return <Tooltip title={text} content={<span>{trimmedText}...</span>} />;
  } else {
    return text;
  }
};

export const formatDate = (date, format = "DD-MM-YYYY") =>
  dayjs(date).format(format);

export const addZeroToSingleNumber = (number) => {
  return String(number).padStart(2, "0");
};

export const getNamesInitials = (fullName) => {
  return fullName
    ? fullName
        .split(" ")
        .filter((_, i) => i < 2)
        .map((name) => name[0]?.toUpperCase())
        .join("")
    : "";
};

export const getFileName = (fileName) => {
  // let startIndex = fileName.indexOf("/files/") + "/files/".length;
  // return fileName.slice(startIndex);
  return fileName.replace(/^.*[\\/]/, "");
};

export const convertHtmlToText = (htmlString) =>
  htmlString ? htmlString.replace(/<[^>]+>/g, "") : htmlString;

export const textContainOnlySpaces = (text) =>
  text.length && /^\s*$/.test(text);

export const nonEmptyObject = (obj) => {
  let copyObj = { ...obj };
  for (const key in copyObj) {
    if (copyObj[key] === undefined || copyObj[key] === "") {
      copyObj[key] = null;
    }
  }
  return JSON.parse(JSON.stringify(copyObj));
};

export const generateUniqueSrc = (src) => {
  const timestamp = new Date().getTime();
  return `${src}?v=${timestamp}`;
};

// TODO: remove this temp fix afer dayjs issue is solved
export const checkFor12amIssue = (time) => {
  const hour11 = dayjs("11:59", "HH:mm");
  const hour13 = dayjs("13:00", "HH:mm");
  const timeObj = dayjs(time);
  let localizedTime = dayjs(time).locale("ar-sa").format("hh:mm a");
  if (timeObj.isBetween(hour11, hour13, null, "[]")) {
    const correctLocalizedTime = localizedTime.replace("ص", "م");
    return correctLocalizedTime;
  }
  return localizedTime;
};

//Covert Islamic month to Hijri Date
export const convertHijriToGregorian = (_date) => {
  if (typeof _date !== "string" || _date.trim() === "") {
    return undefined;
  }
  const [day, month, year] = _date.split(" ");
  const _month = month.replace(".", "");
  const hijriMonthIndex =
    hijriMonths.findIndex(
      (m) => m.toLowerCase() === _month.toLowerCase().trim()
    ) + 1;
  const hijriFormattedDate = `${
    +day.trim() + 1
  }-${hijriMonthIndex}-${year.trim()}`;

  return moment(hijriFormattedDate, "iD-iM-iYYYY").format("YYYY-MM-DD");
};

//Covert Hijri date to Islamic Date
export const convertGregorianToHijri = (_date) => {
  if (typeof _date !== "string" || _date.trim() === "") {
    return undefined;
  }
  const [day, month, year] = _date.split("-");
  const _month = month?.replace(/^0+/, "");
  const gregorianMonth = hijriMonths[_month];
  return `${day} ${gregorianMonth}. ${year}`;
};
export const getPageLocalStored = (key) => {
  const _get = localStorage.getItem(key);
  return Number(_get);
};

export const setPageLocalStored = (key, value) => {
  return localStorage.setItem(key, JSON.stringify(value));
};

export const isNumericValue = (value) =>
  !isNaN(value) && !isNaN(parseFloat(value));

// FOR IMAGE CONVERT TO BASE64 PPT FILE APPLICANTS
export const fetchImageAsBase64 = async (imageUrl) => {
  try {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(blob);
    });
  } catch (e) {
    console.error(e);
    throw e;
  }
};
