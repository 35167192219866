import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { useFormik, FormikProvider, FieldArray } from "formik";
import * as Yup from "yup";
import { Add, ArrowUpward } from "@mui/icons-material";
import dayjs from "dayjs";

import {
  addEventRequest,
  editEventRequest,
  showEventConflictWarningAlert,
} from "../../../../store/Events/actions";
import messages from "../../../../assets/locale/messages";
import CreateFormSectionTitle from "../../CreateFormSectionTitle";
import Upload from "../../../../components/Upload";
import Input from "../../../../components/Input";
import Button from "../../../../components/Button";
import GoogleMaps from "../../../../components/GoogleMaps";
import DateTimePicker from "../../../../components/DateTimePicker";
import RadioButtonsGroup from "../../../../components/RadioButton";
import Switch from "../../../../components/Switch";
import Modal from "../../../../components/Modal";
import ProjectManagerFields from "../ProjectManagerFields";
import { ROUTE_PATHS } from "../../../../utils/RoutesPaths";
import { uploadToS3, directories } from "../../../../utils/S3";
import Geocode from "../../../../utils/Geocode";
import { nonEmptyObject } from "../../../../utils/Helpers";
import {
  eventCheckinVerificationMethods,
  eventPublishingModes,
  eventStatuses,
  eventTypes,
} from "../../../../utils/Constants";
import { isSuperAdmin } from "../../../../utils/Permissions";
import { ONLY_POSITIVE_NUMBERS_WITH_ZERO } from "../../../../utils/Patterns";
import "../CreateEvent.scss";

const StepOne = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();

  const lang = useSelector((state) => state.locale.lang);
  const event = useSelector((state) => state.events.event);
  const createdEventId = useSelector((state) => state.routing.createdEventId);
  const activePublishMode = useSelector(
    (state) => state.routing.activePublishMode
  );
  const warningAlert = useSelector((state) => state.events.warningAlert);

  const {
    events: { creatEvent },
    shared,
  } = messages[lang];

  const [activeField, setActiveField] = useState(null);
  const [img, setImg] = useState();
  const [coords, setCoords] = useState({
    coords: null,
  });
  const [markerMoved, setMarkerMoved] = useState(false);
  const [address, setAddress] = useState("");
  // flag to upload image in edit mode if image changed only
  const [imgChanged, setImgChanged] = useState(false);
  const [submitAction, setSubmitAction] = useState();
  const [removedPMarray, setRemovedPMarray] = useState([]);
  const [isSingleDay, setIsSingleDay] = useState(false);
  const [isEventUpcoming, setIsEventUpcoming] = useState(false);
  const [isEventOngoing, setIsEventOngoing] = useState(false);

  const arrayHelpersRef = useRef(null);

  useEffect(() => {
    if (event) {
      setIsEventUpcoming(event.status === eventStatuses.upcoming);
      setIsEventOngoing(event.status === eventStatuses.ongoing);
    }
  }, [event]);

  const eventTypesOptions = [
    {
      label: creatEvent.daily,
      value: eventTypes.daily,
    },
    {
      label: creatEvent.monthly,
      value: eventTypes.monthly,
    },
  ];

  const publishingModeOptions = [
    {
      label: creatEvent.public,
      value: eventPublishingModes.public,
    },
    {
      label: creatEvent.private,
      value: eventPublishingModes.private,
    },
  ];

  const verificationMethodOptions = [
    {
      label: creatEvent.labels.basicVerification,
      value: eventCheckinVerificationMethods.basicVerification,
    },
    {
      label: creatEvent.labels.supervisorApproval,
      value: eventCheckinVerificationMethods.supervisorApproval,
    },
    {
      label: creatEvent.labels.organizerScanning,
      value: eventCheckinVerificationMethods.organizerScanning,
    },
    {
      label: creatEvent.labels.supervisorScanning,
      value: eventCheckinVerificationMethods.supervisorScanning,
    },
  ];

  const formFirstStepData = (values) => {
    let firstStepData = {};
    firstStepData.name = values.name;
    firstStepData.description = values.description;
    firstStepData.payment_period = values.eventType;
    firstStepData.is_private = values.is_private;
    firstStepData.building_start_at = values.startBuildUpStart;
    firstStepData.building_end_at = values.startBuildUpEnd;
    firstStepData.event_start_at = values.eventDayStart;
    firstStepData.event_end_at = values.eventDayEnd;
    firstStepData.dismantling_start_at = values.dismantlingDayStart;
    firstStepData.dismantling_end_at = values.dismantlingDayEnd;
    firstStepData.address = values.eventLocation;
    firstStepData.lat = values.lat;
    firstStepData.lng = values.lng;
    firstStepData.attendance_verification_method = values.verificationMethod;
    firstStepData.event_radius = values.eventRadius;

    return firstStepData;
  };

  const getInitialValues = (event) => {
    if (event) setIsSingleDay(event?.is_single_day);
    return {
      image_url: event?.image_url || "",
      name: event?.name || "",
      description: event?.description || "",
      eventType: event?.payment_period || eventTypes.daily,
      is_private: event?.is_private || false,
      startBuildUpStart: event?.building_start_at || "",
      startBuildUpEnd: event?.building_end_at || "",
      eventDayStart: event?.event_start_at || "",
      eventDayEnd: event?.event_end_at || "",
      dismantlingDayStart: event?.dismantling_start_at || "",
      dismantlingDayEnd: event?.dismantling_end_at || "",
      eventLocation: event?.address || "",
      lat: event?.lat || "",
      lng: event?.lng || "",
      verificationMethod:
        event?.attendance_verification_method ||
        eventCheckinVerificationMethods.basicVerification,
      eventRadius: event?.event_radius || "",
      projectManagers: [],
    };
  };

  const formik = useFormik({
    initialValues: getInitialValues(),
    validationSchema: Yup.object().shape(
      {
        image_url: Yup.mixed().when([], {
          is: () => activePublishMode || isEventUpcoming,
          then: Yup.mixed().required("eventImageRequired"),
          otherwise: Yup.mixed().notRequired(),
        }),
        name: Yup.string()
          .max(100, "maxHundredChars")
          .required("eventNameRequired"),
        description: Yup.string()
          .max(500, "maxFiveHundredChars")
          .required("eventBriefRequired"),
        eventType: Yup.string().when([], {
          is: () => activePublishMode || isEventUpcoming,
          then: Yup.string().required("generalFieldRequiredToPublish"),
          otherwise: Yup.string().notRequired(),
        }),
        is_private: Yup.bool(),
        startBuildUpStart: Yup.date()
          .test(
            "test start after end",
            "startBuildUpDateNotAfterEnd",
            (value) => {
              return !(
                formik.values["startBuildUpEnd"] !== "" &&
                formik.values["startBuildUpStart"] !== "" &&
                activeField === "startBuildUpStart" &&
                (dayjs(value).isSame(dayjs(formik.values["startBuildUpEnd"])) ||
                  dayjs(value).isAfter(dayjs(formik.values["startBuildUpEnd"])))
              );
            }
          )
          .when("dismantlingDayStart", {
            is: (dismantlingDayStart) =>
              dismantlingDayStart?.toDateString()?.length > 0 &&
              (activePublishMode || isEventUpcoming),
            then: Yup.date().required("generalFieldRequiredToPublish"),
            otherwise: Yup.date().notRequired(),
          })
          .when("dismantlingDayEnd", {
            is: (dismantlingDayEnd) =>
              dismantlingDayEnd?.toDateString()?.length > 0 &&
              (activePublishMode || isEventUpcoming),
            then: Yup.date().required("generalFieldRequiredToPublish"),
            otherwise: Yup.date().notRequired(),
          }),
        startBuildUpEnd: Yup.date()
          .test(
            "test end before start",
            "endBuildUpDateNotBeforeStart",
            (value) => {
              return !(
                formik.values["startBuildUpStart"] !== "" &&
                formik.values["startBuildUpEnd"] !== "" &&
                activeField === "startBuildUpEnd" &&
                (dayjs(formik.values["startBuildUpStart"]).isSame(value) ||
                  dayjs(formik.values["startBuildUpStart"]).isAfter(value))
              );
            }
          )
          .when("startBuildUpStart", {
            is: (startBuildUpStart) =>
              startBuildUpStart?.toDateString()?.length > 0 &&
              (activePublishMode || isEventUpcoming),
            then: Yup.date().required("generalFieldRequiredToPublish"),
            otherwise: Yup.date().notRequired(),
          })
          // .when(
          //   "startBuildUpStart",
          //   (startBuildUpStart, schema) =>
          //     startBuildUpStart &&
          //     schema.min(startBuildUpStart, "endBuildUpDateNotBeforeStart")
          // )
          .when("dismantlingDayStart", {
            is: (dismantlingDayStart) =>
              dismantlingDayStart?.toDateString()?.length > 0 &&
              (activePublishMode || isEventUpcoming),
            then: Yup.date().required("generalFieldRequiredToPublish"),
            otherwise: Yup.date().notRequired(),
          })
          .when("dismantlingDayEnd", {
            is: (dismantlingDayEnd) =>
              dismantlingDayEnd?.toDateString()?.length > 0 &&
              (activePublishMode || isEventUpcoming),
            then: Yup.date().required("generalFieldRequiredToPublish"),
            otherwise: Yup.date().notRequired(),
          }),
        eventDayStart: Yup.date()
          .test(
            "test start after end",
            "eventStartDateNotAfterEnd",
            (value) => {
              return !(
                formik.values["eventDayEnd"] !== "" &&
                formik.values["eventDayStart"] !== "" &&
                activeField === "eventDayStart" &&
                (dayjs(value).isSame(dayjs(formik.values["eventDayEnd"])) ||
                  dayjs(value).isAfter(dayjs(formik.values["eventDayEnd"])))
              );
            }
          )
          .when([], {
            is: () => activePublishMode || isEventUpcoming,
            then: Yup.date().required("generalFieldRequiredToPublish"),
            otherwise: Yup.date().notRequired(),
          }),
        eventDayEnd: Yup.date()
          .test(
            "test end before start",
            "eventEndDateNotBeforeStart",
            (value) => {
              return !(
                formik.values["eventDayStart"] !== "" &&
                formik.values["eventDayEnd"] !== "" &&
                activeField === "eventDayEnd" &&
                (dayjs(formik.values["eventDayStart"]).isAfter(dayjs(value)) ||
                  dayjs(formik.values["eventDayStart"]).isSame(dayjs(value)))
              );
            }
          )
          // .when(
          //   "eventDayStart",
          //   (eventDayStart, schema) =>
          //     eventDayStart &&
          //     schema.min(eventDayStart, "eventEndDateNotBeforeStart")
          // )
          .when([], {
            is: () => activePublishMode || isEventUpcoming || isEventOngoing,
            then: Yup.date().required("generalFieldRequiredToPublish"),
            otherwise: Yup.date().notRequired(),
          }),
        dismantlingDayStart: Yup.date()
          .test(
            "test start after end",
            "dismantlingStartDateNotAfterEnd",
            (value) => {
              return !(
                formik.values["dismantlingDayEnd"] !== "" &&
                formik.values["dismantlingDayStart"] !== "" &&
                activeField === "dismantlingDayStart" &&
                (dayjs(value).isSame(
                  dayjs(formik.values["dismantlingDayEnd"])
                ) ||
                  dayjs(value).isAfter(
                    dayjs(formik.values["dismantlingDayEnd"])
                  ))
              );
            }
          )
          .when("startBuildUpStart", {
            is: (startBuildUpStart) =>
              startBuildUpStart?.toDateString()?.length > 0 &&
              (activePublishMode || isEventUpcoming),
            then: Yup.date().required("generalFieldRequiredToPublish"),
            otherwise: Yup.date().notRequired(),
          })
          .when("startBuildUpEnd", {
            is: (startBuildUpEnd) =>
              startBuildUpEnd?.toDateString()?.length > 0 &&
              (activePublishMode || isEventUpcoming),
            then: Yup.date().required("generalFieldRequiredToPublish"),
            otherwise: Yup.date().notRequired(),
          }),
        dismantlingDayEnd: Yup.date()
          .test(
            "test end before start",
            "dismantlingEndtDateNotBeforeStart",
            (value) => {
              return !(
                formik.values["dismantlingDayStart"] !== "" &&
                formik.values["dismantlingDayEnd"] !== "" &&
                activeField === "dismantlingDayEnd" &&
                (dayjs(formik.values["dismantlingDayStart"]).isAfter(
                  dayjs(value)
                ) ||
                  dayjs(formik.values["dismantlingDayStart"]).isSame(
                    dayjs(value)
                  ))
              );
            }
          )
          .when("startBuildUpStart", {
            is: (startBuildUpStart) =>
              startBuildUpStart?.toDateString()?.length > 0 &&
              (activePublishMode || isEventUpcoming),
            then: Yup.date().required("generalFieldRequiredToPublish"),
            otherwise: Yup.date().notRequired(),
          })
          .when("startBuildUpEnd", {
            is: (startBuildUpEnd) =>
              startBuildUpEnd?.toDateString()?.length > 0 &&
              (activePublishMode || isEventUpcoming),
            then: Yup.date().required("generalFieldRequiredToPublish"),
            otherwise: Yup.date().notRequired(),
          })
          .when("dismantlingDayStart", {
            is: (dismantlingDayStart) =>
              dismantlingDayStart?.toDateString()?.length > 0 &&
              (activePublishMode || isEventUpcoming),
            then: Yup.date().required("generalFieldRequiredToPublish"),
            otherwise: Yup.date().notRequired(),
          }),
        // .when(
        //   "dismantlingDayStart",
        //   (dismantlingDayStart, schema) =>
        //     dismantlingDayStart &&
        //     schema.min(
        //       dismantlingDayStart,
        //       "dismantlingEndtDateNotBeforeStart"
        //     )
        // ),
        eventLocation: Yup.string().when([], {
          is: () => activePublishMode || isEventUpcoming,
          then: Yup.string().required("generalFieldRequiredToPublish"),
          otherwise: Yup.string().notRequired(),
        }),
        lat: Yup.string().when([], {
          is: () => activePublishMode || isEventUpcoming,
          then: Yup.string().required("generalFieldRequiredToPublish"),
          otherwise: Yup.string().notRequired(),
        }),
        lng: Yup.string().when([], {
          is: () => activePublishMode || isEventUpcoming,
          then: Yup.string().required("generalFieldRequiredToPublish"),
          otherwise: Yup.string().notRequired(),
        }),
        verificationMethod: Yup.string().when([], {
          is: () => activePublishMode || isEventUpcoming,
          then: Yup.string().required("verificationMethodRequired"),
          otherwise: Yup.string().notRequired(),
        }),
        eventRadius: Yup.string()
          .matches(ONLY_POSITIVE_NUMBERS_WITH_ZERO, "onlyPositiveNumber")
          .when([], {
            is: () => activePublishMode || isEventUpcoming,
            then: Yup.string().required("generalFieldRequiredToPublish"),
            otherwise: Yup.string().notRequired(),
          }),
        projectManagers: Yup.array().of(
          Yup.object().shape({
            admin_id: Yup.mixed().when([], {
              is: () => activePublishMode || isEventUpcoming,
              then: Yup.mixed().required("generalFieldRequiredToPublish"),
              otherwise: Yup.mixed().notRequired(),
            }),
            // responsibility: Yup.string().when([], {
            //   is: () => activePublishMode,
            //   then: Yup.string().required("generalFieldRequiredToPublish"),
            //   otherwise: Yup.string().nullable(),
            // }),
            responsibility: Yup.string().nullable(),
          })
        ),
      },
      [
        ["startBuildUpStart", "dismantlingDayEnd"],
        ["startBuildUpStart", "dismantlingDayStart"],
        ["startBuildUpEnd", "startBuildUpStart"],
        ["startBuildUpEnd", "dismantlingDayEnd"],
        ["startBuildUpEnd", "dismantlingDayStart"],
        ["dismantlingDayStart", "dismantlingDayEnd"],
      ]
    ),
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      let firstStepData = formFirstStepData(values);
      firstStepData.is_single_day = isSingleDay;

      if (values.image_url) {
        if (imgChanged) {
          const imgExt = values.image_url.name.split(".").pop();
          const imgName = `event_image_${new Date().getTime()}.${imgExt}`;
          const data = await uploadToS3(
            values.image_url,
            // // unique file name to avoid conflicts and without spaces as it makes errors
            // `${new Date().getTime()}_${values.image_url.name
            //   .replace(/\s/g, "")
            //   .replace(/(\(|\))/g, "")}`,
            imgName,
            directories.events
          );
          firstStepData.image_url = data?.link;
        } else {
          firstStepData.image_url = values.image_url;
        }
      } else {
        firstStepData.image_url = null;
      }
      let filteredProjectManagers = [];
      if (values.projectManagers.length) {
        filteredProjectManagers = values.projectManagers.filter(
          (elt) => !!elt.admin_id
        );
      }
      firstStepData.admin_events_attributes = [
        ...filteredProjectManagers,
        ...removedPMarray,
      ];

      if (id) {
        dispatch(
          editEventRequest({
            data: {
              ...nonEmptyObject(JSON.parse(JSON.stringify(firstStepData))),
              expected_number_of_conflicting_resources: warningAlert.count,
            },
            id: id,
            nextStep: submitAction === "continue" ? 1 : "view-event",
            validate:
              [eventStatuses.upcoming, eventStatuses.ongoing].includes(
                event?.status
              ) && !warningAlert.show,
          })
        );
      } else if (createdEventId) {
        dispatch(
          editEventRequest({
            data: {
              ...nonEmptyObject(JSON.parse(JSON.stringify(firstStepData))),
              expected_number_of_conflicting_resources: warningAlert.count,
            },
            id: createdEventId,
            nextStep: submitAction === "continue" ? 1 : "events",
            validate:
              [eventStatuses.upcoming, eventStatuses.ongoing].includes(
                event?.status
              ) && !warningAlert.show,
          })
        );
      } else {
        dispatch(
          addEventRequest({
            data: nonEmptyObject(JSON.parse(JSON.stringify(firstStepData))),
            nextStep: submitAction === "continue" ? 1 : "events",
          })
        );
      }
    },
  });

  const onAddProjectManagerBtnClick = () => {
    arrayHelpersRef.current.push({
      admin_id: null,
      responsibility: null,
    });
  };

  useEffect(() => {
    if (
      !formik.values.projectManagers.length &&
      !event?.project_managers?.length
    ) {
      const addPm = setTimeout(() => {
        onAddProjectManagerBtnClick();
      }, 200);
      return () => {
        clearTimeout(addPm);
      };
    }
  }, [formik.values.projectManagers.length, event?.project_managers?.length]);

  const getLatLng = (address) => {
    Geocode.fromAddress(address).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        formik.setFieldValue("lat", lat);
        formik.setFieldValue("lng", lng);
      },
      (error) => {
        console.log("error", error);
      }
    );
  };
  const getAddress = (lat, long) => {
    Geocode.fromLatLng(lat, long).then(
      (response) => {
        const address = response.results[0].formatted_address;
        setAddress(address);
      },
      (error) => {
        console.error(error);
        setAddress("");
      }
    );
  };

  useEffect(() => {
    if (coords.coords && markerMoved) {
      getAddress(coords.coords?.lat, coords.coords?.lng);
      // formik.setFieldTouched("eventLocation");
      formik.setFieldValue("eventLocation", address);
      formik.setFieldValue("lat", coords.coords?.lat);
      formik.setFieldValue("lng", coords.coords?.lng);
    }
  }, [coords, markerMoved, address, event, id]);

  useEffect(() => {
    formik.setValues(getInitialValues(event));

    if (!event?.project_managers?.length == 0) {
      let projectManagersArray = event?.project_managers?.map((manager) => {
        return {
          id: manager.id,
          admin_id: manager.admin.id,
          responsibility: manager.responsibility,
        };
      });
      formik.setFieldValue("projectManagers", projectManagersArray);
    }
    if (event && activePublishMode) addPublishValidations();
  }, [event]);

  const addPublishValidations = () => {
    if (
      !event.image_url ||
      !event.payment_period ||
      (!event.building_start_at &&
        !!(
          event?.building_end_at ||
          event?.dismantling_start_at ||
          event?.dismantling_end_at
        )) ||
      (!event.building_end_at &&
        !!(
          event?.building_start_at ||
          event?.dismantling_start_at ||
          event?.dismantling_end_at
        )) ||
      !event.event_start_at ||
      !event.event_end_at ||
      (!event.dismantling_start_at &&
        !!(
          event?.dismantling_end_at ||
          event?.building_start_at ||
          event?.building_end_at
        )) ||
      (!event.dismantling_end_at &&
        !!(
          event?.dismantling_start_at ||
          event?.building_start_at ||
          event?.building_end_at
        )) ||
      !event.address ||
      !event.lat ||
      !event.lng ||
      !event.attendance_verification_method ||
      !event.event_radius ||
      !event?.project_managers?.length
    ) {
      formik.setTouched({
        ...formik.touched,
        image_url: !event.image_url,
        eventType: !event.payment_period,
        startBuildUpStart:
          !event.building_start_at &&
          !!(
            event?.building_end_at ||
            event?.dismantling_start_at ||
            event?.dismantling_end_at
          ),
        startBuildUpEnd:
          !event.building_end_at &&
          !!(
            event?.building_start_at ||
            event?.dismantling_start_at ||
            event?.dismantling_end_at
          ),
        eventDayStart: !event.event_start_at,
        eventDayEnd: !event.event_end_at,
        dismantlingDayStart:
          !event.dismantling_start_at &&
          !!(
            event?.dismantling_end_at ||
            event?.building_start_at ||
            event?.building_end_at
          ),
        dismantlingDayEnd:
          !event.dismantling_end_at &&
          !!(
            event?.dismantling_start_at ||
            event?.building_start_at ||
            event?.building_end_at
          ),
        eventLocation: !event.address,
        lat: !event.lat,
        lng: !event.lng,
        verificationMethod: !event.attendance_verification_method,
        eventRadius: !event.event_radius,
      });

      if (!event?.project_managers?.length) {
        formik.setFieldTouched("projectManagers[0].admin_id");
      }
    }
  };

  const handleCancelSubmit = () => {
    dispatch(
      showEventConflictWarningAlert({
        show: false,
        message: "",
        count: 0,
      })
    );
  };

  return (
    <div className="form-wrapper">
      <FormikProvider value={formik}>
        <form noValidate>
          <div className="mb-4 step-part-content-wrapper">
            <div className="mb-4 d-flex justify-content-between top-wrapper">
              <CreateFormSectionTitle title={creatEvent.basicInfo} />
            </div>

            <Upload
              fieldLabel={creatEvent.labels.eventImage}
              label={
                <div className="pt-1 d-flex flex-column align-items-center justify-content-center event-image-upload-initial-container">
                  <div className="d-flex justify-content-center align-items-center center-uplod-btn">
                    <ArrowUpward fontSize="small" />
                    <p className=" mb-0 text-center w-fit mx-2">
                      {creatEvent.labels.uploadLabel}
                    </p>
                  </div>
                </div>
              }
              onChange={(img, value) => {
                setImg(img);
                setImgChanged(true);
                formik.setFieldTouched("image_url");
                formik.setFieldValue("image_url", value);
              }}
              img={img || formik.values["image_url"]}
              name="image_url"
              isFullWidthImage={true}
              className="mt-3 min-height-image"
              fullWidthImageClassName="event-image-upload-container"
              wrapperClasses="mege-upload-container"
              isImage
              isBluredBackground
              disabled={
                id &&
                [
                  eventStatuses.ongoing,
                  eventStatuses.cancelled,
                  eventStatuses.past,
                ].includes(event?.status)
              }
              required={activePublishMode || isEventUpcoming}
              errorMsg={formik.errors["image_url"]}
              isInputHasErr={
                !!(formik.touched["image_url"] && formik.errors["image_url"])
              }
            />
            <Input
              id="name"
              name="name"
              label={creatEvent.labels.eventName}
              type="text"
              placeholder={creatEvent.placeholders.eventName}
              required
              onChange={(value) => {
                formik.setFieldTouched("name");
                formik.setFieldValue("name", value);
              }}
              value={formik.values["name"]}
              inputClass="mb-1"
              labelClassName="font-medium mb-2 text-primary field-label"
              inputWrapperClass="create-event-input"
              isInputHasErr={
                !!(formik.touched["name"] && formik.errors["name"])
              }
              errMsg={formik.errors["name"]}
              disabled={
                (id &&
                  [
                    eventStatuses.cancelled,
                    eventStatuses.past,
                    eventStatuses.upcoming,
                  ].includes(event?.status)) ||
                !isSuperAdmin()
              }
            />

            <Input
              id="description"
              name="description"
              label={creatEvent.labels.eventBrief}
              type="text"
              placeholder={creatEvent.placeholders.eventBrief}
              required
              onChange={(value) => {
                formik.setFieldTouched("description");
                formik.setFieldValue("description", value);
              }}
              value={formik.values["description"]}
              inputClass="mb-1"
              labelClassName="font-medium mb-2 text-primary field-label"
              inputWrapperClass="create-event-input"
              isInputHasErr={
                !!(
                  formik.touched["description"] && formik.errors["description"]
                )
              }
              errMsg={formik.errors["description"]}
              disabled={
                id &&
                [
                  eventStatuses.cancelled,
                  eventStatuses.past,
                ].includes(event?.status)
              }
            />
            <div className="row">
              <div className="col-md-6">
                <RadioButtonsGroup
                  name="eventType"
                  id="eventType"
                  label={creatEvent.labels.eventType}
                  className={`d-flex flex-row`}
                  labelClassName="font-medium mb-1 text-primary"
                  containerClassName="create-event-input mt-3"
                  wrapperClassName="w-100"
                  radioBtnClass="w-50"
                  listOfRadios={eventTypesOptions}
                  value={formik.values.eventType}
                  handleChange={(e) => {
                    formik.setFieldTouched("eventType");
                    formik.setFieldValue("eventType", e.target.value);
                  }}
                  required={activePublishMode || isEventUpcoming}
                  isInputHasErr={
                    !!(
                      formik.touched["eventType"] && formik.errors["eventType"]
                    )
                  }
                  errMsg={formik.errors["eventType"]}
                  disabled={
                    id &&
                    [
                      eventStatuses.ongoing,
                      eventStatuses.cancelled,
                      eventStatuses.past,
                      eventStatuses.upcoming,
                    ].includes(event?.status)
                  }
                />
              </div>
              <div className="col-md-6 d-flex align-items-end mb-2">
                <Switch
                  label={creatEvent.singleDayEvent}
                  checked={isSingleDay}
                  onChange={(isChecked) => setIsSingleDay(isChecked)}
                  name="is-single-day"
                  id="is-single-day"
                  labelClass="fsize-16"
                  formLabelClass="m-0"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <RadioButtonsGroup
                  name="is_private"
                  id="is_private"
                  label={creatEvent.labels.publishingMode}
                  className={`d-flex flex-row`}
                  labelClassName="font-medium mb-1 text-primary"
                  containerClassName="create-event-input mt-3"
                  wrapperClassName="w-100"
                  radioBtnClass="w-50"
                  listOfRadios={publishingModeOptions}
                  value={
                    formik.values.is_private
                      ? eventPublishingModes.private
                      : eventPublishingModes.public
                  }
                  handleChange={(e) => {
                    formik.setFieldTouched("is_private");
                    formik.setFieldValue(
                      "is_private",
                      e.target.value === eventPublishingModes.private
                    );
                  }}
                  required={activePublishMode}
                  isInputHasErr={
                    !!(
                      formik.touched["is_private"] &&
                      formik.errors["is_private"]
                    )
                  }
                  errMsg={formik.errors["is_private"]}
                  disabled={
                    id &&
                    [
                      eventStatuses.ongoing,
                      eventStatuses.cancelled,
                      eventStatuses.past,
                      eventStatuses.upcoming,
                    ].includes(event?.status)
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <DateTimePicker
                  value={formik.values["startBuildUpStart"]}
                  onChange={(value) => {
                    setActiveField("startBuildUpStart");
                    formik.setFieldTouched("startBuildUpStart");
                    formik.setFieldValue("startBuildUpStart", value);
                  }}
                  name="startBuildUpStart"
                  disablePast={true}
                  label={creatEvent.labels.startBuildUpStart}
                  maxDate={formik.values["startBuildUpEnd"]}
                  placeholder={creatEvent.placeholders.startBuildUpStart}
                  isInputHasErr={
                    // formik.values["startBuildUpEnd"] !== "" &&
                    // formik.values["startBuildUpStart"] >
                    //   formik.values["startBuildUpEnd"]
                    !!(
                      formik.touched["startBuildUpStart"] &&
                      formik.errors["startBuildUpStart"]
                    )
                  }
                  shouldNotBeLessThanToday
                  errMsg={formik.errors["startBuildUpStart"]}
                  disabled={
                    id &&
                    [
                      eventStatuses.ongoing,
                      eventStatuses.cancelled,
                      eventStatuses.past,
                    ].includes(event?.status)
                  }
                  required={
                    ((!!formik.values["startBuildUpEnd"] ||
                      !!formik.values["dismantlingDayStart"] ||
                      !!formik.values["dismantlingDayEnd"]) &&
                      !formik.values["startBuildUpStart"] &&
                      (activePublishMode || isEventUpcoming)) ||
                    (formik.values["startBuildUpStart"] &&
                      (activePublishMode || isEventUpcoming))
                  }
                  onOpen={() => setActiveField("startBuildUpStart")}
                  onClose={() => setActiveField(null)}
                />
              </div>
              <div className="col-md-6">
                <DateTimePicker
                  value={formik.values["startBuildUpEnd"]}
                  onChange={(value) => {
                    setActiveField("startBuildUpEnd");
                    formik.setFieldTouched("startBuildUpEnd");
                    formik.setFieldValue("startBuildUpEnd", value);
                  }}
                  name="startBuildUpEnd"
                  disablePast={true}
                  label={creatEvent.labels.startBuildUpEnd}
                  minDate={formik.values["startBuildUpStart"]}
                  placeholder={creatEvent.placeholders.startBuildUpEnd}
                  isInputHasErr={
                    // formik.values["startBuildUpEnd"] !== "" &&
                    // formik.values["startBuildUpStart"] >
                    //   formik.values["startBuildUpEnd"]
                    !!(
                      formik.touched["startBuildUpEnd"] &&
                      formik.errors["startBuildUpEnd"]
                    )
                  }
                  shouldNotBeLessThanToday
                  errMsg={formik.errors["startBuildUpEnd"]}
                  disabled={
                    id &&
                    [
                      eventStatuses.ongoing,
                      eventStatuses.cancelled,
                      eventStatuses.past,
                    ].includes(event?.status)
                  }
                  required={
                    ((!!formik.values["startBuildUpStart"] ||
                      !!formik.values["dismantlingDayStart"] ||
                      !!formik.values["dismantlingDayEnd"]) &&
                      !formik.values["startBuildUpEnd"] &&
                      (activePublishMode || isEventUpcoming)) ||
                    (formik.values["startBuildUpEnd"] &&
                      (activePublishMode || isEventUpcoming))
                  }
                  onOpen={() => setActiveField("startBuildUpEnd")}
                  onClose={() => setActiveField(null)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <DateTimePicker
                  value={formik.values["eventDayStart"]}
                  onChange={(value) => {
                    setActiveField("eventDayStart");
                    formik.setFieldTouched("eventDayStart");
                    formik.setFieldValue("eventDayStart", value);
                  }}
                  name="eventDayStart"
                  disablePast={true}
                  label={creatEvent.labels.eventDayStart}
                  maxDate={formik.values["eventDayEnd"]}
                  placeholder={creatEvent.placeholders.eventDayStart}
                  shouldNotBeLessThanToday
                  isInputHasErr={
                    // formik.values["eventDayEnd"] !== "" &&
                    // formik.values["eventDayStart"] >
                    //   formik.values["eventDayEnd"]
                    !!(
                      formik.touched["eventDayStart"] &&
                      formik.errors["eventDayStart"]
                    )
                  }
                  errMsg={formik.errors["eventDayStart"]}
                  disabled={
                    id &&
                    [
                      eventStatuses.ongoing,
                      eventStatuses.cancelled,
                      eventStatuses.past,
                    ].includes(event?.status)
                  }
                  required={activePublishMode || isEventUpcoming}
                  onOpen={() => setActiveField("eventDayStart")}
                  onClose={() => setActiveField(null)}
                />
              </div>
              <div className="col-md-6">
                <DateTimePicker
                  value={formik.values["eventDayEnd"]}
                  onChange={(value) => {
                    setActiveField("eventDayEnd");
                    formik.setFieldTouched("eventDayEnd");
                    formik.setFieldValue("eventDayEnd", value);
                  }}
                  name="eventDayEnd"
                  disablePast={true}
                  label={creatEvent.labels.eventDayEnd}
                  minDate={formik.values["eventDayStart"]}
                  placeholder={creatEvent.placeholders.eventDayEnd}
                  isInputHasErr={
                    // formik.values["eventDayEnd"] !== "" &&
                    // formik.values["eventDayStart"] >
                    //   formik.values["eventDayEnd"]
                    !!(
                      formik.touched["eventDayEnd"] &&
                      formik.errors["eventDayEnd"]
                    )
                  }
                  shouldNotBeLessThanToday
                  errMsg={formik.errors["eventDayEnd"]}
                  required={
                    activePublishMode || isEventUpcoming || isEventOngoing
                  }
                  onOpen={() => setActiveField("eventDayEnd")}
                  onClose={() => setActiveField(null)}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <DateTimePicker
                  value={formik.values["dismantlingDayStart"]}
                  onChange={(value) => {
                    setActiveField("dismantlingDayStart");
                    formik.setFieldTouched("dismantlingDayStart");
                    formik.setFieldValue("dismantlingDayStart", value);
                  }}
                  name="dismantlingDayStart"
                  disablePast={true}
                  label={creatEvent.labels.dismantlingDayStart}
                  maxDate={formik.values["dismantlingDayEnd"]}
                  placeholder={creatEvent.placeholders.dismantlingDayStart}
                  isInputHasErr={
                    // formik.values["dismantlingDayEnd"] !== "" &&
                    // formik.values["dismantlingDayStart"] >
                    //   formik.values["dismantlingDayEnd"]
                    !!(
                      formik.touched["dismantlingDayStart"] &&
                      formik.errors["dismantlingDayStart"]
                    )
                  }
                  shouldNotBeLessThanToday
                  errMsg={formik.errors["dismantlingDayStart"]}
                  required={
                    ((!!formik.values["dismantlingDayEnd"] ||
                      !!formik.values["startBuildUpEnd"] ||
                      !!formik.values["startBuildUpStart"]) &&
                      !formik.values["dismantlingDayStart"] &&
                      (activePublishMode || isEventUpcoming)) ||
                    (formik.values["dismantlingDayStart"] &&
                      (activePublishMode || isEventUpcoming))
                  }
                  onOpen={() => setActiveField("dismantlingDayStart")}
                  onClose={() => setActiveField(null)}
                />
              </div>
              <div className="col-md-6">
                <DateTimePicker
                  value={formik.values["dismantlingDayEnd"]}
                  onChange={(value) => {
                    setActiveField("dismantlingDayEnd");
                    formik.setFieldTouched("dismantlingDayEnd");
                    formik.setFieldValue("dismantlingDayEnd", value);
                  }}
                  name="dismantlingDayEnd"
                  disablePast={true}
                  label={creatEvent.labels.dismantlingDayEnd}
                  minDate={formik.values["dismantlingDayStart"]}
                  placeholder={creatEvent.placeholders.dismantlingDayEnd}
                  isInputHasErr={
                    // formik.values["dismantlingDayEnd"] !== "" &&
                    // formik.values["dismantlingDayStart"] >
                    //   formik.values["dismantlingDayEnd"]
                    !!(
                      formik.touched["dismantlingDayEnd"] &&
                      formik.errors["dismantlingDayEnd"]
                    )
                  }
                  shouldNotBeLessThanToday
                  errMsg={formik.errors["dismantlingDayEnd"]}
                  required={
                    ((!!formik.values["dismantlingDayStart"] ||
                      !!formik.values["startBuildUpEnd"] ||
                      !!formik.values["startBuildUpStart"]) &&
                      !formik.values["dismantlingDayEnd"] &&
                      (activePublishMode || isEventUpcoming)) ||
                    (formik.values["dismantlingDayEnd"] &&
                      (activePublishMode || isEventUpcoming))
                  }
                  onOpen={() => setActiveField("dismantlingDayEnd")}
                  onClose={() => setActiveField(null)}
                />
              </div>
            </div>

            <div className="pb-3 location-container">
              <div className="row">
                <div className="col-md-6 col-12">
                  <Input
                    id="eventLocation"
                    name="eventLocation"
                    label={creatEvent.labels.eventLocation}
                    type="text"
                    placeholder={creatEvent.placeholders.eventLocation}
                    onChange={(value) => {
                      getLatLng(value);
                      formik.setFieldTouched("eventLocation");
                      formik.setFieldValue("eventLocation", value);
                    }}
                    value={formik.values["eventLocation"]}
                    inputClass="mb-1"
                    labelClassName="font-medium mb-2 text-primary field-label"
                    inputWrapperClass="create-event-input"
                    isInputHasErr={
                      !!(
                        formik.touched["eventLocation"] &&
                        formik.errors["eventLocation"]
                      )
                    }
                    errMsg={formik.errors["eventLocation"]}
                    disabled={
                      id &&
                      [
                        eventStatuses.cancelled,
                        eventStatuses.past,
                      ].includes(event?.status)
                    }
                    required={activePublishMode || isEventUpcoming}
                  />
                </div>
                <div className="col-md-6 col-12">
                  <Input
                    id="eventRadius"
                    name="eventRadius"
                    label={creatEvent.labels.diameter}
                    type="text"
                    placeholder={creatEvent.placeholders.diameter}
                    onChange={(value) => {
                      formik.setFieldTouched("eventRadius");
                      formik.setFieldValue("eventRadius", value);
                    }}
                    value={formik.values["eventRadius"]}
                    inputClass="mb-1"
                    labelClassName="font-medium mb-2 text-primary field-label"
                    inputWrapperClass="create-event-input"
                    isInputHasErr={
                      !!(
                        formik.touched["eventRadius"] &&
                        formik.errors["eventRadius"]
                      )
                    }
                    errMsg={formik.errors["eventRadius"]}
                    disabled={
                      id &&
                      [
                        eventStatuses.cancelled,
                        eventStatuses.past,
                      ].includes(event?.status)
                    }
                    required={activePublishMode || isEventUpcoming}
                  />
                </div>
                <div className="col-12">
                  <GoogleMaps
                    getCoords={(position) => {
                      setCoords(position);
                    }}
                    className="my-3"
                    isMarker={true}
                    showCircle={!!formik.values.eventRadius}
                    circleRadius={
                      formik.values.eventRadius ? +formik.values.eventRadius : 0
                    }
                    wrapperClasses="min-height-map mb-4"
                    markerMoved={() => {
                      setMarkerMoved(true);
                    }}
                    updatedPosition={{
                      coords: {
                        lng: formik.values["lng"],
                        lat: formik.values["lat"],
                      },
                    }}
                    savedCoords={
                      event?.lat &&
                      event?.lng && {
                        coords: { lng: event?.lng, lat: event?.lat },
                      }
                    }
                    viewOnly={
                      id &&
                      [
                        eventStatuses.cancelled,
                        eventStatuses.past,
                      ].includes(event?.status)
                    }
                  />
                </div>
              </div>
              {(activePublishMode || isEventUpcoming) &&
                !(formik.values.lat && formik.values.lng) && (
                  <div className="err">{creatEvent.selectLocationOnMap}</div>
                )}
            </div>
          </div>
          <div className="mb-4 step-part-content-wrapper">
            <div className="mb-4 d-flex justify-content-between top-wrapper">
              <CreateFormSectionTitle title={creatEvent.verificationMethod} />
            </div>
            <RadioButtonsGroup
              name="verificationMethod"
              id="verificationMethod"
              listOfRadios={verificationMethodOptions}
              value={formik.values.verificationMethod}
              handleChange={(e) => {
                formik.setFieldTouched("verificationMethod");
                formik.setFieldValue("verificationMethod", e.target.value);
              }}
              required={activePublishMode || isEventUpcoming}
              labelClassName="d-none"
              isInputHasErr={
                !!(
                  formik.touched["verificationMethod"] &&
                  formik.errors["verificationMethod"]
                )
              }
              errMsg={formik.errors["verificationMethod"]}
              disabled={
                id &&
                [
                  eventStatuses.cancelled,
                  eventStatuses.past,
                ].includes(event?.status)
              }
            />
          </div>
          <div className="mb-4 step-part-content-wrapper">
            <div className="mb-4 d-flex justify-content-between top-wrapper">
              <CreateFormSectionTitle title={creatEvent.projectManagers} />
              {(id &&
                [
                  eventStatuses.ongoing,
                  eventStatuses.cancelled,
                  eventStatuses.past,
                ].includes(event?.status)) ||
              (id && !isSuperAdmin()) ? (
                <></>
              ) : (
                <Button
                  label={
                    <>
                      <Add />
                      {creatEvent.add}
                    </>
                  }
                  labelClass="px-2 fsize-16 font-medium"
                  outlined
                  className="add-btn"
                  onClick={() => {
                    onAddProjectManagerBtnClick();
                  }}
                />
              )}
            </div>
            <div className="dynamic-added-data-container ">
              <FieldArray
                name="projectManagers"
                render={(arrayHelpers) => {
                  arrayHelpersRef.current = arrayHelpers;

                  return formik.values.projectManagers?.map(
                    (projectManager, index) => (
                      <ProjectManagerFields
                        id={`projectManager-${index}`}
                        key={`projectManager-${index}`}
                        projectManagersCount={
                          formik.values.projectManagers?.length
                        }
                        projectManager={projectManager}
                        removeClickFunction={(removeField) => {
                          if (projectManager.id) {
                            setRemovedPMarray([
                              ...removedPMarray,
                              { ...projectManager, _destroy: true },
                            ]);
                          }
                          removeField && arrayHelpers.remove(index);
                        }}
                        formik={formik}
                        index={index}
                        isEventUpcoming={isEventUpcoming}
                      />
                    )
                  );
                }}
              />
              {/* {activePublishMode &&
                formik.values.projectManagers?.length == 0 && (
                  <div className="err fsize-20 d-flex justify-content-center my-4 px-3">
                    {creatEvent.addAtListOnePm}
                  </div>
                )} */}

              {id &&
                [eventStatuses.upcoming].includes(event?.status) &&
                formik.values.projectManagers?.length == 0 && (
                  <div className="err fsize-20 d-flex justify-content-center my-4 px-3">
                    {creatEvent.addAtListOnePmEditMode}
                  </div>
                )}
            </div>
          </div>
          <div className="d-flex justify-content-end align-items-center buttom-btns-container">
            {!activePublishMode && (
              <Button
                type="button"
                onClick={() => {
                  setSubmitAction("saveLater");
                  formik.handleSubmit();
                }}
                label={id ? creatEvent.save : creatEvent.saveLater}
                disabled={
                  (id &&
                    [eventStatuses.upcoming].includes(event?.status) &&
                    formik.values.projectManagers?.length == 0) ||
                  !formik.isValid
                }
                outlined
                className="mx-2 px-4 py-1  save-later-btn"
              />
            )}

            {id && (
              <Button
                type="button"
                onClick={() => {
                  history.push(ROUTE_PATHS["viewEvent"].replace(":id", id));
                }}
                label={creatEvent.cancel}
                outlined
                className="mx-2 px-4 py-1 cancel"
              />
            )}
            <Button
              type="button"
              onClick={() => {
                setSubmitAction("continue");
                formik.handleSubmit();
              }}
              label={id ? creatEvent.saveContinue : creatEvent.continue}
              labelClass="px-4"
              disabled={
                (id &&
                  [eventStatuses.upcoming].includes(event?.status) &&
                  formik.values.projectManagers?.length == 0) ||
                !formik.isValid
              }
              className="mx-2 py-1 continue-btn"
            />
          </div>
        </form>
      </FormikProvider>
      <Modal
        open={warningAlert.show}
        content={warningAlert.message}
        confirmBtnTxt={shared.confirm}
        cancelBtnTxt={shared.cancel}
        handleConfirm={() => formik.handleSubmit()}
        handleClose={handleCancelSubmit}
        handleCancel={handleCancelSubmit}
      />
    </div>
  );
};

export default StepOne;
