import * as types from "./types";

const initialState = {
  page: 1,
  rowCount: 10,
  searchValue: "",
};

const persistEmployee = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_EMPLOYEE_PAGE:
      return {
        ...state,
        page: action.payload,
      };

    case types.SET_EMPLOYEE_SEARCH:
      return {
        ...state,
        searchValue: action.payload,
      };

    default:
      return state;
  }
};
export default persistEmployee;
