import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { TableCell, TableRow } from "@mui/material";
import { Block } from "@mui/icons-material";

import Breadcrumbs from "../../components/BreadCrumbs";
import Table from "../../components/Table";
import Pagination from "../../components/Pagination";
import EmptyState from "../../components/EmptyState";
import Filters from "../../components/Filter";
import messages from "../../assets/locale/messages";
import { eventTypes, lookupsKeys } from "../../utils/Constants";
import { getLookupsRequest } from "../../store/Lookups/actions";
import emptyPlaceholder from "../../assets/images/timesheet-screen/empty-report-placeholder.svg";
import { textContainOnlySpaces, renderTextEllipsis } from "../../utils/Helpers";
import { ROUTE_PATHS } from "../../utils/RoutesPaths";
import { ExcelIcon } from "../../utils/Icons";
import { exportExcelResponse } from "../../store/TimeSheet/actions";
import Button from "../../components/Button";
import SelectExportedColumnsModal from "./SelectExportedColumnsModal";
import "./TimeSheet.scss";
import {
  initialTimeSheetEventFetch,
  setTimeSheetEventFilters,
  setTimeSheetEventPage,
  setTimeSheetEventSearch,
} from "../../store/PersistTimeSheetEvent/actions";

const MonthlyReport = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { eventid } = useParams();
  const linkRef = useRef(null);

  const lang = useSelector((state) => state.locale.lang);
  const { timeSheet, shared } = messages[lang];
  const isLoading = useSelector((state) => state.loader);
  const rolesList = useSelector((state) => state.lookups.general.roles) || [];
  const list = useSelector((state) => state.timesheet.list);
  const meta = useSelector((state) => state.timesheet.meta);
  const excelLink = useSelector((state) => state.timesheet.excelLink);
  const persistStateEvent = useSelector((state) => state.persistTimeSheetEvent);

  const [q, setQuery] = useState("");
  const [openModal, setOpenModal] = useState(false);

  let location = useLocation();

  const getFilterValues = () => {
    const roles = persistStateEvent.filterRole
      .map((role) => rolesList.find((elt) => elt.id === role))
      .map((role) => ({
        id: role.id,
        name: role.name,
      }));

    return roles;
  };

  const handlePageChange = (newPage) => {
    dispatch(setTimeSheetEventPage(newPage));
  };

  const handleFilterChange = (newValue) => {
    dispatch(
      setTimeSheetEventFilters({ ...persistStateEvent.filterRole, ...newValue })
    );
  };

  useEffect(() => {
    dispatch(initialTimeSheetEventFetch({ id: eventid }));
  }, [lang, persistStateEvent, eventid]);

  useEffect(() => {
    if ((q.length >= 3 || q.length === 0) && !textContainOnlySpaces(q)) {
      dispatch(setTimeSheetEventSearch(q));
    }
  }, [q]);

  useEffect(() => {
    dispatch(getLookupsRequest([lookupsKeys.roles]));
  }, [lang]);

  useEffect(() => {
    if (excelLink) {
      linkRef.current.click();
      dispatch(exportExcelResponse(null));
    }
  }, [excelLink]);

  const renderMonthlyReportsList = () => {
    if (!isLoading && !list?.length) {
      return (
        <>
          <div className="bg-white rounded-4 d-flex justify-content-center align-items-center flex-column my-4">
            <EmptyState
              description={timeSheet.placeholder}
              imgSrc={emptyPlaceholder}
              showActionButton={false}
            />
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="bg-white rounded-4 mt-2 pb-3">
            <Table
              className="rounded-4"
              headlines={[
                timeSheet.monthlyReport.month,
                timeSheet.applicantName,
                timeSheet.role,
                timeSheet.monthlyReport.workingHours,
                timeSheet.payroll,
              ]}
              hasActions={false}
              hasSizeLimitation={false}
              rows={list?.map((row, i) => (
                <TableRow
                  key={i}
                  className="row-hover"
                  onClick={() =>
                    history.push(
                      ROUTE_PATHS.applicantReport
                        .replace(":eventid", eventid)
                        .replace(":userid", row.user.id),
                      {
                        isDaily: false,
                        event_name: location?.state?.event_name,
                        filter_date: row.date,
                        user_name: row.user,
                      }
                    )
                  }
                >
                  <TableCell>{row.readable_date}</TableCell>
                  <TableCell>
                    {renderTextEllipsis(
                      lang === "en" && row.user.full_name_en.trim()
                        ? row.user.full_name_en
                        : lang === "ar" && row.user.full_name_ar.trim()
                        ? row.user.full_name_ar
                        : "-"
                    )}
                    {row.user.is_blocked && (
                      <Block color="error" className="mx-2" fontSize="small" />
                    )}
                  </TableCell>
                  <TableCell>{row?.role?.name || "-"}</TableCell>
                  <TableCell
                    className={`mobile-style ${lang === "ar" && "text-end"}`}
                    dir="ltr"
                  >
                    {row.working_hours || "-"}
                  </TableCell>
                  <TableCell>
                    {row.expected_payroll} {shared.sar}
                  </TableCell>
                </TableRow>
              ))}
            />
          </div>
          <div className="d-flex justify-content-center py-3 mt-4">
            {meta.pages > 1 && (
              <Pagination
                count={meta.pages}
                page={meta.currentPage || persistStateEvent.page}
                handleChange={(event, page) => handlePageChange(page)}
                defaultPage={1}
              />
            )}
          </div>
        </>
      );
    }
  };

  return (
    <div className="monthly-report-container">
      <div className="w-100 page-title-margin d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-md-center top-container">
        <Breadcrumbs
          list={[
            {
              path: ROUTE_PATHS.timesheet,
              label: timeSheet.listTimeSheetBreadcrumb,
            },
            {
              path: "",
              label: `${location?.state?.event_name} - ${timeSheet.monthlyReport.name}`,
            },
          ]}
          BreadCrumbHasBackIcon={false}
        />
        {list?.length > 0 && (
          <div>
            <a
              ref={linkRef}
              href={excelLink}
              download={`${location?.state?.event_name}-monthly-report`}
              target="_blank"
              rel="noreferrer"
            ></a>
            <Button
              label={
                <>
                  <ExcelIcon />
                  <span className={`${lang === "en" ? "ms-2" : "me-2"}`}>
                    {timeSheet.exportBtn}
                  </span>
                </>
              }
              outlined
              small
              positive
              onClick={() => setOpenModal(true)}
            />
          </div>
        )}
      </div>

      <Filters
        isCollapsable={true}
        searchInput={{
          placeholder: timeSheet.searchByApplicant,
          name: "search",
          value: q,
          onChange: (value) => setQuery(value),
        }}
        filters={[
          {
            type: "filter",
            name: "role",
            label: timeSheet.role,
            placeholder:
              persistStateEvent.filterRole.length === 0 ? timeSheet.role : "",
            value: getFilterValues(),
            options: rolesList?.map((option) => ({
              id: option.id,
              name: option.name,
            })),

            onChange: (value) =>
              handleFilterChange({ filterRole: value.map((role) => role.id) }),
            isMultiple: true,
            className: "multiple-role-filter-wrapper",
          },
          {
            type: "date",
            name: "startDate",
            placeholder: timeSheet.monthlyReport.month,
            label: timeSheet.monthlyReport.month,
            value: persistStateEvent.filterStartDate,
            calendarViews: ["month"],
            openView: "month",
            inputDateFormat: "MMMM",
            onChange: (value) => handleFilterChange({ filterStartDate: value }),
          },
        ]}
      />
      <div className="d-flex flex-column justify-content-between">
        {renderMonthlyReportsList()}
      </div>
      <SelectExportedColumnsModal
        eventType={eventTypes.monthly}
        open={openModal}
        setOpen={setOpenModal}
      />
    </div>
  );
};

export default MonthlyReport;
