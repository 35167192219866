import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import dayjs from "dayjs";
import { ErrorOutline } from "@mui/icons-material";

import {
  getEventResponse,
  getEventRequest,
  deleteEventRequest,
  cancelEventRequest,
} from "../../../store/Events/actions";
import { addEvaluationEventRequest } from "../../../store/Evaluations/actions";
import { ROUTE_PATHS } from "../../../utils/RoutesPaths";
import { eventStatuses } from "../../../utils/Constants";
import messages from "../../../assets/locale/messages";
import Breadcrumbs from "../../../components/BreadCrumbs";
import Tabs from "../../../components/Tabs";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import BasicInfoTab from "./BasicInfoTab";
import RolesTab from "./RolesTab";
import ZonesTab from "./ZonesTab";
import RequirementsAndCommentsTab from "./RequirementsAndCommentsTab";
import { setCreateEventFormStep } from "../../../store/Routing/actions";
import ApplicantsTab from "./ApplicantsTab";
import { isSuperAdmin } from "../../../utils/Permissions";
import Tooltip from "../../../components/Tooltip";
import "./ViewEvent.scss";
import { setTabValueRequest } from "../../../store/ApplicantsTab/actions";

const ViewEvent = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { id } = useParams();

  const lang = useSelector((state) => state.locale.lang);
  const event = useSelector((state) => state.events.event);
  const step = useSelector((state) => state.routing.currentCreateEventFormStep);
  const { tabValue } = useSelector((state) => state.applicantTabs);

  const { events, shared } = messages[lang];

  // const [tabValue, setTabValue] = useState("basic");
  const [openActionModal, setOpenActionModal] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [isEventDatePassed, setIsEventDatePassed] = useState(false);
  const [tabs, setTabs] = useState([]);

  const basicTabs = [
    {
      value: "basic",
      label: events.viewEvents.basicInfo,
      content: <BasicInfoTab />,
    },
    {
      value: "roles",
      label: events.viewEvents.roles,
      content: <RolesTab />,
    },
    {
      value: "zones",
      label: events.viewEvents.zones,
      content: <ZonesTab />,
    },
    {
      value: "equipments",
      label: events.viewEvents.requirementsAndComments,
      content: <RequirementsAndCommentsTab />,
    },
  ];

  const handleTabValue = (value) => {
    dispatch(setTabValueRequest(value));
  };

  useEffect(() => {
    dispatch(getEventRequest(id));
    return () => {
      dispatch(getEventResponse(null));
    };
  }, [id, lang]);

  useEffect(() => {
    dispatch(setCreateEventFormStep(step));
    if (step == 2) handleTabValue("zones");
    dispatch(setCreateEventFormStep(0));
  }, [step]);

  useEffect(() => {
    let newTabs = [...basicTabs];
    if (event?.status && event?.status !== "pending") {
      const applicantsTab = {
        value: "applicants",
        label: events.viewEvents.applicants,
        content: <ApplicantsTab />,
      };
      newTabs.push(applicantsTab);
    }
    setTabs(newTabs);
  }, [event?.status, lang]);

  useEffect(() => {
    if (event) {
      setIsEventDatePassed(dayjs().isAfter(dayjs(event.event_end_at)));
    }
  }, [event]);

  // useEffect(() => {
  //   const storedTab = sessionStorage.getItem("viewEventTab");
  //   if (storedTab) setTabValue(storedTab);
  // }, []);

  // const handleSelectTab = (value) => {
  //   sessionStorage.setItem("viewEventTab", value);
  //   handleTabValue(value);
  // };

  const handleRoute = () => {
    if (tabValue == "zones" || tabValue == "equipments") {
      dispatch(setCreateEventFormStep(2));
    } else if (tabValue == "roles") {
      dispatch(setCreateEventFormStep(1));
    } else dispatch(setCreateEventFormStep(0));
    history.push(ROUTE_PATHS["editEvent"].replace(":id", event.id));
  };

  const handleConfirmModalAction = () => {
    modalType == "evaluation_cycle"
      ? dispatch(addEvaluationEventRequest(id))
      : modalType === "delete"
      ? dispatch(deleteEventRequest(id))
      : dispatch(cancelEventRequest(id));

    setOpenActionModal(false);
  };

  const renderCreateEvaluationBtn = () => (
    <Button
      className="complete-evaluation-round-btn mx-3"
      outlined
      small
      label={
        <>
          {events.viewEvents.completedEvaluationBtn}
          {isEventDatePassed && (
            <ErrorOutline fontSize="small" className="mx-1" />
          )}
        </>
      }
      labelClass="complete-evaluation-round-label"
      onClick={() => {
        setModalType("evaluation_cycle");
        setOpenActionModal(true);
      }}
      disabled={isEventDatePassed}
    />
  );

  return (
    <div className="event-details-page-container">
      <div className="w-100 page-title-margin d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-md-center top-container">
        <Breadcrumbs
          list={[
            {
              path: ROUTE_PATHS.events,
              label: events.listEvents.listEventsBreadcrumb,
            },
            {
              path: "",
              label: shared.eventStatusOptions[event?.status],
              isClicked: true,
              onclick: () =>
                history.push({
                  pathname: ROUTE_PATHS.events,
                  state: event?.status,
                }),
            },
            {
              path: "",
              label: `${event?.name} ${events.listEvents.details}`,
            },
          ]}
          BreadCrumbHasBackIcon={false}
        />
        <div className="d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-center top-btns-container">
          {event?.status === "pending" && isSuperAdmin() && (
            <Button
              type="button"
              onClick={() => {
                setModalType("delete");
                setOpenActionModal(true);
              }}
              label={events.viewEvents.deleteBtn}
              className="btn-delete mx-3"
              outlined
              small
            />
          )}
          {event?.status === "upcoming" && isSuperAdmin() && (
            <div className="btn-container">
              <Button
                type="button"
                onClick={() => {
                  setModalType("cancel");
                  setOpenActionModal(true);
                }}
                label={events.viewEvents.cancelBtn}
                className="btn-cancel mx-3"
                outlined
                negative
                small
              />
            </div>
          )}
          {event?.status === "ongoing" && (
            <div className="btn-container">
              {isEventDatePassed ? (
                <Tooltip
                  title={events.viewEvents.disbaledCreateEvaluationBtnHint}
                  content={<span>{renderCreateEvaluationBtn()}</span>}
                />
              ) : (
                renderCreateEvaluationBtn()
              )}
            </div>
          )}
          {id &&
            [
              eventStatuses.upcoming,
              eventStatuses.pending,
              eventStatuses.ongoing,
            ].includes(event?.status) && (
              <div className="">
                <Button
                  type="button"
                  onClick={() => {
                    handleRoute();
                  }}
                  label={events.viewEvents.editBtn}
                  className="btn-edit"
                  outlined
                  positive
                  small
                />
              </div>
            )}
        </div>
      </div>
      <Tabs onSelectTab={handleTabValue} tabs={tabs} activeTab={tabValue} />
      <Modal
        open={openActionModal}
        handleClose={() => {
          setOpenActionModal(false);
        }}
        content={
          modalType == "evaluation_cycle" ? (
            <>
              <p>{events.viewEvents.startEvaluationRound}</p>
              <p className="event-name">({event?.name}) </p>
            </>
          ) : modalType === "delete" ? (
            events.viewEvents.deleteModalConfirmMessage
          ) : (
            events.viewEvents.cancelModalConfirmMessage
          )
        }
        hasActions
        confirmBtnTxt={shared.confirm}
        cancelBtnTxt={shared.cancel}
        handleConfirm={handleConfirmModalAction}
        handleCancel={() => {
          setOpenActionModal(false);
        }}
      />
    </div>
  );
};

export default ViewEvent;
