import { put, takeLatest, select, call } from "redux-saga/effects";
import * as types from "./types";
import * as actions from "./actions";
import { getRequestsRequest } from "../Requests/actions";
import { showErrorMsg } from "../../utils/Helpers";

function* fetchApplicantRequestListSaga(action) {
  const { id } = action.payload;
  const {
    page,
    searchValue,
    rowCount,
    gender,
    status,
    role,
    zone,
    check_in_status,
    isSupervisor,
    flag_check_in,
  } = yield select((state) => state.applicantTabs);
  yield put(
    getRequestsRequest({
      page,
      items: rowCount,
      event_id: id,
      [isSupervisor ? "user_name" : "query"]: searchValue,
      is_assigned_to_zone: status?.value,
      gender: gender?.value,
      is_supervisor: isSupervisor,
      zone_id: zone?.id,
      role_id: role?.id,
      order_by_created_at: "asc",
      status: "approved",
      responseType: "applicantsTab",
      flag_is_user_checkedin: flag_check_in?.flag_check_in,
      is_checked_in: check_in_status?.value,
    })
  );
}

function* getApplicantsTabListSaga(action) {
  try {
    yield call(fetchApplicantRequestListSaga, action);
  } catch (error) {
    yield showErrorMsg(error);
  }
}

function* getPageRequestSaga(action) {
  try {
    const { page } = yield select((state) => state.applicantTabs);
    if (action.payload !== page) {
      yield put(actions.setPageRequest(action.payload));
    }
  } catch (error) {
    yield showErrorMsg(error);
  }
}

function* getSupervisorRequestSaga(action) {
  try {
    const { isSupervisor } = yield select((state) => state.applicantTabs);
    if (action.payload !== isSupervisor) {
      yield put(actions.setSupervisorValueRequest(action.payload));
    }
  } catch (error) {
    yield showErrorMsg(error);
  }
}

function* getFlagCheckInRequestSaga(action) {
  try {
    const { flag_check_in } = yield select((state) => state.applicantTabs);
    if (action.payload !== flag_check_in) {
      yield put(actions.setFlagCheckInValueRequest(action.payload));
    }
  } catch (error) {
    yield showErrorMsg(error);
  }
}

function* getTabsValueRequestSaga(action) {
  try {
    const { tabValue } = yield select((state) => state.applicantTabs);
    if (action.payload !== tabValue) {
      yield put(actions.setTabValueRequest(action.payload));
    }
  } catch (error) {
    yield showErrorMsg(error);
  }
}

function* getFiltersRequestSaga(action) {
  try {
    const { resettingFilters } = yield select((state) => state.applicantTabs);
    if (resettingFilters) {
      yield put(actions.setResettingFilters(false));
      return;
    }
    const previousFilter = yield select((state) => state.applicantTabs);
    const appliedFilter = action.payload;

    if (JSON.stringify(previousFilter) !== JSON.stringify(appliedFilter)) {
      yield put(actions.setFiltersRequest(appliedFilter));
    }
  } catch (error) {
    yield showErrorMsg(error);
  }
}

export default function* applicantTabRequestSaga() {
  yield takeLatest(types.INITIAL_FETCH_REQUEST, getApplicantsTabListSaga);
  yield takeLatest(types.SET_PAGE_REQUEST, getPageRequestSaga);
  yield takeLatest(types.SET_FILTERS_VALUE_REQUEST, getFiltersRequestSaga);
  yield takeLatest(
    types.SET_SUPERVISOR_VALUE_REQUEST,
    getSupervisorRequestSaga
  );
  yield takeLatest(
    types.SET_FLAG_CHECK_IN_VALUE_REQUEST,
    getFlagCheckInRequestSaga
  );
  yield takeLatest(types.SET_TABS_VALUE_REQUEST, getTabsValueRequestSaga);
}
