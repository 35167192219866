import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Chip, TableCell, TableRow } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Block } from "@mui/icons-material";

import Table from "../../../../components/Table";
import Menu from "../../../../components/Menu";
import Pagination from "../../../../components/Pagination";
import Avatar from "../../../../components/Avatar";
import { renderTextEllipsis } from "../../../../utils/Helpers";
import { eventStatuses } from "../../../../utils/Constants";
import messages from "../../../../assets/locale/messages";
import StatusModal from "./StatusModal";
import "./ApplicantsTab.scss";
import Button from "../../../../components/Button";
import { ROUTE_PATHS } from "../../../../utils/RoutesPaths";
import { useHistory } from "react-router-dom";

const Supervisor = ({
  lists,
  setPageHandle,
  eventId,
  searchValue,
  isUnableDoingAction,
  handleAddNote,
  handleDeleteNote,
  handleEditNote,
}) => {
  const history = useHistory();
  const [page, setPage] = useState(1);
  const lang = useSelector((state) => state.locale.lang);
  const meta = useSelector((state) => state.requests.applicantsTab.meta);
  const event = useSelector((state) => state.events.event);

  const { events, shared } = messages[lang];
  const [selectedItem, setSelectedItem] = useState(null);
  const unassignedOptions = [
    events.viewEvents.applicantsTab.unassign,
    events.viewEvents.applicantsTab.reallocate,
    events.viewEvents.applicantsTab.remove,
  ];
  const assignedOptions = [events.viewEvents.applicantsTab.assign];
  const [selectedOption, setSelectedOption] = useState("");
  const [openStatusModal, setOpenStatusModal] = useState(false);

  const clickHandler = (option, item) => {
    setOpenStatusModal(true);
    setSelectedOption(option);
    setSelectedItem(item);
  };

  const handleClose = (status) => {
    setOpenStatusModal(status);
  };

  const handleSetPage = (page) => {
    setPage(page);
    setPageHandle(page);
  };

  const headLines = [
    shared.id,
    shared.image,
    events.viewEvents.applicantsTab.name,
    events.viewEvents.applicantsTab.email,
    events.viewEvents.applicantsTab.gender,
    events.viewEvents.applicantsTab.note,
    events.viewEvents.applicantsTab.zone,
    events.viewEvents.applicantsTab.status,
    shared.stcNumber,
    shared.phone_number,
    shared.actions,
  ];

  if ([eventStatuses.ongoing, eventStatuses.past].includes(event?.status)) {
    headLines.splice(8, 0, events.viewEvents.checkinStatus);
  }

  if (isUnableDoingAction) {
    headLines.pop();
  }

  return (
    <>
      <div className="bg-white rounded-4 pb-3">
        <Table
          className="rounded-4"
          headlines={headLines}
          hasActions={false}
          rows={lists?.map((row, i) => {
            return (
              <TableRow key={i}>
                <TableCell className="mobile-style">
                  {row.user ? row.user.id : "-"}
                </TableCell>
                <TableCell className="mobile-style font-regular text-dark-blue fsize-14 ">
                  <Avatar
                    imgSrcAlt="user Image"
                    imgSrc={row.user?.image}
                    name={row.user?.full_name_en}
                    size={30}
                  />
                </TableCell>
                <TableCell
                  className="mobile-style pointer"
                  onClick={() => {
                    history.push(
                      ROUTE_PATHS.viewApplicant.replace(":id", row.user?.id)
                    );
                  }}
                >
                  {row?.user?.["full_name_" + lang]
                    ? renderTextEllipsis(row?.user?.["full_name_" + lang])
                    : "-"}
                  {row.user?.is_blocked && (
                    <Block color="error" className="mx-2" fontSize="small" />
                  )}
                </TableCell>
                <TableCell className="mobile-style">
                  {row?.user?.email ? renderTextEllipsis(row.user.email) : "-"}
                </TableCell>
                <TableCell className="mobile-style">
                  {row?.user?.gender
                    ? shared.genderOptions[row?.user?.gender]
                    : "-"}
                </TableCell>
                <TableCell className="mobile-style note-cell">
                  {row?.user?.note?.custom_location ||
                    row?.user?.note?.location?.name ||
                    "-"}
                </TableCell>
                <TableCell className="mobile-style data-cell">
                  {row.zone?.name ? renderTextEllipsis(row.zone?.name) : "-"}
                </TableCell>
                <TableCell className="mobile-style">
                  <Chip
                    label={
                      row.zone?.name
                        ? events.viewEvents.applicantsTab.assigned
                        : events.viewEvents.applicantsTab.notAssigned
                    }
                    className={`status-type fsize-14 fweight-400 ${
                      row.zone?.name ? "assigned" : "not-assigned"
                    } `}
                  />
                </TableCell>
                {[eventStatuses.ongoing, eventStatuses.past].includes(
                  event?.status
                ) && (
                  <TableCell className="mobile-style check-in-cell">
                    {row.is_user_checkedin
                      ? events.viewEvents.checkedIn
                      : events.viewEvents.nonCheckedIn}
                  </TableCell>
                )}
                <TableCell className="mobile-style">
                  {row?.user?.stc_number || "-"}
                </TableCell>
                <TableCell className="mobile-style">
                  {row?.user?.phone_number || "-"}
                </TableCell>
                {!isUnableDoingAction && (
                  <TableCell className="mobile-style p-0">
                    <Menu
                      options={
                        row.zone?.name ? unassignedOptions : assignedOptions
                      }
                      onSelectItem={(option) => clickHandler(option, row)}
                      triggerIcon={<MoreVertIcon />}
                    />
                  </TableCell>
                )}
                <TableCell className="mobile-style px-0">
                  {!row?.user?.note && (
                    <Button
                      label={shared.addNote}
                      onClick={() => handleAddNote(row?.user?.id)}
                      labelClass={`fsize-14 fweight-500 text-white`}
                    />
                  )}
                  {row?.user?.note && (
                    <div className="d-flex flex-wrap align-items-center gap-2">
                      <Button
                        label={shared.editNote}
                        onClick={() => handleEditNote(row?.user?.id)}
                        labelClass={`fsize-14 fweight-500 text-white`}
                      />
                      <button
                        className="delete-note-request"
                        onClick={() => handleDeleteNote(row?.user?.id)}
                      >
                        {shared.deleteNote}
                      </button>
                    </div>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        />
      </div>
      <div className="d-flex justify-content-center py-3 mt-4">
        {meta.pages > 1 && (
          <Pagination
            count={meta.pages}
            page={meta.currentPage || page}
            handleChange={(event, page) => {
              handleSetPage(page);
            }}
            defaultPage={1}
          />
        )}
      </div>
      <StatusModal
        handleClose={handleClose}
        open={openStatusModal}
        selectedOption={selectedOption}
        selectedItem={selectedItem}
        page={page}
        eventId={eventId}
        searchValue={searchValue}
        isSupervisor={true}
      />
    </>
  );
};

Supervisor.propTypes = {
  lists: PropTypes.array,
  setPageHandle: PropTypes.func,
  page: PropTypes.number,
  eventId: PropTypes.string,
  searchValue: PropTypes.string,
  isUnableDoingAction: PropTypes.bool,
  handleAddNote: PropTypes.func,
  handleEditNote: PropTypes.func,
  handleDeleteNote: PropTypes.func,
};

export default Supervisor;
