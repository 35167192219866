import React from "react";
import { Router, Switch, Redirect } from "react-router-dom";
import history from "./History";
import { ROUTE_PATHS } from "../utils/RoutesPaths";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
// ========= Components =========
import Login from "../containers/Auth/Login";
import ChangePassword from "../containers/Auth/ChangePassword";
import ForgetPassword from "../containers/Auth/ForgetPassword";
import Events from "../containers/Events";
import CreateEvent from "../containers/Events/CreateEvent";
import ViewEvent from "../containers/Events/ViewEvent";
import InviteApplicants from "../containers/Events/ViewEvent/ApplicantsTab/InviteApplicants";
import Employees from "../containers/Employees";
import Requests from "../containers/Requests";
import PromotionRequests from "../containers/PromotionRequests";
import ResetPasswordInternally from "../containers/Auth/ResetPasswordInternally";
import AddProjectManager from "../containers/Employees/AddProjectManager";
import Applicants from "../containers/Applicants";
import ViewApplicant from "../containers/Applicants/ViewApplicant";
import TimeSheet from "../containers/TimeSheet";
import MonthlyReport from "../containers/TimeSheet/MonthlyReport";
import DailyReport from "../containers/TimeSheet/DailyReport";
import ApplicantReport from "../containers/TimeSheet/ApplicantReport";
import ApplicantRequests from "../containers/Applicants/ViewApplicant/ApplicantRequests";
import PastEvents from "../containers/Applicants/ViewApplicant/PastEvents";
import ZoneApplicants from "../containers/Events/ZoneApplicants";
import ViewEmployee from "../containers/Employees/ViewEmployee";
import Evaluations from "../containers/Evaluations";
import InprogressEvaluation from "../containers/Evaluations/InprogressEvaluation";
import CompletedEvaluation from "../containers/Evaluations/CompletedEvaluation";
import EvaluationForm from "../containers/Evaluations/EvaluationForm";
import Notifications from "../containers/Notifications";
import EditApplicant from "../containers/Applicants/EditApplicant";
import ViewReport from "../containers/Reports/ViewReport/ViewReport";
import Reports from "../containers/Reports";
import EditEvaluation from "../containers/Evaluations/EditEvaluation";

const Routes = (
  <Router history={history}>
    <Switch>
      {/* Events */}
      <PrivateRoute
        component={Events}
        path={[
          ROUTE_PATHS.events,
          ROUTE_PATHS.requestsEvents,
          ROUTE_PATHS.reports,
        ]}
        exact
        permission={false}
      />
      <PrivateRoute
        component={CreateEvent}
        path={[ROUTE_PATHS.createEvent, ROUTE_PATHS.editEvent]}
        exact
        permission={false}
      />
      <PrivateRoute
        component={ViewEvent}
        path={ROUTE_PATHS.viewEvent}
        exact
        permission={false}
      />
      <PrivateRoute
        component={InviteApplicants}
        path={ROUTE_PATHS.inviteApplicants}
        exact
        permission={false}
      />
      {/*Reports*/}
      <PrivateRoute
        component={Reports}
        path={ROUTE_PATHS.viewReport}
        exact
        permission={false}
      />
      <PrivateRoute
        component={ViewReport}
        path={ROUTE_PATHS.viewReportDetail}
        exact
        permission={false}
      />

      {/* Employess */}
      <PrivateRoute
        component={Employees}
        path={ROUTE_PATHS.employees}
        exact
        permission={false}
      />
      <PrivateRoute
        component={AddProjectManager}
        path={[ROUTE_PATHS.addProjectManager, ROUTE_PATHS.editProfile]}
        exact
        permission={false}
      />
      <PrivateRoute
        component={ViewEmployee}
        path={[ROUTE_PATHS.viewEmployee, ROUTE_PATHS.profile]}
        exact
        permission={false}
      />
      {/* Requests */}
      <PrivateRoute
        component={Requests}
        path={ROUTE_PATHS.viewRequests}
        exact
        permission={false}
      />
      <PrivateRoute
        component={ViewApplicant}
        path={ROUTE_PATHS.viewRequestsApplicant}
        exact
        permission={false}
      />
      <PrivateRoute
        component={PromotionRequests}
        path={ROUTE_PATHS.promotionRequests}
        exact
        permission={false}
      />
      {/* Applicants */}
      <PrivateRoute
        component={Applicants}
        path={ROUTE_PATHS.applicants}
        exact
        permission={false}
      />
      <PrivateRoute
        component={ViewApplicant}
        path={ROUTE_PATHS.viewApplicant}
        exact
        permission={false}
      />
      <PrivateRoute
        component={EditApplicant}
        path={ROUTE_PATHS.editApplicantProfile}
        exact
        permission={false}
      />
      <PrivateRoute
        component={ApplicantRequests}
        path={ROUTE_PATHS.viewApplicantRequests}
        permission={false}
      />
      <PrivateRoute
        component={PastEvents}
        path={ROUTE_PATHS.viewApplicantEvents}
        exact
        permission={false}
      />
      <PrivateRoute
        component={EvaluationForm}
        path={ROUTE_PATHS.viewApplicantEvaluationView}
        exact
        permission={false}
      />
      <PrivateRoute
        component={EvaluationForm}
        path={ROUTE_PATHS.viewApplicantEvaluationEdit}
        exact
        permission={false}
      />
      <PrivateRoute
        component={ZoneApplicants}
        path={ROUTE_PATHS.zoneApplicants}
        exact
        permission={false}
      />
      {/* Timesheet */}
      <PrivateRoute
        component={TimeSheet}
        path={ROUTE_PATHS.timesheet}
        exact
        permission={false}
      />
      <PrivateRoute
        component={MonthlyReport}
        path={ROUTE_PATHS.monthlyReport}
        exact
        permission={false}
      />
      <PrivateRoute
        component={DailyReport}
        path={ROUTE_PATHS.dailyReport}
        exact
        permission={false}
      />
      <PrivateRoute
        component={ApplicantReport}
        path={ROUTE_PATHS.applicantReport}
        exact
        permission={false}
      />
      {/* Evaluations */}
      <PrivateRoute
        component={Evaluations}
        path={ROUTE_PATHS.evaluations}
        exact
        permission={true}
      />
      <PrivateRoute
        component={InprogressEvaluation}
        path={ROUTE_PATHS.inprogressEvaluations}
        exact
        permission={true}
      />
      <PrivateRoute
        component={CompletedEvaluation}
        path={ROUTE_PATHS.completedEvaluations}
        exact
        permission={false}
      />
      <PrivateRoute
        component={EvaluationForm}
        path={ROUTE_PATHS.evaluationFormInprogressCreate}
        exact
        permission={true}
      />
      <PrivateRoute
        component={EvaluationForm}
        path={ROUTE_PATHS.evaluationFormInprogressView}
        exact
        permission={true}
      />
      <PrivateRoute
        component={EditEvaluation}
        path={ROUTE_PATHS.evaluationFormInProgressEdit}
        exact
        permission={false}
      />
      <PrivateRoute
        component={EvaluationForm}
        path={ROUTE_PATHS.evaluationFormCompletedView}
        exact
        permission={false}
      />

      {/* Authentication */}
      <PrivateRoute
        component={ChangePassword}
        path={ROUTE_PATHS.changePassword}
        exact
        permission={true}
      />
      <PrivateRoute
        component={Notifications}
        path={ROUTE_PATHS.notifications}
        exact
        permission={true}
      />
      {/* Authentication */}
      <PublicRoute
        component={ForgetPassword}
        path={ROUTE_PATHS.forgetPassword}
        exact
      />
      <PrivateRoute
        component={ResetPasswordInternally}
        path={ROUTE_PATHS.resetPassword}
        exact
      />
      <PublicRoute component={Login} path={ROUTE_PATHS.login} exact />

      <Redirect from="/" to={ROUTE_PATHS.events} />
      <Redirect from="**" to={ROUTE_PATHS.events} />
    </Switch>
  </Router>
);
export default Routes;
